import React from 'react';
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import MuiDialogTitle from '../MuiDialogTitle';
import img from '../../icons/success.png';
import imgErr from '../../icons/cancelar.png';
import checked from '../../icons/checked.png';
import useStyles from './styled';

export default function MuiDialog({
  onOpen,
  onClose,
  title,
  body,
  errorServer,
  buttonText,
}) {
  const classes = useStyles();
  const pathname = window.location.pathname;
  const validatePath = pathname === '/login' || pathname === '/';

  const renderContent = body => {
    var content;
    if (body && body.content) {
      content = (
        <div className={classes.bodyContent}>
          <Typography className={classes.title} gutterBottom>
            {body.content.passport === '' ? 'Cédula' : 'Pasaporte'}
          </Typography>
          <Typography gutterBottom>
            {body.content.passport === ''
              ? body.content.cedula
              : body.content.passport}
          </Typography>
          <Typography className={classes.title} gutterBottom>
            Nombre(s)
          </Typography>
          <Typography gutterBottom>{body.content.fullName}</Typography>
          <Typography className={classes.title} gutterBottom>
            Teléfono
          </Typography>
          <Typography gutterBottom>{body.content.phoneNumber}</Typography>
          <Typography className={classes.title} gutterBottom>
            Vacuna Suministrada
          </Typography>
          <Typography gutterBottom>{body.content.vaccine}</Typography>
          <Typography className={classes.title} gutterBottom>
            Info Vacuna Escaneada
          </Typography>
          <Typography gutterBottom>{body.content.infoVaccine}</Typography>
        </div>
      );
    } else if (validatePath) {
      content = (
        <Typography className={classes.bodyLogin} gutterBottom>
          {body.success}
        </Typography>
      );
    } else {
      content = (
        <Typography gutterBottom>
          {!errorServer && body ? body.success : body.error}
        </Typography>
      );
    }
    return content;
  };

  return (
    <Dialog aria-labelledby="customized-dialog-title" open={onOpen}>
      <MuiDialogTitle
        className={classes.header}
        id="customized-dialog-title"
        onClose={onClose}
      >
        {!errorServer && !validatePath ? (
          <img className={classes.img} width="100%" src={img} alt="success" />
        ) : validatePath ? (
          <img className={classes.img} src={checked} alt="checked" />
        ) : (
          <img
            className={classes.img}
            style={{ paddingBottom: '2%' }}
            src={imgErr}
            alt="err"
          />
        )}
        {title && title}
      </MuiDialogTitle>
      <DialogContent dividers>{renderContent(body)}</DialogContent>
      <DialogActions>
        {!validatePath && (
          <Button autoFocus onClick={onClose} color="primary">
            {buttonText && buttonText !== '' ? buttonText : 'Ok'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

MuiDialog.propTypes = {
  onOpen: PropTypes.bool,
  onClose: PropTypes.bool,
  title: PropTypes.string,
  body: PropTypes.object,
  errorServer: PropTypes.bool,
  buttonText: PropTypes.string,
};

MuiDialog.defaultProps = {
  title: '',
  body: {},
};
