import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  btn: {
    backgroundColor: '#f7f7f7',
    border: '1px solid #00205ce8',
    color: '#4A4A4A',
    fontSize: 20,
    height: '2em',
    textTransform: 'capitalize',
    width: '96%',
    margin: '10px',
    '&$selected': {
      backgroundColor: '#0153b32b',
      border: '2px solid #072548',
      color: '#194b85',
    },
  },
  selected: {},
}));
