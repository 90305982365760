import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  container: {
    boxShadow: '1px 1px 8px hsla(0, 0%, 40%, 0.3)',
    display: 'flex',
    flexWrap: 'Wrap',
    margin: '50px auto 50px',
    maxWidth: 400,
    minWidth: 50,
    padding: 30,
    borderRadius: 10,
    textAlign: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down(400)]: {
      maxWidth: 'auto',
    },
  },
  btnSignup: {
    backgroundColor: '#00205C',
    borderRadius: '5px',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 'bold',
    height: '45.6px',
    margin: '8% auto 10%',
    maxHeight: 50,
    textDecoration: 'none',
    width: '100%',
    [theme.breakpoints.down(400)]: {
      height: 50,
    },
  },
  input: {
    margin: 'auto',
    marginTop: '5%',
    minWidth: '70%',
  },
  labelMessage: {
    color: 'red',
    fontSize: 20,
    fontWeight: 100,
    textAlign: 'center',
  },
  labelLogin: {
    fontSize: 14,
    margin: 'auto',
    marginBottom: 10,
    marginTop: 20,
    textAlign: 'center',
  },
  optionUser: {
    color: '#048EF7',
  },
  titleRegister: {
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontWeight: 'bold',
    letterSpacing: '0.11px',
    lineHeight: '24px',
    marginBottom: '25%',
    textAlign: 'center',
    width: '132px',
  },
  logoImage: {
    height: '80px',
    margin: '10% 0 10%',
  },
  title: {
    margin: 'auto',
  },
  selectInputClass: {
    color: '#9B9B9B',
    marginBottom: 20,
    marginTop: '2.5%',
    textAlign: 'left',
  },
  labelTitleSelect: {
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'left',
    marginTop: '0.5em',
  },
  textFields: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  labelTitle: {
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'left',
    margin: '20px 0px 10px 0px',
  },
  img: {
    width: '80px',
    paddingBottom: '2%',
  },
  header: {
    '& h2': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
}));
