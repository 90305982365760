import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    position: 'absolute',
    bottom: '0',
    top: '380px',
    left: '0',
    margin: 'auto',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down(700)]: {
      top: '260px',
    },
  },
  sectionTitle: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(1),
    fontSize: '20px',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    textAlign: 'left',
    marginBottom: '15px',
  },
  sectionHeader: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(1),
    fontWeight: 700,
    textTransform: 'uppercase',
    textAlign: 'center',
    [theme.breakpoints.down(600)]: {
      marginLeft: 0,
      fontSize: '14px',
    },
    [theme.breakpoints.down(370)]: {
      fontSize: '12px',
    },
  },
  paper: {
    maxWidth: '730px',
    marginLeft: '5%',
    marginRight: '5%',
    marginTop: '3%',
    width: '100%',
  },
  spacer: {
    width: '100%',
    height: '100px',
  },
  tableTitle: {
    color: '#00205C',
    fontSize: '20px',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    [theme.breakpoints.down(700)]: {
      fontSize: '15px',
      margin: '2% 1% 2% 1%',
    },
  },
  tableTitleContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 5% 0 5%',
    textAlign: 'left',
    [theme.breakpoints.down(700)]: {
      display: 'unset',
      textAlign: 'center',
    },
  },
  card: {
    borderRadius: '10px',
    width: '300px',
    height: '340px',
    padding: '10px 10px',
    position: 'relative',
    overflow: 'unset',
    marginBottom: '10px',
    [theme.breakpoints.down(1025)]: {
      width: '85%',
      height: '340px',
    },
    [theme.breakpoints.down(900)]: {
      height: '330px',
    },
    [theme.breakpoints.down(600)]: {
      height: '240px',
    },
    [theme.breakpoints.down(590)]: {
      height: '235px',
    },
    [theme.breakpoints.down(450)]: {
      height: '230px',
    },
    [theme.breakpoints.down(380)]: {
      height: '225px',
    },
    [theme.breakpoints.down(370)]: {
      width: '80%',
      height: '220px',
    },
  },
  cardContent: {
    padding: '16px',
    [theme.breakpoints.down(600)]: {
      padding: '0',
    },
    [theme.breakpoints.down(380)]: {
      padding: '0',
    },
  },
  button: {
    height: '45.6px',
    backgroundColor: '#e6eefc',
    border: '4px',
    borderRadius: 5,
    color: theme.palette.primary.main,
    display: 'flex',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: 'bold',
    width: '100%',
    marginTop: theme.spacing(2),
    '&:hover': {
      backgroundColor: '#e6eefc',
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down(380)]: {
      fontSize: 15,
    },
  },
  icon: {
    height: 170,
    [theme.breakpoints.down(900)]: {
      height: 170,
    },
    [theme.breakpoints.down(600)]: {
      height: 100,
    },
  },
  label: {
    color: '#4a4a4a',
    fontFamily: 'Montserrat',
    marginTop: theme.spacing(0.5),
    fontSize: '14px',
    textTransform: 'uppercase',
    // [theme.breakpoints.down(900)]: {
    //   fontSize: '15px',
    // },
    [theme.breakpoints.down(770)]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down(590)]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down(450)]: {
      fontSize: '10px',
    },
    [theme.breakpoints.down(380)]: {
      fontSize: '9px',
    },
    [theme.breakpoints.down(370)]: {
      fontSize: '8px',
    },
  },
  title: {
    color: '#00205C',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  container: {
    position: 'absolute',
    backgroundColor: '#F0F3F0',
    bottom: '0',
    left: '0',
    right: '0',
  },
  chipNumberUsers: {
    margin: '1% 1% 1% 33%',
    [theme.breakpoints.down(700)]: {
      margin: '1% 2% 1% 0',
    },
  },
}));
