import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  toggleSelected: {
    backgroundColor: '#00205C !important',
  },
  bodyContent: {
    margin: '0 20px',
    textAlign: 'left',
    justifyContent: 'left',
  },
  btnSend: {
    marginTop: '2%',
    borderRadius: '10px',
    height: 40,
    width: '35%',
    float: 'right',
    [theme.breakpoints.down(400)]: {
      width: '43%',
    },
  },
  btnClose: {
    marginTop: '2%',
    borderRadius: '10px',
    height: 40,
    width: '35%',
    float: 'left',
    [theme.breakpoints.down(400)]: {
      width: '43%',
    },
  },
  img: {
    display: 'flex',
    margin: 'auto',
    justifyContent: 'center',
    alignContent: 'center',
    width: '24%',
    paddingBottom: '2%',
    [theme.breakpoints.down(600)]: {
      width: '28%',
    },
  },
  modalTitle: {
    marginBottom: '20px',
    textAlign: 'center',
    fontSize: '18px',
    [theme.breakpoints.down(400)]: {
      fontSize: '16px',
    },
  },
  modalSubtitle: {
    fontWeight: '600',
  },
}));
