import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  header: {
    paddingTop: '2em',
    textAlign: 'center',
    [theme.breakpoints.down(400)]: {
      paddingBottom: '0',
    },
    '& .MuiTypography-h6': {
      fontWeight: 'bold',
    },
  },
  img: {
    display: 'flex',
    margin: 'auto',
    justifyContent: 'center',
    alignContent: 'center',
    width: '25%',
    [theme.breakpoints.down(400)]: {
      width: '40%',
    },
  },
  title: {
    color: '#00205C',
    fontSize: '15px',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    textAlign: 'left',
    justifyContent: 'center',
  },
  bodyLogin: {
    color: '#00205C',
    fontSize: '20px',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    textAlign: 'center',
    justifyContent: 'center',
  },
  bodyContent: {
    margin: '0 20px',
    textAlign: 'left',
    justifyContent: 'left',
  },
}));
