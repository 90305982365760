import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import useStyles from './styled';

export default function UserType({ onChange, value, options }) {
  const classes = useStyles();
  const tabsKey = Object.keys(options);

  return (
    <FormControl component="fieldset" className={classes.formRadio}>
      <RadioGroup
        name="userType"
        onChange={event => onChange(event)}
        value={value}
        row
      >
        {tabsKey.map(key => {
          return (
            <FormControlLabel
              key={key}
              control={<Radio />}
              label={options[key]}
              value={key}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}

UserType.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.object,
  options: PropTypes.object,
};
