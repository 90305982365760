import React, { useContext, useState } from 'react';
import { useLocation, Link, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import {
  AppBar,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  Drawer,
  Toolbar,
  Divider,
  IconButton,
} from '@material-ui/core';
import { Menu, ChevronLeft, ChevronRight } from '@material-ui/icons';

import { useTheme } from '@material-ui/core/styles';
import logo from '../../logo.svg';
import icon from '../../icons/vacunaterd_navbar.svg';
import { USER_LOGOUT, RESET_STATES } from '../../reducer/actions';
import AppContext from '../../context/AppContext';
import AppStorage from '../../helpers/AppStorage';
import User from '../../helpers/User';
import useStyles from './styled';

const { REACT_APP_TAG_VERSION } = process.env;
export default function Header() {
  const location = useLocation();
  const SHOW_NAV_ON_PATH = [
    '/vaccination/?$',
    '/vaccination/register/?$',
    '/vaccination/symptoms/?$',
    '/appointment/?$',
    '/totals',
    '/vaccined-totals',
    'registered-vaccinate',
  ];
  const showNav = SHOW_NAV_ON_PATH.some(e =>
    new RegExp(e).test(location.pathname)
  );
  const headerLogo = !showNav ? logo : icon;
  const { dispatch } = useContext(AppContext);
  const history = useHistory();
  const center = AppStorage.get('center', true) || {};
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const signOut = () => {
    User.logout();
    setOpen(false);
    dispatch({
      type: USER_LOGOUT,
    });
    dispatch({ type: RESET_STATES });
  };

  const goHome = () => {
    setOpen(false);
    dispatch({ type: RESET_STATES });
    history.push('/vaccination');
  };

  const goAppointment = () => {
    setOpen(false);
    dispatch({ type: RESET_STATES });
    history.push('/appointment');
  };

  const goRegisteredVaccinate = () => {
    setOpen(false);
    dispatch({ type: RESET_STATES });
    history.push('/registered-vaccinate');
  };

  const goTotal = () => {
    setOpen(false);
    dispatch({ type: RESET_STATES });
    history.push('/totals');
  };

  const goVaccinedTotal = () => {
    setOpen(false);
    dispatch({ type: RESET_STATES });
    history.push('/vaccined-totals');
  };

  const listItem = [
    {
      label: 'Inicio',
      url: '/vaccination',
      function: goHome,
    },
    {
      label: 'Cita',
      url: '/appointment',
      function: goAppointment,
    },
    {
      label: 'Total',
      url: '/total',
      function: goTotal,
    },
    {
      label: 'Reportes',
      url: '/vaccined-total',
      function: goVaccinedTotal,
    },
    {
      label: 'Registros',
      url: '/registered-vaccinate',
      function: goRegisteredVaccinate,
    },
    {
      label: 'Salir',
      url: '',
      function: signOut,
    },
  ];

  return (
    <AppBar
      className={!showNav ? classes.appBarOut : `App-header ${classes.appBar}`}
      color="primary"
    >
      <div className={classes.logoContainer}>
        <img
          className={classes.appLogo}
          src={headerLogo}
          alt="VacunateDR logo"
        />
      </div>

      {showNav && (
        <div className={classes.navigationContainer}>
          <div className={classes.headerTitlesContainer}>
            <h2 className={classes.headerPrimaryTitle}>{center.name}</h2>
            <h3 className={classes.headerSecondaryTitle}>
              Centro de vacunación
            </h3>
            <h3 className={classes.headerSecondaryTitle}>
              {REACT_APP_TAG_VERSION && REACT_APP_TAG_VERSION}
            </h3>
          </div>
          {isMobile ? (
            <>
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="end"
                  onClick={() => setOpen(true)}
                  className={clsx(open ? classes.hide : classes.iconMenu)}
                >
                  <Menu />
                </IconButton>
              </Toolbar>
              <Drawer
                variant="persistent"
                anchor="right"
                open={open}
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                <div className={classes.drawerHeader}>
                  <IconButton onClick={() => setOpen(false)}>
                    {open ? <ChevronRight /> : <ChevronLeft />}
                  </IconButton>
                </div>
                <Divider />
                <List className={classes.menuList}>
                  {listItem.map(i => {
                    return (
                      <ListItem
                        className={classes.linkDrawer}
                        component={Link}
                        key={i.url}
                        to="#"
                        onClick={i.function}
                      >
                        <ListItemText
                          primary={i.label}
                          className={classes.linkSpan}
                        />
                      </ListItem>
                    );
                  })}
                  <Divider />
                </List>
              </Drawer>
            </>
          ) : (
            <>
              <List className={classes.menuMobile}>
                {listItem.map(i => {
                  return (
                    <ListItem
                      className={classes.link}
                      component={Link}
                      key={i.url}
                      to="#"
                      onClick={i.function}
                    >
                      <ListItemText
                        primary={i.label}
                        className={classes.linkSpan}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </>
          )}
        </div>
      )}
    </AppBar>
  );
}
