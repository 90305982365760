class Token {
  isValid(token) {
    const payload = this.payload(token);
    if (this.compareTime(payload)) {
      return true;
    }
    return false;
  }

  compareTime(payload) {
    var exp_time = new Date(payload.exp),
      time = new Date().getTime() / 1000,
      current_time = new Date(time);
    if (current_time < exp_time) {
      return true;
    }
    return false;
  }

  payload(token) {
    const payload = token.split('.')[1];
    return this.decode(payload);
  }

  decode(payload) {
    if (this.isBase64(payload)) {
      return JSON.parse(atob(payload));
    }
    return false;
  }

  isBase64(str) {
    try {
      return btoa(atob(str)).replace(/=/g, '') === str;
    } catch (err) {
      return false;
    }
  }
}

export default new Token();
