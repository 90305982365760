import React, { useContext } from 'react';
import AppContext from '../../context/AppContext';
import { SET_COMORBIDITIES } from '../../reducer/actions';
import { ToggleButton } from '@material-ui/lab';
import { Container, Grid, Typography } from '@material-ui/core';
import useStyles from './styled';

export default function Comorbidities({ value, setPacientData, pacientData }) {
  const { state, dispatch } = useContext(AppContext);
  const { comorbidities } = state;
  const classes = useStyles();

  const handleSymptom = (comorbidity, selected) => {
    setPacientData({
      ...pacientData,
      comorbidities: {
        ...comorbidities,
        [comorbidity]: !selected,
      },
    });
    dispatch({
      type: SET_COMORBIDITIES,
      payload: {
        comorbidities: {
          ...comorbidities,
          [comorbidity]: !selected,
        },
      },
    });
  };

  return (
    <>
      {value === 'comorbidities' && (
        <Container className={classes.container}>
          {Object.entries(comorbidities).map(
            ([comorbidities, value = false]) => {
              return (
                <Grid key={comorbidities} item>
                  <ToggleButton
                    classes={{
                      root: classes.btn,
                      selected: classes.selected,
                    }}
                    onClick={() => handleSymptom(comorbidities, value)}
                    value=""
                    selected={value}
                  >
                    <Grid
                      container
                      alignItems="center"
                      direction="column"
                      justify="center"
                    >
                      <Typography>{comorbidities}</Typography>
                    </Grid>
                  </ToggleButton>
                </Grid>
              );
            }
          )}
        </Container>
      )}
    </>
  );
}
