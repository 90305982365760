import React, { useEffect, useState, useContext } from 'react';
import { Grid, Card, CardContent, Typography, Button } from '@material-ui/core';
import { get } from '../../utils/fetch';
import AppStorage from '../../helpers/AppStorage';
import useStyles from './styled';
import AppContext from '../../context/AppContext';
import { USER_LOGOUT } from '../../reducer/actions';
import { Cached } from '@material-ui/icons';
import { catchError } from '../../catchErrors/ErrorComponent';

function Total() {
  const classes = useStyles();
  const { centerId } = AppStorage.get('center', true) || {};
  const [vaccineTotal, setVaccineTotal] = useState([]);
  const { dispatch } = useContext(AppContext);

  useEffect(() => {
    updateData();
    // eslint-disable-next-line
  }, [centerId]);

  const updateData = () => {
    get(`/vaccination/total?centerId=${centerId}`)
      .then(res => {
        if (res.status === 401) {
          dispatch({
            type: USER_LOGOUT,
          });
        }
        return res.json();
      })
      .then(({ data }) => {
        setVaccineTotal(data || []);
      });
  };

  return (
    <div className={classes.root}>
      {vaccineTotal && vaccineTotal.length !== 0 && (
        <Button onClick={updateData} className={classes.btnUpdateData}>
          <Typography style={{ paddingRight: '5px' }}>
            Actualizar datos
          </Typography>
          <Cached />
        </Button>
      )}
      {vaccineTotal && vaccineTotal.length === 0 ? (
        <Typography
          variant="h5"
          className={classes.sectionHeader}
        >
          No existe registro de vacunados para este centro
        </Typography>
      ) : (
        vaccineTotal &&
        vaccineTotal.map((vaccine, i) => {
          const totalFirst = vaccine.totalFirstDose || 0;
          const totalSecond = vaccine.totalSecondDose || 0;
          const totalThird = vaccine.totalThirdDose || 0;
          const totalFourth = vaccine.totalFourthDose || 0;
          const totalFifth = vaccine.totalFifthDose || 0;

          const total =
            parseInt(totalFirst) +
            parseInt(totalSecond) +
            parseInt(totalThird) +
            parseInt(totalFourth) +
            parseInt(totalFifth);

          const optionsName = [
            {
              label: 'Total Vacunados Primera Dosis',
              total: totalFirst,
            },
            {
              label: 'Total Vacunados Segunda Dosis',
              total: totalSecond,
            },
            {
              label: 'Total Vacunados Tercera Dosis',
              total: totalThird,
            },
            {
              label: 'Total Vacunados Cuarta Dosis',
              total: totalFourth,
            },
            {
              label: 'Total Vacunados Quinta Dosis',
              total: totalFifth,
            },
            {
              label: 'Total de Vacunados del centro',
              total: total,
            },
          ];
          return (
            <div key={i}>
              <Typography
                variant="h5"
                className={classes.sectionHeader}
                style={{ fontWeight: 'bold', padding: 15 }}
              >
                Vacuna {vaccine.name}
              </Typography>

              <Grid container>
                {optionsName.map(({ label, total }, i) => {
                  return (
                    <Grid item xs key={i}>
                      <Card className={classes.card}>
                        <CardContent>
                          <Typography
                            variant="h2"
                            className={classes.sectionHeader}
                            style={{ fontWeight: 'bold' }}
                          >
                            {total}
                          </Typography>
                          <Typography
                            className={classes.sectionBody}
                            style={{ fontWeight: 'bold' }}
                          >
                            {label}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
              <span style={{ margin: '10%' }}></span>
            </div>
          );
        })
      )}
    </div>
  );
}
export default catchError(Total);
