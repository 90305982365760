import AppStorage from '../helpers/AppStorage';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
export {
  REACT_APP_API_URL
};

export const get = url => {
  const token = AppStorage.get('auth_token');

  const bodyOpts = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      auth_token: token,
      Authorization: token,
      credentials: 'include',
    },
  };
  return fetch(`${REACT_APP_API_URL}${url}`, bodyOpts);
};

export const post = (url, body = {}) => {
  const token = AppStorage.get('auth_token');

  const bodyOpts = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      auth_token: token,
      Authorization: token,
      credentials: 'include',
    },
    body: JSON.stringify(body),
  };
  return fetch(`${REACT_APP_API_URL}${url}`, bodyOpts);
};

export const put = (url, body = {}) => {
  const token = AppStorage.get('auth_token');

  const bodyOpts = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
      credentials: 'include',
    },
    body: JSON.stringify(body),
  };
  return fetch(`${REACT_APP_API_URL}${url}`, bodyOpts);
};
