import React, { useState, useContext, useEffect } from 'react';
// Google Analytics
import ReactGA from 'react-ga';
import {
  Button,
  Typography,
  Snackbar,
  Grid,
  Container,
  Card,
  TextField,
  Select,
  FormControl,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Switch,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import Patient from '../../helpers/Patient';
import QRCodeReader from '../../components/QRCodeReader';

import AppContext from '../../context/AppContext';
import SimpleModal from '../../components/SimpleModal';

import { post, get } from '../../utils/fetch';
import AppStorage from '../../helpers/AppStorage';
import useStyles from './styled';
import InputMask from 'react-input-mask';
import {
  USER_LOGOUT,
  RESET_COMORBIDITIES_STATES,
  RESET_STATES,
} from '../../reducer/actions';
import { errorMessage, OPTIONS_LABORAL_SECTOR } from '../../const';
import { LocationContext } from '../../context/LocationContext';
import useLocation from '../../hooks/useLocation';
// import PatientAlert from './PatientAlert';
import { catchError } from '../../catchErrors/ErrorComponent';
import checked from '../../icons/checked.png';
import imgErr from '../../icons/cancelar.png';
import { formatDate2, formatDatePicker } from '../../helpers/formatDate';
import ToggleButton from '../../components/ToggleButton';
import DatePickerComponent from '../../components/DatePickerComponent';
import isYoung from '../../helpers/isYoung';
import Comorbidities from '../../components/Comorbidities';
import InputMaskComponent from '../../components/InputMask';
import CreatableSelect from 'react-select/creatable';

const DOSIS_LABEL = {
  1: '1ra Dosis',
  2: '2da Dosis',
  3: '3ra Dosis',
};

const RADIO_OPTION = {
  id: 'ced',
  passport: 'pass',
  adults: 'adults',
  younger: 'younger',
};

function VaccinationRegister() {
  const classes = useStyles();
  const {
    state: {
      userData: { centerId = '', personId },
      comorbidities,
    },
    dispatch,
  } = useContext(AppContext);
  const { isLocactionActive } = useContext(LocationContext);
  const { latitude, longitude } = useLocation();
  const [error, setError] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [showFailedOpen, setShowFailedOpen] = useState(false);
  const [isPacientVaccineInLast24h, setIsPacientVaccineInLast24h] = useState(
    false
  );
  const setAttributes = ({ openDialog = true, error = false }) => {
    setError(error);
    setOpenDialog(openDialog);
  };
  const [errorScanIdNumber, setErrorScanIdNumber] = useState();
  // const [errorScanVaccine, setErrorScanVaccine] = useState();
  const { vaccineId = '' } = AppStorage.get('vaccineId', true) || {};
  const { vaccineType = '' } = AppStorage.get('center', true) || {};
  const initialState = {
    documentNumber: '',
    firstNames: '',
    nextDose: '',
    vaccineId: '',
    numDose: '',
    vaccineTypeId: vaccineType,
    centerId,
    personId,
    passportNumber: '',
    cardCode: '',
    status_id: '',
    lastDose: '',
    phoneNumber: '',
    youngerName: '',
    youngerLastName: '',
    youngerSex: '',
    youngerBornDate: null,
    laboralSector: 'otros',
    loteId: [],
    comorbidities: {
      diabetes: comorbidities['1. diabetes'],
      hypertension: comorbidities['2. hipertensión'],
      asthma: comorbidities['3. asma'],
      cancer: comorbidities['4. cáncer'],
      renalInsufficiency: comorbidities['5. insuficiencia renal'],
      enfCardiovascular: comorbidities['6. enf. cardiovascular'],
    },
  };
  const defaultState = {
    ...initialState,
    vaccineTypeId: '',
  };

  const [pacientData, setPacientData] = useState(initialState);
  const [vaccineTypes, setVaccineTypes] = useState([]);
  const [patientNames, setPatientNames] = useState('');
  const [stage, setStage] = useState('adults');
  const [identifier, setIdentifier] = useState('ced');
  const [valueComorbidities, setValueComorbidities] = useState('none');
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [modalData, setModalData] = useState({
    errorServer: false,
    title: null,
    body: {
      error: null,
      success: null,
      content: null,
    },
    buttonText: null,
  });

  const isYoungerForm = stage === RADIO_OPTION.younger;
  const [loteChange, setLoteChange] = useState('');
  const [comorbiditiesHandler, setComorbiditiesHandler] = useState(false);
  const [vaccineIdLabelAux, setVaccineIdLabelAux] = useState(vaccineId.label);

  useEffect(() => {
    const joinVaccineType = vaccineTypes.filter(
      ({ id }) => id === pacientData.vaccineTypeId
    );
    const keyVaccineType = Object.keys(joinVaccineType)[0];
    joinVaccineType[keyVaccineType] &&
      get(`/lotes?vaccineType=${joinVaccineType[keyVaccineType]['name']}`)
        .then(res => res.json())
        .then(({ data }) => setPacientData({ ...pacientData, loteId: data }));
    // eslint-disable-next-line
  }, [vaccineTypes, pacientData.vaccineTypeId]);

  useEffect(() => {
    get(`/vaccination/vaccine-types`)
      .then(res => {
        if (res.status === 401) {
          dispatch({
            type: USER_LOGOUT,
          });
        }
        return res.json();
      })
      .then(({ data }) => {
        setVaccineTypes(data || []);
      });

    setPacientData({ ...pacientData, vaccineId: '' });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let finder = pacientData.documentNumber;
    let pasaporte = pacientData.passportNumber;
    // const { vaccineId } = AppStorage.get('vaccineId', true) || {};
    // const { vaccineType } = AppStorage.get('center', true) || {};
    if (
      finder &&
      (stage === RADIO_OPTION.younger || identifier === RADIO_OPTION.id) &&
      finder.length === 11
    ) {
      if (/^([0-9])\1{10,}$/.test(finder)) {
        return setPacientData({
          ...initialState,
          vaccineId: '',
          loteId: pacientData.loteId,
          documentNumber: finder,
          passportNumber: pasaporte,
          validId: false,
        });
      }
      (async () => {
        const {
          valid,
          status_id,
          names,
          nextDose,
          previewDose,
          isRegisteredInLast15days,
          message,
          phoneNumber,
          laboralSector,
        } = (await Patient.getNamesFromAPI(finder)) || {};
        if (
          message &&
          message === 'The token is invalid or has not been provided.'
        ) {
          dispatch({
            type: USER_LOGOUT,
          });
          return;
        }
        const { vaccineTypeId = '', vaccineTypeName = '' } =
          (previewDose &&
            previewDose.length &&
            previewDose[previewDose.length - 1]) ||
          {};
        if (stage === RADIO_OPTION.younger || identifier === RADIO_OPTION.id) {
          setPacientData({
            documentNumber: finder,
            status_id,
            validId: valid,
            firstNames: names,
            previousDoses: previewDose || [],
            nextDose,
            vaccineTypeId:
              pacientData.vaccineTypeId || vaccineTypeId || vaccineType || '',
            numDose: !isYoungerForm ? (nextDose || 1).toString() : '',
            passportNumber: '',
            vaccineTypeName,
            vaccineId: '',
            loteId: pacientData.loteId,
            lastDose: previewDose && previewDose.length,
            phoneNumber,
            laboralSector: laboralSector || 'otros',
          });
        } else {
          setPacientData({
            documentNumber: '',
            status_id,
            validId: valid,
            firstNames: names,
            previousDoses: previewDose || [],
            nextDose,
            vaccineTypeId,
            numDose: !isYoungerForm ? (nextDose || 1).toString() : '',
            passportNumber: finder,
            vaccineTypeName,
            vaccineId: '',
            loteId: pacientData.loteId,
            lastDose: previewDose && previewDose.length,
            phoneNumber: '',
            laboralSector: 'otros',
          });
        }
      })();
    } else if (pasaporte) {
      setPacientData({
        ...initialState,
        vaccineId: '',
        loteId: pacientData.loteId,
        documentNumber: finder,
        passportNumber: pasaporte,
        numDose: '1',
      });
    }

    // eslint-disable-next-line
  }, [pacientData.documentNumber, pacientData.passportNumber]);

  const handleChange = ({ name, value }) => {
    const fieldsDontSpaceAtStart = [
      'firstNames',
      'youngerName',
      'youngerLastName',
    ];
    if (name === 'documentNumber' || name === 'phoneNumber') {
      value = value.replace(/\D/g, '');
    }
    setPacientData({
      ...pacientData,
      [name]: fieldsDontSpaceAtStart.includes(name)
        ? value.trimStart()
        : value.trim(),
      vaccineId: loteChange && loteChange,
    });
  };

  const handleDateChange = date => {
    setPacientData({ ...pacientData, youngerBornDate: date });
  };

  const handleScanID = async data => {
    const {
      valid,
      cedula,
      status_id,
      names,
      nextDose,
      previewDose,
      isRegisteredInLast15days,
      message,
      phoneNumber,
      laboralSector,
    } = await Patient.getNamesFromAPI(data);
    if (
      message &&
      message === 'The token is invalid or has not been provided.'
    ) {
      dispatch({
        type: USER_LOGOUT,
      });
      return;
    }

    if (!valid) {
      return setAttributes({
        openDialog: true,
        error:
          'El documento escaneado no es valido. Por favor trate de nuevo o digite la cédula',
      });
    }

    const { vaccineTypeId = '', vaccineTypeName = '' } =
      (previewDose &&
        previewDose.length &&
        previewDose[previewDose.length - 1]) ||
      {};
    setPacientData({
      documentNumber: cedula,
      status_id,
      validId: valid,
      firstNames: names,
      previousDoses: previewDose || [],
      nextDose,
      vaccineTypeId:
        pacientData.vaccineTypeId || vaccineTypeId || vaccineType || '',
      numDose: !isYoungerForm ? (nextDose || 1).toString() : '',
      passportNumber: '',
      vaccineTypeName,
      vaccineId: '',
      lastDose: previewDose && previewDose.length,
      phoneNumber,
      laboralSector: laboralSector || 'otros',
    });
  };
  const showError = message_error => {
    return setAttributes({
      openDialog: true,
      error: message_error,
    });
  };

  const handleSubmit = async () => {
    setDisabledBtn(true);
    if (
      identifier === RADIO_OPTION.id &&
      (!pacientData.documentNumber || !pacientData.validId)
    ) {
      return showError(errorMessage.verifyCed);
    } else if (
      identifier === RADIO_OPTION.passport &&
      !pacientData.passportNumber
    ) {
      return showError(errorMessage.verifyPassport);
    }

    if (!pacientData.firstNames) {
      return showError(errorMessage.verifyName);
    }

    if (!pacientData.phoneNumber) {
      return showError(errorMessage.verifyPhone);
    }

    if (stage === RADIO_OPTION.younger) {
      if (!pacientData.youngerName) {
        return showError(errorMessage.youngerName);
      }
      if (!pacientData.youngerBornDate) {
        return showError(errorMessage.youngerBornDate);
      }
      if (!pacientData.youngerSex) {
        return showError(errorMessage.youngerSex);
      }
      if (!pacientData.cardCode) {
        return showError(errorMessage.cardCode);
      }
    }
    if (!pacientData.laboralSector) {
      return showError(errorMessage.laboralSector);
    }
    if (!loteChange) {
      return showError(errorMessage.verifyVaccineCode);
    }
    AppStorage.set('vaccineId', { vaccineId: loteChange }, true);

    if (!pacientData.vaccineTypeId) {
      return showError(errorMessage.verifySelectVaccine);
    } else {
      const center = AppStorage.get('center', true) || {};
      AppStorage.set(
        'center',
        {
          ...center,
          vaccineType: pacientData.vaccineTypeId,
        },
        true
      );
    }

    if (!pacientData.numDose || parseInt(pacientData.numDose, 10) > 5) {
      return showError(errorMessage.verifyDosis);
    }
    if (
      isYoungerForm &&
      !isYoung(
        formatDatePicker(pacientData.youngerBornDate, '/'),
        pacientData.numDose
      )
    ) {
      return showError(errorMessage.isNotYoung);
    } else if (
      isYoungerForm &&
      isYoung(
        formatDatePicker(pacientData.youngerBornDate, '/'),
        pacientData.numDose
      ) === 1
    ) {
      return showError(errorMessage.isYoung);
    } else if (
      isYoungerForm &&
      isYoung(
        formatDatePicker(pacientData.youngerBornDate, '/'),
        pacientData.numDose
      ) === 2
    ) {
      return showError(errorMessage.isNotYoung);
    }

    pacientData.documentNumber =
      pacientData.documentNumber || pacientData.passportNumber.toUpperCase();

    await post('/vaccination', {
      ...pacientData,
      ...initialState.comorbidities,
      firstNames: pacientData.firstNames.toUpperCase(),
      latitude: longitude,
      longitude: latitude,
      vaccineId: loteChange.label,
      centerId,
      personId,
      phoneNumber: pacientData.phoneNumber,
      isIdNumber: identifier === RADIO_OPTION.id,
      cardCode: isYoungerForm ? pacientData.cardCode : null,
      documentType: identifier === RADIO_OPTION.id ? 'CEDULA' : 'PASAPORTE',
    }).then(({ status }) => {
      let errorMsg;
      switch (status) {
        case 201:
          ReactGA.event({
            category: 'vaccineded-register',
            action: `vaccineded saved`,
          });
          window.scrollTo({ top: 0, behavior: 'smooth' });
          setPacientData({
            ...defaultState
          });
          setLoteChange('');
          setPatientNames(pacientData.firstNames);
          setModalData({
            errorServer: false,
            title: 'Registrado con éxito',
            body: {
              content: {
                cedula: pacientData.documentNumber,
                passport: pacientData.passportNumber.toUpperCase(),
                fullName: pacientData.firstNames,
                phoneNumber: pacientData.phoneNumber,
                vaccine:
                  DOSIS_LABEL[pacientData.numDose] ||
                  `${pacientData.numDose} Dosis`,
                infoVaccine: `${vaccineTypes.find(
                  vaccine => vaccine.id === pacientData.vaccineTypeId
                ).name
                  } - ${loteChange.label}`,
                youngerName: pacientData.youngerName,
                youngerBornDate: formatDate2(pacientData.youngerBornDate),
                youngerSex: pacientData.youngerSex,
                cardCode: pacientData.cardCode,
                documentType: pacientData.documentType,
                diabetes: '',
                hypertension: '',
                asthma: '',
                cancer: '',
                renalInsufficiency: '',
                enfCardiovascular: '',
              },
            },
            buttonText: 'Registrar otro Paciente',
          });
          dispatch({
            type: RESET_COMORBIDITIES_STATES,
          });
          dispatch({
            type: RESET_STATES,
          });
          setShowFailedOpen(true);
          setDisabledBtn(false);
          break;
        case 400:
          errorMsg = errorMessage.invalidCed;
          break;
        case 401:
        case 403:
          errorMsg = errorMessage.invalidRegisterInLast24h;
          break;
        default:
          errorMsg = errorMessage.serverError;
      }

      setAttributes({ error: errorMsg, openDialog: Boolean(errorMsg) });
    });
    setValueComorbidities('none');
    setComorbiditiesHandler(false);
  };

  const handleErrorScanIdNumber = () => {
    setErrorScanIdNumber(true);
  };

  // const handleErrorScanVaccine = () => {
  //   setErrorScanVaccine(true);
  // };

  const handleClose = error => {
    if (!error) {
      setPacientData(defaultState);
    }
    setValueComorbidities('');
    setLoteChange('');
    setComorbiditiesHandler(false);
    setOpenDialog(false);
    setDisabledBtn(false);
  };

  const handleCloseDialog = () => {
    setShowFailedOpen(false);
  };

  const showWrongIcon =
    pacientData.validId === false && pacientData.documentNumber;

  const showCheckMarkIcon =
    pacientData.validId === true &&
    pacientData.documentNumber &&
    pacientData.documentNumber.length === 11;

  const doseOptions = [
    { name: '1ra dosis', value: '1' },
    { name: '2da dosis', value: '2' },
    { name: '3ra dosis', value: '3' },
    { name: '4ta dosis', value: '4' },
    { name: '5ta dosis', value: '5' },
  ];

  return (
    <div className={classes.card}>
      <Grid container spacing={4}>
        <Grid item xs={12} style={{ marginTop: '30px' }}>
          <Card className={classes.cardForCamera}>
            {errorScanIdNumber && (
              <Alert
                variant="filled"
                severity="warning"
                className={classes.alertWarning}
              >
                Problemas con el escanéo de la cédula
              </Alert>
            )}
            <Container>
              <Typography className={classes.sectionHeader}>
                Información del vacunado
              </Typography>
            </Container>
            <Container className={classes.btnContainer}>
              <QRCodeReader
                onScan={handleScanID}
                onError={handleErrorScanIdNumber}
                label="Escanear Cédula"
                passport={identifier === RADIO_OPTION.passport}
              />
            </Container>
            <Container>
              <ToggleButton
                value={identifier}
                name={'identifier'}
                options={[
                  { name: 'Cédula', value: RADIO_OPTION.id },
                  { name: 'Otra identificación', value: RADIO_OPTION.passport },
                ]}
                onChange={({ value }) => {
                  setPacientData({
                    ...initialState,
                    vaccineTypeId: pacientData.vaccineTypeId || vaccineType,
                    loteId: pacientData.loteId,
                  });
                  setIdentifier(value);
                  setValueComorbidities('');
                  setComorbiditiesHandler(false);
                }}
                styleTB={{ fontSize: '12px', width: '100%' }}
                styleTBG={classes.toggleButtonTop}
                styleContainer={{ margin: 'auto' }}
              />

              <ToggleButton
                value={stage}
                name={'stage'}
                options={[
                  { name: 'Adultos', value: RADIO_OPTION.adults },
                  { name: 'Menores de 18 años', value: RADIO_OPTION.younger },
                ]}
                onChange={({ value }) => {
                  setPacientData({
                    ...initialState,
                    vaccineTypeId: pacientData.vaccineTypeId || vaccineType,
                    loteId: pacientData.loteId,
                  });
                  setStage(value);
                  dispatch({ type: RESET_COMORBIDITIES_STATES });
                  setValueComorbidities('');
                  setComorbiditiesHandler(false);
                  for (
                    let e = 0;
                    e <
                    document.getElementsByClassName('MuiToggleButtonGroup-root')
                      .length;
                    e++
                  ) {
                    if (
                      document.getElementsByClassName(
                        'MuiToggleButtonGroup-root'
                      )[e].children.length === 5
                    ) {
                      value === 'younger'
                        ? (document.getElementsByClassName(
                          'MuiToggleButtonGroup-root'
                        )[e].children[4].style.display = 'none')
                        : (document.getElementsByClassName(
                          'MuiToggleButtonGroup-root'
                        )[e].children[4].style.display = 'inline-block');
                    }
                  }
                }}
                styleTB={{ fontSize: '12px', width: '100%' }}
                styleTBG={classes.toggleButtonTop}
                styleContainer={{ margin: 'auto' }}
              />
            </Container>
            <Container>
              {isYoungerForm && (
                <Typography className={classes.sectionHeader}>Tutor</Typography>
              )}
              <Container style={{ marginTop: 15 }}>
                <Typography style={{ textAlign: 'left' }}>
                  {[RADIO_OPTION.younger, RADIO_OPTION.id].includes(identifier)
                    ? 'Cédula'
                    : 'Otra identificación'}
                </Typography>
                {[RADIO_OPTION.younger, RADIO_OPTION.id].includes(
                  identifier
                ) ? (
                  <InputMaskComponent
                    onChange={({ target: { name, value } }) =>
                      handleChange({ name, value })
                    }
                    name="documentNumber"
                    label="Escanee la cédula o escríbala"
                    mask="999-9999999-9"
                    value={pacientData.documentNumber}
                    showWrongIcon={showWrongIcon}
                    showCheckMarkIcon={showCheckMarkIcon}
                  />
                ) : (
                  <TextField
                    value={pacientData.passportNumber}
                    name="passportNumber"
                    className={classes.passPortInput}
                    fullWidth
                    id="outlined-basic"
                    placeholder="Digite otra identificación"
                    variant="outlined"
                    onChange={({ target: { name, value } }) =>
                      handleChange({ name, value })
                    }
                  />
                )}
              </Container>
              <Container style={{ marginTop: 15 }}>
                <Typography className={classes.labelTitle}>
                  Nombre(s)
                </Typography>
                <TextField
                  color="secondary"
                  fullWidth
                  placeholder="Nombre del paciente"
                  name="firstNames"
                  value={pacientData.firstNames}
                  variant="outlined"
                  onChange={({ target: { name, value } }) =>
                    handleChange({ name, value })
                  }
                  autoComplete="off"
                />
              </Container>
              <Container style={{ marginTop: 15 }}>
                <Typography style={{ textAlign: 'left' }}>
                  Sector laboral
                </Typography>
                <Select
                  fullWidth
                  className={classes.classSelectInput}
                  variant="outlined"
                  color="secondary"
                  name="laboralSector"
                  value={pacientData.laboralSector}
                  onChange={({ target: { name, value } }) => {
                    handleChange({ name, value });
                  }}
                  displayEmpty
                >
                  <MenuItem
                    disabled
                    value=""
                    className={classes.placeHolderSelect}
                  >
                    Seleccione el sector laboral
                  </MenuItem>
                  {OPTIONS_LABORAL_SECTOR.map(({ label, value }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </Container>
              <Container style={{ marginTop: 15 }}>
                <Typography style={{ textAlign: 'left' }}>Teléfono</Typography>
                <InputMask
                  mask="999-999-9999"
                  value={pacientData.phoneNumber}
                  onChange={({ target: { name, value } }) =>
                    handleChange({ name, value })
                  }
                >
                  {inputProps => (
                    <TextField
                      {...inputProps}
                      color="secondary"
                      fullWidth
                      placeholder="Digíte el teléfono"
                      name="phoneNumber"
                      variant="outlined"
                      autoComplete="off"
                      type="tel"
                    />
                  )}
                </InputMask>
              </Container>

              {!isYoungerForm && (
                <>
                  <Container style={{ marginTop: 25, display: 'inline-block' }}>
                    <Typography style={{ float: 'left' }}>
                      Marque si tiene alguna comorbilidad
                    </Typography>
                  </Container>
                  <Container style={{ width: '75px', float: 'left' }}>
                    <Switch
                      color="primary"
                      disableRipple={true}
                      checked={comorbiditiesHandler}
                      onChange={() => {
                        comorbiditiesHandler
                          ? (function () {
                            return (
                              setComorbiditiesHandler(false),
                              setValueComorbidities('')
                            );
                          })()
                          : (function () {
                            return (
                              setComorbiditiesHandler(true),
                              setValueComorbidities('comorbidities'),
                              dispatch({ type: RESET_COMORBIDITIES_STATES })
                            );
                          })();
                      }}
                    />
                  </Container>
                  <Typography style={{ float: 'left', marginTop: 8 }}>
                    Comorbilidad
                  </Typography>
                  <Comorbidities
                    value={valueComorbidities}
                    setPacientData={setPacientData}
                    pacientData={pacientData}
                  />
                </>
              )}
            </Container>
          </Card>

          {isYoungerForm && (
            <Card className={classes.cardForCamera}>
              <Container>
                <Typography className={classes.sectionHeader}>
                  Información del menor
                </Typography>
              </Container>
              <Container>
                <Container style={{ marginTop: 15 }}>
                  <QRCodeReader
                    label="Escanear tarjeta"
                    onScan={data =>
                      setPacientData({
                        ...pacientData,
                        cardCode: data,
                      })
                    }
                  />
                  <Typography className={classes.labelTitle}>Código</Typography>
                  <TextField
                    color="secondary"
                    fullWidth
                    placeholder="A-XXX-XXX-XXX"
                    name="cardCode"
                    value={pacientData.cardCode}
                    variant="outlined"
                    onChange={({ target: { name, value } }) =>
                      handleChange({ name, value })
                    }
                    autoComplete="off"
                  />
                </Container>
                <Container style={{ marginTop: 15 }}>
                  <Typography className={classes.labelTitle}>
                    Nombre(s)
                  </Typography>
                  <TextField
                    color="secondary"
                    fullWidth
                    placeholder="Digite el nombre"
                    name="youngerName"
                    value={pacientData.youngerName}
                    variant="outlined"
                    onChange={({ target: { name, value } }) =>
                      handleChange({ name, value })
                    }
                    autoComplete="off"
                  />
                </Container>
                <Container style={{ marginTop: 15 }}>
                  <Typography className={classes.labelTitle}>
                    Apellido(s)
                  </Typography>
                  <TextField
                    color="secondary"
                    fullWidth
                    placeholder="Digite el apellido"
                    name="youngerLastName"
                    value={pacientData.youngerLastName}
                    variant="outlined"
                    onChange={({ target: { name, value } }) =>
                      handleChange({ name, value })
                    }
                    autoComplete="off"
                  />
                </Container>
                <Container style={{ marginTop: 15 }}>
                  <Typography className={classes.labelTitle}>
                    Fecha de Nacimiento
                  </Typography>
                  <DatePickerComponent
                    selectedDate={pacientData.youngerBornDate}
                    handleDateChange={handleDateChange}
                  />
                </Container>
                <ToggleButton
                  title={'Sexo'}
                  value={pacientData.youngerSex}
                  name={'youngerSex'}
                  options={[
                    { name: 'Masculino', value: 'M' },
                    { name: 'Femenino', value: 'F' },
                  ]}
                  onChange={handleChange}
                  styleTB={{ width: '10em' }}
                  styleTBG={classes.toggleButton}
                />
                <Container>
                  <Container style={{ display: 'inline-block', marginTop: 10 }}>
                    <Typography style={{ float: 'left' }}>
                      Marque si tiene alguna comorbilidad
                    </Typography>
                  </Container>
                  <Container style={{ width: 75, float: 'left' }}>
                    <Switch
                      color="primary"
                      disableRipple={true}
                      checked={comorbiditiesHandler}
                      onChange={() => {
                        comorbiditiesHandler
                          ? (function () {
                            return (
                              setComorbiditiesHandler(false),
                              setValueComorbidities('')
                            );
                          })()
                          : (function () {
                            return (
                              setComorbiditiesHandler(true),
                              setValueComorbidities('comorbidities'),
                              dispatch({ type: RESET_COMORBIDITIES_STATES })
                            );
                          })();
                      }}
                    />
                  </Container>
                </Container>
                <Typography
                  style={{ float: 'left', width: 'min-content', marginTop: 8 }}
                >
                  Comorbilidad
                </Typography>
                <Comorbidities
                  value={valueComorbidities}
                  setPacientData={setPacientData}
                  pacientData={pacientData}
                />
              </Container>
            </Card>
          )}
          <Card
            className={classes.cardForCamera}
            style={{ overflow: 'inherit' }}
          >
            {/* 
            -----Alerta relacionada al escaner del lote-----
            {errorScanVaccine && (
              <Alert
                variant="filled"
                severity="warning"
                className={classes.alertWarning}
              >
                Problemas con el escanéo de la vacuna
              </Alert>
            )} */}
            <Container>
              <Typography className={classes.sectionHeader}>Vacuna</Typography>
            </Container>
            <ToggleButton
              title={'Dosis Correspondiente'}
              name={'numDose'}
              value={pacientData.numDose}
              options={doseOptions}
              onChange={handleChange}
              styleTBG={classes.toggleButton}
            />
            <Container
              style={{ display: 'flex', paddingTop: 20, marginLeft: 16 }}
            >
              <FormControl fullWidth>
                <Typography className={classes.labelTitle}>
                  Tipo de vacuna
                </Typography>
                <Select
                  className={classes.selectInputClass}
                  variant="outlined"
                  color="secondary"
                  name="vaccineTypeId"
                  value={pacientData.vaccineTypeId}
                  defaultValue={vaccineType}
                  onChange={({ target: { name, value } }) => {
                    setLoteChange('');
                    setVaccineIdLabelAux('');
                    handleChange({ name, value });
                  }}
                  displayEmpty
                >
                  <MenuItem
                    disabled
                    value=""
                    className={classes.placeHolderSelect}
                  >
                    Seleccione tipo de vacuna
                  </MenuItem>
                  {vaccineTypes.map(vaccine => (
                    <MenuItem
                      key={vaccine.name.replace(/ /g, '_')}
                      value={vaccine.id}
                    >
                      {vaccine.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Container>

            <Container style={{ margin: '8px 0 0 15px' }}>
              <Typography className={classes.labelTitle}>
                Número de Lote
              </Typography>
              <CreatableSelect
                className={classes.creatableSelect}
                isClearable
                placeholder="No. de lote"
                onChange={setLoteChange}
                value={loteChange || vaccineIdLabelAux}
                defaultValue={{
                  value: vaccineIdLabelAux,
                  label: vaccineIdLabelAux,
                }}
                options={pacientData.loteId}
              />
            </Container>
            {/* </Grid>
              </Grid>
            </Grid> */}
          </Card>
          <Container style={{ marginTop: 10 }}>
            <Grid container direction="row" justify="space-between">
              <Button
                disabled={
                  !loteChange ||
                  (!pacientData.documentNumber &&
                    identifier === RADIO_OPTION.id) ||
                  (!pacientData.passportNumber &&
                    identifier === RADIO_OPTION.passport)
                }
                onClick={() => handleClose(false)}
                className={classes.btnClean}
                variant="outlined"
                color="primary"
              >
                <Typography>Borrar</Typography>
              </Button>
              <Button
                disabled={
                  (!pacientData.documentNumber &&
                    identifier === RADIO_OPTION.id) ||
                  (!pacientData.passportNumber &&
                    identifier === RADIO_OPTION.passport) ||
                  isPacientVaccineInLast24h ||
                  disabledBtn ||
                  (function (comorbiditiesHandler) {
                    return comorbiditiesHandler
                      ? (function (comorbidities) {
                        return Object.keys(comorbidities)
                          .map(comorbiditie =>
                            comorbidities[comorbiditie] ? true : void 0
                          )
                          .indexOf(true) >= 0
                          ? false
                          : true;
                      })(comorbidities)
                      : false;
                  })(comorbiditiesHandler)
                }
                onClick={handleSubmit}
                className={classes.btnSend}
                variant="contained"
                color="primary"
              >
                <Typography className={classes.labelSend}>Enviar</Typography>
              </Button>
            </Grid>
            {!isLocactionActive ? (
              <Alert severity="warning" className="text-left">
                Si desea registrar un vacunado tiene que permitir activar la
                Ubicación para este sitio web
              </Alert>
            ) : (
              ''
            )}
          </Container>
        </Grid>
      </Grid>
      {openDialog && (
        <SimpleModal
          onClose={handleClose}
          isOpen={openDialog}
          body={`La información no pudo ser guardada. ${error}.`}
        />
      )}
      <Dialog
        onClose={handleCloseDialog}
        open={showFailedOpen}
        aria-labelledby="customized-dialog-title"
        severity="error"
        variant="filled"
      >
        <DialogTitle className={classes.header} id="customized-dialog-title">
          {!modalData.errorServer ? (
            <img className={classes.img} src={checked} alt="checked" />
          ) : (
            <img
              className={classes.img}
              style={{ paddingBottom: '2%' }}
              src={imgErr}
              alt="err"
            />
          )}
          {modalData.title && modalData.title}
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.bodyContent}>
            {!modalData.errorServer && modalData.body.content ? (
              <>
                {isYoungerForm && (
                  <>
                    <Typography className={classes.title} gutterBottom>
                      Nombre(s)
                    </Typography>
                    <Typography gutterBottom>
                      {modalData.body.content.youngerName}
                    </Typography>
                    <Typography className={classes.title} gutterBottom>
                      Vacuna Suministrada
                    </Typography>
                    <Typography gutterBottom>
                      {modalData.body.content.vaccine}
                    </Typography>
                    <Typography className={classes.title} gutterBottom>
                      Info Vacuna Escaneada
                    </Typography>
                    <Typography gutterBottom>
                      {modalData.body.content.infoVaccine}
                    </Typography>
                    <Typography className={classes.title} gutterBottom>
                      Fecha
                    </Typography>
                    <Typography gutterBottom>
                      {modalData.body.content.youngerBornDate}
                    </Typography>
                    <Divider />
                    <Typography className={classes.titleYoungerForm}>
                      Tutor:
                    </Typography>
                  </>
                )}
                <Typography className={classes.title} gutterBottom>
                  {modalData.body.content.passport === ''
                    ? 'Cédula'
                    : 'Pasaporte'}
                </Typography>
                <Typography gutterBottom>
                  {modalData.body.content.passport === ''
                    ? modalData.body.content.cedula
                    : modalData.body.content.passport}
                </Typography>
                <Typography className={classes.title} gutterBottom>
                  Nombre(s)
                </Typography>
                <Typography gutterBottom>
                  {modalData.body.content.fullName}
                </Typography>
                <Typography className={classes.title} gutterBottom>
                  Teléfono
                </Typography>
                <Typography gutterBottom>
                  {modalData.body.content.phoneNumber}
                </Typography>
                {!isYoungerForm && (
                  <>
                    <Typography className={classes.title} gutterBottom>
                      Vacuna Suministrada
                    </Typography>
                    <Typography gutterBottom>
                      {modalData.body.content.vaccine}
                    </Typography>
                    <Typography className={classes.title} gutterBottom>
                      Info Vacuna Escaneada
                    </Typography>
                    <Typography gutterBottom>
                      {modalData.body.content.infoVaccine}
                    </Typography>
                  </>
                )}
              </>
            ) : (
              <Typography className={classes.bodyDialogErr} gutterBottom>
                {modalData.body.error}
              </Typography>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => setShowFailedOpen(false)}
            color="primary"
          >
            {modalData.buttonText}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        autoHideDuration={3000}
        onClose={() => setSuccessOpen(false)}
        open={successOpen}
      >
        <Alert
          onClose={() => setSuccessOpen(false)}
          severity="success"
          variant="filled"
        >
          <span className={classes.fontLabel}>
            {`${patientNames.toLowerCase()} se agregó exitosamente`}
          </span>
        </Alert>
      </Snackbar>
    </div>
  );
}
export default catchError(VaccinationRegister);
