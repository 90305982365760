import Token from './Token';
import AppStorage from './AppStorage';
import { post } from '../utils/fetch';

class User {
  hasToken() {
    const storedToken = AppStorage.getToken();
    if (!storedToken) {
      return false;
    } else {
      return Token.isValid(storedToken) ? true : this.logout();
    }
  }

  loggedIn() {
    return this.hasToken();
  }

  logout() {
    post('/auth/logout');
    AppStorage.clear();
    window.location = '/login';
  }

  async isValidPerson(idNumber) {
    return post('/auth/isActive', { idNumber });
  }
}
export default new User();
