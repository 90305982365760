import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  listCenters: {
    backgroundColor: '#94CDFF59 !important',
  },
  btn: {
    position: 'sticky',
    zIndex: 2,
    bottom: '8px',
    float: 'right',
    marginRight: '5px',
    borderRadius: '30px',
    boxShadow: '1px 6px 24px 0 rgb(7 94 84 / 20%)',
    transition: 'background .2s linear',
    '-webkit-tap-highlight-color': 'rgba(0,0,0,0)',
  },
}));
