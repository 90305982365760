/**
 * Returns the date in a user friendly format.
 * @param {Date} date
 * Return the surnames without repeating.
 * @param {last} last
 */

export const formatDate = date => {
  var d = new Date(date),
    month = d.getMonth() + 1,
    day = d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [year, month, day].join('-');
};

export default formatDate;
