import React, { useState } from 'react';
import PropTypes from 'prop-types';
import QrReader from 'react-qr-reader';
import { Button, IconButton, Typography, Tooltip } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import whiteIcon from '../../icons/Iconos_Check_Point_App_Blanco-07.svg';
import grayIcon from '../../icons/Iconos_Check_Point_App_Gris-07.svg';

import useStyles from './styled';

function QRCodeReader({
  onScan,
  onError,
  isScanned,
  label,
  sizeButton,
  passport,
}) {
  const classes = useStyles();
  const [isCameraOpen, setIsCameraOpen] = useState(false);

  const handleScan = data => {
    if (!data) return;
    onScan(data);
    setIsCameraOpen(false);
  };

  return (
    <div style={{ width: '100%' }}>
      {isCameraOpen ? (
        <>
          <Tooltip title="Cerrar Escaner" className={classes.closeScanner}>
            <IconButton
              color="secondary"
              onClick={() => {
                setIsCameraOpen(false);
              }}
            >
              <HighlightOffIcon fontSize="large" />
            </IconButton>
          </Tooltip>
          <QrReader
            delay={300}
            onError={onError}
            onScan={handleScan}
            style={{ width: '100%', overflow: 'unset' }}
            className={classes.reader}
          />
        </>
      ) : (
        ''
      )}
      <Button
        variant="contained"
        className={
          sizeButton === 'fullWidth'
            ? `${classes.button} escan-qr`
            : classes.buttonHalfSize
        }
        disabled={isCameraOpen || passport}
        startIcon={
          <img
            className={
              sizeButton === 'fullWidth' ? classes.icon : classes.iconHallfSize
            }
            src={isCameraOpen ? grayIcon : whiteIcon}
            alt="Scanner icon"
          />
        }
        style={{
          backgroundColor: passport ? '#dbdbdb' : '#00205C',
          borderRadius: 5,
          height: 55,
          color: '#fff',
        }}
        onClick={() => {
          label === 'Escanear Cédula' &&
            window.scrollTo({ top: 0, behavior: 'smooth' });
          setIsCameraOpen(true);
        }}
      >
        <div style={{ width: '100%', justifyContent: 'center' }}>
          <Typography
            className={
              sizeButton === 'fullWidth'
                ? classes.label
                : classes.labelHalffSize
            }
          >
            {label}
          </Typography>
        </div>
      </Button>
    </div>
  );
}

QRCodeReader.propTypes = {
  isCameraOpen: PropTypes.bool,
  isScanned: PropTypes.bool,
  label: PropTypes.string,
  sizeButton: PropTypes.string,
};

QRCodeReader.defaultProps = {
  isCameraOpen: false,
  isScanned: false,
  label: 'Escanear QR',
  sizeButton: 'fullWidth',
};

export default QRCodeReader;
