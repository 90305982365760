export const SECURITY = 'security';
export const COMPANY = 'company';
export const VACCINATION = 'vaccination';

export const OPTIONS_TAPS = {
  [SECURITY]: 'Seguridad',
  [COMPANY]: 'Compañía',
};

export const DEFAULT_RNC = '131023444';

export const OPTIONS_LABORAL_SECTOR = [
  { label: '1. Salud', value: 'salud' },
  { label: '2. Educación', value: 'educacion' },
  { label: '3. Turismo', value: 'turismo' },
  { label: '4. Transporte', value: 'transporte' },
  { label: '5.  Seguridad', value: 'seguridad' },
  { label: '6. Otros', value: 'otros' },
];

export const VACCINATION_CENTER = [
  'AMEDOSA-CLINICA SAN CRISTOBAL',
  'CECANOT',
  'CEDIMAT',
  'CEMEVE',
  'CENTRO COVIB 19 ESPAILLAT UBIERA',
  'CENTRO DE AISLAMIENTO Y CUIDADOS INTENSIVO GUIZA',
  'CENTRO DE CIRUGIA Y ESPECILIDADES MEDICAS MAIMON',
  'CENTRO DE ESPECIALIDADES DR. PAULINO REYES',
  'CENTRO DE ESPECIALIDADES MEDICAS',
  'CENTRO DE ESPECIALIDADES MEDICAS MONSEÑOR NOUEL',
  'CENTRO DE ESPECIALIDADES MEDICAS. COSTA NORTE',
  'CENTRO MATERNO INFANTIL Y ESPECIALIDADES',
  'CENTRO MEDICO BOURNIGAL',
  'CENTRO MEDICO CABARETE',
  'CENTRO MEDICO CEMENOR, S,R,L',
  'CENTRO MEDICO CENTRAL ROMANA',
  'CENTRO MEDICO CIBAO - UTESA',
  'CENTRO MEDICO DE ESPECIALIDADES UTESA',
  'CENTRO MEDICO DE LEON',
  'CENTRO MEDICO DR. BALTAZAR Y ASOCIADOS, SRL',
  'CENTRO MEDICO DR. MATEO ROSARIO',
  'CENTRO MEDICO DR. PEREZ',
  'CENTRO MEDICO DR. RAFAEL GRULLON',
  'CENTRO MEDICO DRA CABRERA',
  'CENTRO MEDICO EL MANIEL',
  'CENTRO MEDICO ELOHIM',
  'CENTRO MEDICO ESPECIALIZADO MACORIS',
  'CENTRO MEDICO GAZCUE',
  'CENTRO MEDICO GUADALUPE',
  'CENTRO MEDICO IRMIE',
  'CENTRO MEDICO MATERNO INFANTIL',
  'CENTRO MEDICO NACIONAL SAN FRANCISCO',
  'CENTRO MEDICO NAGUA (CEMNA)',
  'CENTRO MEDICO NUÑEZ HERNANDEZ',
  'CENTRO MEDICO PADRE FANTINO, S.R.L.',
  'CENTRO MEDICO REGIONAL AGUASVIVAS',
  'CENTRO MEDICO SIGLO 21',
  'CENTRO MEDICO UCE',
  'CENTRO MEDICO VISTA DEL JARDIN',
  'CENTRO POLICLINICO NACIONAL',
  'CLINICA ABREU',
  'CLINICA ALTAGRACIA',
  'CLINICA ARIAS',
  'CLINICA BAEZ SOTO SRL',
  'CLINICA BRUGAL',
  'CLINICA CAROLINA DE JESUS',
  'CLINICA COROMINAS, S.A.',
  'CLINICA CRUZ JIMINIAN',
  'CLINICA DOMINICO CUBANO',
  'CLINICA DR. BONILLA',
  'CLINICA DR. CANELO',
  'CLINICA DR. FRANKLIN PEÑA',
  'CLINICA DR. JACOBO',
  'CLINICA DR. PEROZO SRL',
  'CLINICA DR. UREÑA ARIAS',
  'CLINICA ESPECIALIZADA INTERNACIONAL. LAS TERRENAS',
  'CLINICA INDEPENDENCIA',
  'CLINICA LA CONCEPCION',
  'CLINICA RODRIGUEZ SANTOS',
  'CLÍNICA ROSA BENITA',
  'CLINICA UNION MEDICA DEL NORTE',
  'EPIDEMIOLOGO PROVINCIAL DE SALUD',
  'GINECOLOGIA Y OBSTETRICIA',
  'HEMMI',
  'HOSITAL TORIBIO BENCOSME',
  'HOSP. DR. PEDRO EMILIO DE MARCHENA',
  'HOSP. DR. ROMAN BAUTISTA BRACHE',
  'HOSP. MIL. DOCENTE, FARD "DR. RAMON DE LARA"',
  'HOSP. PROV. GRAL. MELENCIANO',
  'HOSPITAL ARISTIDES FIALLO CABRAL',
  'HOSPITAL BANICA',
  'HOSPITAL CAMBITA GARABITO CRUCE',
  'HOSPITAL CAMBITA PUEBLO',
  'HOSPITAL CARLOS A ZAFRA',
  'HOSPITAL DE NAVARRETE',
  'HOSPITAL DR. ALBERTO GAUTREAUX',
  'HOSPITAL DR. JAIME OLIVER PINO',
  'HOSPITAL DR. JOSE FAUSTO OVALLES',
  'HOSPITAL DR. JOSE PEREZ',
  'HOSPITAL DR. RAFAEL CANTISANO ARIAS',
  'HOSPITAL EL BUEN SAMARITANO',
  'HOSPITAL EL VALLE',
  'HOSPITAL ELUPINA CORDERO',
  'HOSPITAL FEDERICO L. LAVANDIER',
  'HOSPITAL FELIX MARIA GOICO',
  'HOSPITAL FRANCISCO A. GONZALVO',
  'HOSPITAL FRANCISCO MOSCOSO PUELLO',
  'HOSPITAL GENERAL DE LA PLAZA DE LA SALUD',
  'HOSPITAL GENERAL DE LA POLICIA',
  'HOSPITAL GIL ROLDAN',
  'HOSPITAL GREGORIO LUPERON',
  'HOSPITAL GUARIONEX ALCANTARA',
  'HOSPITAL IMG',
  'HOSPITAL INFANTIL DR. ARTURO GRULLON',
  'HOSPITAL INMACULADA CONCEPCIËN',
  'HOSPITAL JANICO',
  'HOSPITAL JOSE MARIA CABRAL Y BAEZ',
  'HOSPITAL JUAN PABLO PINA',
  'HOSPITAL JULIA SANTANA',
  'HOSPITAL LEOPOLDO MARTINEZ',
  'HOSPITAL LOS RIOS',
  'HOSPITAL M VILLA ALTAGRACIA',
  'HOSPITAL M. DR. PEDRO HEREDIA ROJAS',
  'HOSPITAL M. HAINA',
  'HOSPITAL MARCELINO VELEZ',
  'HOSPITAL MATERNO INFANTIL SAN LORENZO DE LOS MINA',
  'HOSPITAL METROPOLITANO DE SANTIAGO (HOMS)',
  'HOSPITAL MUNICIPAL DE BOHECHIO',
  'HOSPITAL MUNICIPAL DE IMBERT',
  'HOSPITAL MUNICIPAL DE PARTIDO',
  'HOSPITAL MUNICIPAL DE VALLEJUELO',
  'HOSPITAL MUNICIPAL DE VILLA ISABELA',
  'HOSPITAL MUNICIPAL DE YAMASA',
  'HOSPITAL MUNICIPAL EL CERCADO',
  'HOSPITAL MUNICIPAL HATO DEL YAQUE',
  'HOSPITAL MUNICIPAL HONDO VALLE',
  'HOSPITAL MUNICIPAL JACINTO IGNACIO MAÑON',
  'HOSPITAL MUNICIPAL LAGUNA SALADA',
  'HOSPITAL MUNICIPAL LICEY AL MEDIO',
  'HOSPITAL MUNICIPAL RESTAURACION',
  'HOSPITAL NUESTRA SEÑORA DE REGLA',
  'HOSPITAL NUESTRA SRA. DE LA ALTAGRACIA',
  'HOSPITAL PABLO MORROBEL JIMENEZ',
  'HOSPITAL PROVINCIAL DOCENTE RICARDO LIMARDO',
  'HOSPITAL PROVINCIAL DR. ANGEL CONTRERAS',
  'HOSPITAL PROVINCIAL GENERAL SANTIAGO RODRIGUEZ',
  'HOSPITAL RAFAEL J. MANON',
  'HOSPITAL RAMON ADRIANO VILLALONA',
  'HOSPITAL RAMON MATIAS MELLA',
  'HOSPITAL REG. ING. LUIS L. BOGAERT',
  'HOSPITAL REGIONAL DOCENTE DR. ALEJANDRO CABRAL',
  'HOSPITAL REGIONAL DR. ANTONIO MUSA',
  'HOSPITAL REGIONAL JAIME MOTA',
  'HOSPITAL REGIONAL UNIVERSITARIO DR. LUIS MANUEL MORILLO KING',
  'HOSPITAL RODOLFO DE LA CRUZ LORA',
  'HOSPITAL SAN BARTOLOME',
  'HOSPITAL SAN JOSE',
  'HOSPITAL SANTO CRISTO DE LOS MILAGROS',
  'HOSPITAL SIGIFREDO ALBA DOMINGUEZ',
  'HOSPITAL TAIWAN',
  'HOSPITEN',
  'INSTITUTO DE ESPECIALIDADES MEDICAS DEL NORDESTE, INEMED',
  'INSTITUTO DE ESPECIALIDADES MEDICAS GREGORIO HERNANDEZ',
  'POLICLINICO LA UNIËN',
  'POLICLINICO LA VEGA, S.A',
  'ROBERT REID CABRAL',
];

export const errorMessage = {
  verifyCed: 'Por favor verifique que la cédula esté correctamente digitada',
  verifyName: 'Por favor verifique que el nombre esté correctamente digitado',
  verifyPhone:
    'Por favor verifique que el número telefónico esté correctamente digitado',
  verifyVaccineCode:
    'Por favor, verifique que el lote de la vacuna esté seleccionado.',
  verifySelectVaccine: 'Por favor verifique que ha escogido el tipo de vacuna',
  verifyDosis:
    'Por favor verifique que haya seleccionado algún número de dosis',
  verifyPassport:
    'Por favor verifique que el pasaporte introducido esté correctamente digitado',
  invalidCed: 'El número de cédula introducido es inválido',
  invalidRegisterInLast24h:
    'El vacunado no puede registrarse más de una vez en un mismo dia.',
  serverError: 'Hubo un error en el servidor',
  activeLocation: 'Hubo un error en el servidor',
  youngerId:
    'Menor de edad: Por favor verifique que la identificacion esté correctamente digitada',
  youngerName:
    'Menor de edad: Por favor verifique que el nombre esté correctamente digitado',
  youngerBornDate:
    'Menor de edad: Por favor verifique que la fecha de nacimiento esté correctamente digitado',
  isNotYoung:
    'Favor verificar que ha colocado la fecha de nacimiento permitida para vacunación',
  isYoung: 'Los menores entre 5 y 11 años sólo se pueden colocar 2 dosis',
  youngerSex:
    'Menor de edad: Por favor verifique que el sexo esté seleccionado',
  laboralSector:
    'Por favor verifique que haya seleccionado algún sector laboral',
  cardCode: 'Por favor, verifique el código del menor esté añadido',
};
