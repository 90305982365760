import React, { useContext, useEffect, useReducer, useState } from 'react';
import {
  Typography,
  Card,
  Container,
  TextField,
  Grid,
  Button,
} from '@material-ui/core';
import ToggleButton from '../../../components/ToggleButton';

import { Alert } from '@material-ui/lab';

import { useLocation } from '../../../context/LocationContext';
import AppContext from '../../../context/AppContext';
import { USER_LOGOUT, RESET_STATES } from '../../../reducer/actions';
import reducer from '../../../utils/reducer';
import QRCodeReader from '../../../components/QRCodeReader';
import SymptomsFrom from './SymptomsFrom';
import Patient from '../../../helpers/Patient';
import useStyles from './styled';
import SimpleModal from '../../../components/SimpleModal';
import { catchError } from '../../../catchErrors/ErrorComponent';
import { Controller, useForm } from 'react-hook-form';
import InputMaskComponent from '../../../components/InputMask';

function PacientInfo({
  onSubmit,
  handleClose,
  disabledBtn,
  userData,
  setUserData,
}) {
  const classes = useStyles();
  const { dispatch } = useContext(AppContext);
  const { latitude, longitude } = useLocation();
  const [places, setPlaces] = useReducer(reducer, {});
  const [errorScanIdNumber, setErrorScanIdNumber] = useState();
  const [error, setError] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const { isLocactionActive } = useLocation();
  const { handleSubmit, control, getValues, setValue, reset } = useForm();
  const setAttributes = ({ openDialog = true, error = false }) => {
    setError(error);
    setOpenDialog(openDialog);
  };
  const {
    state: {
      userData: { centerId = '', personId },
    },
  } = useContext(AppContext);

  const [identifier, setIdentifier] = useState('ced');
  const RADIO_OPTION = {
    id: 'ced',
    passport: 'pass',
    adults: 'adults',
    younger: 'younger',
  };

  const [stage, setStage] = useState('adults');
  const isYoungerForm = stage === RADIO_OPTION.younger;
  const [isYounger, setIsYounger] = useState(isYoungerForm);
  const isNameform = identifier === 'ced';
  const isOtherIdForm = stage === RADIO_OPTION.passport;

  const initialState = {
    documentNumber: '',
    firstNames: '',
    centerId,
    personId,
    youngerName: '' || null,
    youngerId: '' || null,
    isOtherIdForm,
    isYounger: isYoungerForm,
  };

  const [symptomsInformation, setSymptomsInformation] = useState(initialState);

  useEffect(() => {
    if (!places.latitude && latitude && longitude) {
      setPlaces({
        latitude,
        longitude,
      });
    }
    // eslint-disable-next-line
  }, [latitude, longitude]);

  const handleChange = async ({ target }) => {
    dispatch({ type: RESET_STATES });
    if (identifier === 'ced') {
      if (target.name === 'documentNumber') {
        target.value = target.value.replace(/\D/g, '');
        if (target.value && target.value.length === 11) {
          (async () => {
            const { valid, names, message } =
              (await Patient.getNamesFromAPI(target.value)) || {};

            if (
              message &&
              message === 'The token is invalid or has not been provided.'
            ) {
              dispatch({
                type: USER_LOGOUT,
              });
              return;
            }
            setValue('firstNames', names || '');
            setUserData({
              ...getValues(),
              isValid: valid,
              firstNames: names,
              isYounger: isYoungerForm,
              isNameform,
            });
          })();
        }
      }
    }
  };

  const handleInputChange = ({ target }) => {
    const { name, value } = target;

    if (name === 'youngerName' || name === 'firstNames') {
      setSymptomsInformation({
        ...symptomsInformation,
        [target.name]: value
          .replace(/[^a-z_ ]/gi, '')
          .toUpperCase()
          .trimStart(),
      });
      setUserData({
        ...userData,
        [name]: value,
      });
    } else if (name === 'documentNumber' || name === 'youngerId') {
      setSymptomsInformation({
        ...symptomsInformation,
        [name]: value.trim(),
      });
      setUserData({
        ...userData,
        [name]: value,
      });
    }
  };

  const handleScanID = async data => {
    const { names, valid, cedula, message } = await Patient.getNamesFromAPI(
      data
    );

    if (
      message &&
      message === 'The token is invalid or has not been provided.'
    ) {
      dispatch({
        type: USER_LOGOUT,
      });
      return;
    }

    if (!valid) {
      return setAttributes({
        openDialog: true,
        error: 'Este tipo de documento no es válido',
      });
    }
    setValue('firstNames', names || '');
    setValue('documentNumber', cedula || '');
    setUserData({
      ...getValues(),
      isValid: valid,
      firstNames: names,
      documentNumber: cedula,
    });
  };

  const handleErrorScanIdNumber = () => {
    setErrorScanIdNumber(true);
  };

  const { isValid, documentNumber } = userData;
  const showWrongIcon = isValid === false && documentNumber;
  const showCheckMarkIcon = isValid === true && documentNumber;

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)} onChange={handleChange}>
        <Grid container spacing={4}>
          <Grid item xs={12} style={{ marginTop: '30px' }}>
            <Card className={classes.cardForCamera}>
              <Container>
                <Typography variant="h5" className={classes.sectionHeader}>
                  Información del vacunado
                </Typography>
              </Container>
              {errorScanIdNumber && (
                <Alert
                  variant="filled"
                  severity="warning"
                  className={classes.alertWarning}
                >
                  Problemas con el escanéo de la cédula
                </Alert>
              )}
              <Container className={classes.btnContainer}>
                <QRCodeReader
                  onScan={handleScanID}
                  onError={handleErrorScanIdNumber}
                  label="Escanear Cédula"
                />
              </Container>
              <Container>
                <ToggleButton
                  value={identifier}
                  name={'identifier'}
                  options={[
                    { name: 'Cédula', value: RADIO_OPTION.id },
                    {
                      name: 'Otra identificación',
                      value: RADIO_OPTION.passport,
                    },
                  ]}
                  onChange={({ value }) => {
                    setUserData(initialState);
                    setSymptomsInformation(initialState);
                    setIdentifier(value);
                  }}
                  styleTB={{ fontSize: '12px', width: '100%' }}
                  styleTBG={classes.toggleButtonTop}
                  styleContainer={{ margin: 'auto' }}
                />

                <ToggleButton
                  value={stage}
                  name={'stage'}
                  options={[
                    { name: 'Adultos', value: RADIO_OPTION.adults },
                    { name: 'Menores de 18 años', value: RADIO_OPTION.younger },
                  ]}
                  onChange={({ value }) => {
                    setIsYounger(!isYounger);
                    setUserData(initialState);
                    setStage(value);
                    setSymptomsInformation(initialState);
                    // dispatch({ type: RESET_STATES });
                  }}
                  styleTB={{ fontSize: '12px', width: '100%' }}
                  styleTBG={classes.toggleButtonTop}
                  styleContainer={{ margin: 'auto' }}
                />
              </Container>
              <Container>
                {isYoungerForm && (
                  <Typography className={classes.sectionHeader}>
                    Tutor
                  </Typography>
                )}
                <Container style={{ marginTop: 15 }}>
                  <Typography style={{ textAlign: 'left' }}>
                    {[RADIO_OPTION.younger, RADIO_OPTION.id].includes(
                      identifier
                    )
                      ? 'Cédula'
                      : 'Otra identificación'}
                  </Typography>
                  {[RADIO_OPTION.younger, RADIO_OPTION.id].includes(
                    identifier
                  ) ? (
                    <Controller
                      control={control}
                      name="documentNumber"
                      as={
                        <InputMaskComponent
                          onChange={handleChange}
                          name="documentNumber"
                          label="Escanee la cédula o escríbala"
                          mask="999-9999999-9"
                          value={userData.documentNumber}
                          showWrongIcon={showWrongIcon}
                          showCheckMarkIcon={showCheckMarkIcon}
                        />
                      }
                    />
                  ) : (
                    <div>
                      <TextField
                        value={symptomsInformation.documentNumber}
                        name="documentNumber"
                        className={classes.passPortInput}
                        fullWidth
                        id="outlined-basic"
                        placeholder="Digite otra identificación"
                        variant="outlined"
                        onChange={handleInputChange}
                        autoComplete="off"
                      />
                      <Typography className={classes.labelTitle}>
                        Nombre(s)
                      </Typography>
                      <TextField
                        color="secondary"
                        fullWidth
                        placeholder="Nombre del paciente"
                        name="firstNames"
                        value={symptomsInformation.firstNames}
                        variant="outlined"
                        onChange={handleInputChange}
                        autoComplete="off"
                      />
                    </div>
                  )}
                </Container>
                {isNameform && (
                  <Container style={{ marginTop: 15 }}>
                    <Typography className={classes.labelTitle}>
                      Nombre(s)
                    </Typography>
                    <Controller
                      control={control}
                      name="firstNames"
                      as={
                        <TextField
                          color="secondary"
                          fullWidth
                          placeholder={'Nombre del paciente'}
                          name="firstNames"
                          variant="outlined"
                          autoComplete="off"
                        />
                      }
                    />
                  </Container>
                )}
              </Container>
            </Card>
            {isYoungerForm && (
              <Card className={classes.cardForCamera}>
                <Container>
                  <Typography className={classes.sectionHeader}>
                    Información del menor
                  </Typography>
                </Container>
                <Container>
                  <Container style={{ marginTop: 15 }}></Container>
                  <Container style={{ marginTop: 15 }}>
                    <Typography className={classes.labelTitle}>
                      Nombre(s)
                    </Typography>
                    <TextField
                      color="secondary"
                      fullWidth
                      placeholder="Digite el nombre"
                      name="youngerName"
                      value={symptomsInformation.youngerName}
                      variant="outlined"
                      onChange={handleInputChange}
                      autoComplete="off"
                    />
                  </Container>
                </Container>
              </Card>
            )}
          </Grid>
        </Grid>
        <Card className={classes.cardForCamera}>
          <Container>
            <Typography className={classes.sectionHeader}>
              Síntomas post-vacunación
            </Typography>
            <Typography className={classes.subLabel}>
              Selecccione los síntomas del vacunante
            </Typography>
          </Container>
          <Container style={{ display: 'flex', paddingTop: 20, width: '90%' }}>
            <SymptomsFrom />
          </Container>
        </Card>
        {openDialog && (
          <SimpleModal
            onClose={() => setOpenDialog(false)}
            isOpen={openDialog}
            body={`La información no pudo ser guardada. ${error}.`}
          />
        )}
        <Container style={{ marginTop: 10, padding: 0 }}>
          <Grid container direction="row" justify="space-between">
            <Button
              disabled={
                (!userData.documentNumber && identifier === RADIO_OPTION.id) ||
                (!userData.documentNumber &&
                  identifier === RADIO_OPTION.passport)
              }
              onClick={() => {
                reset({
                  firstNames: '',
                  documentNumber: '',
                });
                setSymptomsInformation(initialState);
                handleClose();
              }}
              className={classes.btnClean}
              variant="outlined"
              color="primary"
            >
              <Typography>Borrar</Typography>
            </Button>
            <Button
              type="submit"
              disabled={
                (!userData.documentNumber && identifier === RADIO_OPTION.id) ||
                (!userData.documentNumber &&
                  identifier === RADIO_OPTION.passport) ||
                disabledBtn
              }
              className={classes.btnSend}
              variant="contained"
              color="primary"
            >
              <Typography className={classes.labelSend}>Enviar</Typography>
            </Button>
          </Grid>
          {!isLocactionActive ? (
            <Alert severity="warning" className="text-left">
              Si desea registrar un vacunado tiene que permitir activar la
              Ubicación para este sitio web
            </Alert>
          ) : (
            ''
          )}
        </Container>
      </form>
    </React.Fragment>
  );
}

export default catchError(PacientInfo);
