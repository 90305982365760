import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => {
  return {
    header: {
      height: '9em',
      paddingTop: '4em',
      paddingBottom: '1.5em',
      [theme.breakpoints.down(400)]: {
        paddingBottom: '0',
      },
    },
    img: {
      display: 'flex',
      margin: 'auto',
      justifyContent: 'center',
      alignContent: 'center',
      width: '35%',
      [theme.breakpoints.down(400)]: {
        width: '50%',
      },
    },
    bodyLogin: {
      color: '#00205C',
      fontSize: '20px',
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
      textAlign: 'center',
      justifyContent: 'center',
      margin: '2%',
    },
  };
});
