import React, { useEffect, useState, useContext } from 'react';
// Google Analytics
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
  Container,
  Chip,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Moment from 'react-moment';
import { FilterList } from '@material-ui/icons';
import { get } from '../../utils/fetch';
import AppStorage from '../../helpers/AppStorage';
import formatDate from '../../utils/formatDate';
import QRCodeReader from '../../components/QRCodeReader';
import Patient from '../../helpers/Patient';
import AppContext from '../../context/AppContext';
import { USER_LOGOUT } from '../../reducer/actions';
import { catchError } from '../../catchErrors/ErrorComponent';
import useStyles from './styled';

function Appointment() {
  const classes = useStyles();
  const { centerId, name } = AppStorage.get('center', true) || {};
  const [users, setUsers] = useState([]);
  const momentDate = Date();
  const date = formatDate(momentDate);
  const [filterUsers, setFiltersUsers] = useState();
  const [idNumberField, setIdNumberField] = useState('');
  const [errorScanIdNumber, setErrorScanIdNumber] = useState();
  const [loading, setLoading] = useState(false);
  const { dispatch } = useContext(AppContext);

  const handleChange = ({ target: { value } }, pattern) => {
    value = value.replace(/\D/g, '');
    setFiltersUsers(users);
    if (!idNumberField.match(pattern)) {
      setIdNumberField(value.slice(0, value.length - 1));
    } else {
      setIdNumberField(value);
    }
    setFiltersUsers(users => {
      return users.filter(user => user.idNumber.indexOf(value) > -1);
    });
  };

  useEffect(() => {
    setLoading(true);
    get(`/vaccination/quotes?CenterId=${centerId}&limit=${500}`)
      .then(res => {
        if (res.status === 401) {
          dispatch({
            type: USER_LOGOUT,
          });
        }
        return res.json();
      })
      .then(({ data }) => {
        setUsers(data || []);
        setFiltersUsers(data || []);
        setLoading(false);
      });
  }, [centerId, date, name, dispatch]);

  const handleScanID = async data => {
    const { valid, cedula, message } = await Patient.getNamesFromAPI(data);
    if (
      message &&
      message === 'The token is invalid or has not been provided.'
    ) {
      dispatch({
        type: USER_LOGOUT,
      });
      return;
    }
    setErrorScanIdNumber(false);
    if (!valid) return;
    handleChange({ target: { value: cedula } });
  };
  const handleErrorScanIdNumber = () => {
    setErrorScanIdNumber(true);
  };

  return (
    <div className={classes.root}>
      {users && users.length > 0 ? (
        <>
          {errorScanIdNumber && (
            <Alert
              variant="filled"
              severity="warning"
              className={classes.alertWarning}
            >
              Problemas con el escanéo de la cédula
            </Alert>
          )}
          <Container className={classes.btnContainer}>
            <QRCodeReader
              onScan={handleScanID}
              onError={handleErrorScanIdNumber}
              label="Escanear Cédula"
            />
          </Container>
        </>
      ) : (
        ''
      )}
      <Paper className={classes.paper}>
        <div className={classes.tableTitleContainer}>
          {users && users.length > 0 ? (
            <h3 className={classes.tableTitle}>
              Citas correspondientes a{' '}
              <Moment format="DD/MM/YYYY">{date}</Moment>
            </h3>
          ) : (
            ''
          )}
          {users && users.length > 0 && (
            <>
              <TextField
                color="secondary"
                id="outlined-text-input"
                placeholder="Filtrar por cédula"
                name="idNumberField"
                value={idNumberField}
                onChange={e => handleChange(e, /^[0-9]{0,10}$/)}
                type="number"
                variant="outlined"
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FilterList />
                    </InputAdornment>
                  ),
                }}
              />
              <Chip className={classes.chipNumberUsers} label={users.length} />
            </>
          )}
        </div>
        <TableContainer>
          <Table>
            {filterUsers && filterUsers.length > 0 ? (
              <TableHead>
                <TableRow>
                  <TableCell className={classes.head} align="center">
                    Nombre(s)
                  </TableCell>
                  <TableCell className={classes.head} align="center">
                    Cédula
                  </TableCell>
                </TableRow>
              </TableHead>
            ) : null}
            <TableBody>
              {filterUsers && filterUsers.length > 0 ? (
                filterUsers.map(({ name, idNumber }, index) => {
                  return (
                    <TableRow
                      hover
                      key={name + index}
                      style={
                        filterUsers.length === 1 && idNumberField.length === 11
                          ? { backgroundColor: '#22B900' }
                          : { backgroundColor: '#fff' }
                      }
                    >
                      <TableCell align="center">{name}</TableCell>
                      <TableCell align="center">{idNumber}</TableCell>
                    </TableRow>
                  );
                })
              ) : users.length > 0 ? (
                <TableRow>
                  <TableCell align="center" colSpan="2">
                    <Alert severity="error" style={{ margin: '2%' }}>
                      Cédula no encontrada
                    </Alert>
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow className={classes.emptyRow}>
                  <TableCell align="center" colSpan="2">
                    <span className={classes.tableLabel}>
                      {loading
                        ? 'Cargando pacientes...'
                        : 'No hay pacientes registrados'}
                    </span>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
export default catchError(Appointment);
