export const idValidator = id => {
  const validatorDigit = parseInt(id.substring(id.length - 1));
  const sequence = id.substring(0, 10).split('').reverse();
  const luhnDigit =
    sequence.reduce((acc, current, index) => {
      const currentDigit = parseInt(current);

      if (index % 2 === 0) {
        const doubleCurrent = currentDigit * 2;

        return doubleCurrent >= 10
          ? acc + (doubleCurrent - 9)
          : acc + doubleCurrent;
      }

      return acc + currentDigit;
    }, 0) % 10;

  return (
    luhnDigit === 0 ||
    luhnDigit === validatorDigit ||
    10 - luhnDigit === validatorDigit
  );
};
