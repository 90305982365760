const months = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

/**
 * Returns the date in a user friendly format.
 * @param {Date} date
 */
export const formatDate = (date, page) => {
  if (typeof date === 'string') date = new Date(date);

  const year = date.getFullYear();
  const month = months[date.getMonth()];
  const day = date.getDate();
  const hours = date.getHours() % 12;
  const minutes = `${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}`;
  const amOrPm = date.getHours() >= 12 ? 'PM' : 'AM';
  if (page === 'reports') {
    return `${day}, ${month} - ${year}`;
  }
  return `${month} ${day}, ${year} ${hours}:${minutes} ${amOrPm}`;
};

export const formatDate2 = date => {
  var d = new Date(date),
    month = d.getMonth() + 1,
    day = d.getDate(),
    year = d.getFullYear();
  return [year, day, month].join('-');
};

export const formatDatePicker = (date, divider) => {
  const dateNow = new Date(date);
  let month = dateNow.getMonth() + 1;
  let day = dateNow.getDate();
  let year = dateNow.getFullYear();
  if (month.toString().length === 1) month = '0' + month;
  if (day.toString().length < 2) day = '0' + day;

  if (divider.toString() === '-') {
    return [year, day, month].join('-');
  }
  if (divider.toString() === '/') {
    return [year, month, day].join('/');
  }
};
export default { formatDate, formatDate2, formatDatePicker };
