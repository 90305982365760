import { useState, useCallback, useEffect } from 'react';
import * as serviceWorker from '../serviceWorker';
import ReactGA from 'react-ga';

const isProduction = process.env.NODE_ENV === 'production';

export const useServiceWorker = () => {
  const [waitingWorker, setWaitingWorker] = useState({});
  const [showReload, setShowReload] = useState(false);

  const onSWUpdate = useCallback(
    registration => {
      setShowReload(true);
      setWaitingWorker(registration.waiting);
    },
    [setShowReload, setWaitingWorker]
  );

  const reloadPage = useCallback(() => {
    waitingWorker && waitingWorker.postMessage({ type: 'SKIP_WAITING' });
    setShowReload(false);
    window.location.reload();
  }, [setShowReload, waitingWorker]);

  useEffect(() => {
    serviceWorker.register({
      onUpdate: onSWUpdate,
    });
    if (isProduction) {
      ReactGA.initialize('UA-190012584-1');
    }
  }, [onSWUpdate]);

  return { showReload, waitingWorker, reloadPage };
};
