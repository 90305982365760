import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  button: {
    position: 'absolute',
    left: '75%',
    top: '55%',
  },
  modal: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    height: 80,
    margin: 'auto',
    padding: theme.spacing(2, 3, 5.5),
    position: 'relative',
    width: `70%`,
  },
}));
