import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import './styles/index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
const isProduction = process.env.NODE_ENV === 'production';

if (isProduction) {
  Sentry.init({
    dsn:
      'https://51e58d43abba467187dcd8a19f7a620b@o577078.ingest.sentry.io/5731687',
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.NODE_ENV,
    maxBreadcrumbs: 10,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

process.env.NODE_ENV === 'development'
  ? serviceWorker.unregister()
  : serviceWorker.register();
