import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => {
  return {
    root: {
      position: 'absolute',
      bottom: '0',
      top: '380px',
      left: '0',
      margin: 'auto',
      height: '100%',
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      textAlign: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down(700)]: {
        top: '260px',
      },
    },
    paper: {
      maxWidth: '730px',
      marginLeft: '5%',
      marginRight: '5%',
      marginTop: '3%',
      width: '100%',
    },
    tableTitle: {
      color: '#00205C',
      fontSize: '20px',
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
      [theme.breakpoints.down(700)]: {
        fontSize: '18px',
        margin: '2% 1% 2% 1%',
      },
    },
    tableTitleContainer: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      margin: '0 5% 0 5%',
      textAlign: 'left',
      [theme.breakpoints.down(700)]: {
        display: 'unset',
        textAlign: 'center',
      },
    },
  };
});
