import React, { useState, useReducer, useEffect } from 'react';
// Google Analytics
import ReactGA from 'react-ga';
import {
  Card,
  TextField,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { Link, Redirect } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';

import UserType from './UserType';
import { post } from '../../utils/fetch';
import icon from '../../../src/icons/Vacunaterd@2x.svg';
import reducer from '../../utils/reducer';
import { idValidator } from '../../utils/idValidator';
import { useLocation } from '../../context/LocationContext';
import { Alert } from '@material-ui/lab';
import { catchError } from '../../catchErrors/ErrorComponent';
import checked from '../../icons/checked.png';
import imgErr from '../../icons/cancelar.png';
import useStyles from './styled';
import InputMaskComponent from '../../components/InputMask';

import {
  SECURITY,
  COMPANY,
  VACCINATION,
  OPTIONS_TAPS,
  DEFAULT_RNC,
} from '../../const';

const { REACT_APP_FEAT_VACCINE } = process.env;

function SingUp() {
  const classes = useStyles();
  const [state, setState] = useReducer(reducer, {
    signInError: false,
    target: REACT_APP_FEAT_VACCINE ? VACCINATION : SECURITY,
    errorData: {},
    userData: {},
    pathRedirect: false,
  });
  const { userData, target } = state;
  const [errorServer, setErrorServer] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isSignUpSuccess, setIsSignUpSuccess] = useState(false);
  const [isRedirect, setIsRedirect] = useState(false);
  const isSecurity = target === SECURITY;
  const isVaccination = target === VACCINATION;
  const [status, setStatus] = useState();
  const [disabledBtn, setDisabledBtn] = useState(false);
  const { isLocactionActive } = useLocation();

  const { handleSubmit, control, errors, getValues, setValue } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    // Google Analytics /signup page
    ReactGA.pageview('/signup');
  }, []);

  const handleRadioChange = event => {
    setState(event.target.value);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setErrorServer(false);
    setDisabledBtn(false);
    if (isSignUpSuccess) {
      setIsRedirect(true);
      setIsSignUpSuccess(false);
    }
  };

  const handleChange = ({ target: { name, value } }) => {
    value = value.replace(/\D/g, '');
    if (value.length === 11) {
      if (idValidator(value)) {
        setStatus(true);
      } else {
        setStatus(false);
      }
    } else {
      setStatus(false);
    }
    setValue('idNumber', value);
    setState({ userData: { ...userData, [name]: value } });
  };

  const handleSignUp = e => {
    setDisabledBtn(true);
    const userData = getValues();
    const params = { phone: userData.phone.replace(/[() -._]/g, '') };

    if (isSecurity || isVaccination) {
      Object.assign(params, {
        idNumber: state.userData.idNumber.replace(/\D/g, ''),
        centerId: state.userData.centerId,
      });
    }

    if (isVaccination) {
      Object.assign(params, {
        rnc: DEFAULT_RNC,
      });
    }

    const requestBody = Object.assign({}, userData, params, {
      role: target,
      name: userData.firstName,
    });
    const errorMsgs = {
      company: {
        403: 'Por favor ingrese un RNC válido.',
        409: 'Este RNC ha sido registrado anteriormente.',
      },
      security: {
        400: 'Por favor ingrese su cédula en un formato válido.',
        401: 'Credenciales no proporcionadas, por favor verifique si su empresa se encuentra autenticada.',
        403: 'El RNC no se encuentra registrado como empresa o es inválido.',
        409: 'Este empleado ha sido registrado anteriormente.',
      },
      vaccination: {
        401: 'Este usuario ha sido registrado anteriormente.',
      },
    };

    return post(
      `/${target === COMPANY ? 'companies' : 'securities'}/add`,
      requestBody
    )
      .then(response => {
        if (response.status === 201) {
          ReactGA.event({
            category: 'register',
            action: 'user register',
          });
          setErrorServer(false);
          setIsSignUpSuccess(true);
        } else {
          throw new Error(errorMsgs[target][response.status]);
        }
      })
      .catch(message => setErrorServer(message))
      .finally(() => setOpenDialog(true));
  };

  const canSignUp = () => {
    const getUserData = getValues();

    const {
      confirmPassword,
      firstName,
      lastName,
      password,
      phone,
      rnc = DEFAULT_RNC,
    } = getUserData;

    if (
      confirmPassword &&
      firstName &&
      lastName &&
      password &&
      phone &&
      rnc &&
      status
    )
      return !Object.keys(errors).some(key => !!errors[key] === true);

    return false;
  };

  const showCheckMarkIcon = status === true;
  const showWrongIcon = status === false && userData.idNumber !== '';

  return (
    <React.Fragment>
      <Card className={classes.container}>
        <div className={classes.title}>
          <img alt="vaccine logo" className={classes.logoImage} src={icon} />
          <Typography
            variant="h4"
            gutterBottom
            className={classes.titleRegister}
          >
            Registrate
          </Typography>
        </div>

        {!REACT_APP_FEAT_VACCINE && (
          <UserType
            onChange={handleRadioChange}
            options={OPTIONS_TAPS}
            value={target}
          />
        )}

        <form
          onSubmit={handleSubmit(handleSignUp)}
          className={classes.textFields}
        >
          <label className={classes.labelTitle}>Nombre(s)</label>
          <Controller
            defaultValue=""
            name="firstName"
            control={control}
            error={Boolean(errors.firstName)}
            helperText={errors.firstName && errors.firstName.message}
            as={
              <TextField
                className={classes.inputClass}
                color="secondary"
                placeholder="Escriba sus nombres"
                type="text"
                variant="outlined"
                autoComplete="off"
              />
            }
            rules={{
              required: 'Este campo es requerido',
              pattern: {
                value: /^\S[0-9a-zA-Z ]+$/,
                message: 'Este campo solo permite letras y números',
              },
            }}
          />
          {(isSecurity || isVaccination) && (
            <React.Fragment>
              <label className={classes.labelTitle}>Apellidos</label>
              <Controller
                defaultValue=""
                name="lastName"
                control={control}
                error={Boolean(errors.lastName)}
                helperText={errors.lastName && errors.lastName.message}
                as={
                  <TextField
                    className={classes.inputClass}
                    color="secondary"
                    placeholder="Escriba sus apellidos"
                    type="text"
                    variant="outlined"
                    autoComplete="off"
                  />
                }
                rules={{
                  required: 'Este campo es requerido',
                  pattern: {
                    value: /^\S[0-9a-zA-Z ]+$/,
                    message: 'Este campo solo permite letras y números',
                  },
                }}
              />
              <label className={classes.labelTitle}>Cédula</label>
              <Controller
                defaultValue=""
                name="idNumber"
                control={control}
                render={() => {
                  return (
                    <InputMaskComponent
                      onChange={e => handleChange(e)}
                      name="idNumber"
                      label="Escriba su cédula"
                      mask="999-9999999-9"
                      value={userData.idNumber}
                      showWrongIcon={showWrongIcon}
                      showCheckMarkIcon={showCheckMarkIcon}
                    />
                  );
                }}
              />
            </React.Fragment>
          )}

          <label className={classes.labelTitle}>Contraseña</label>
          <Controller
            defaultValue=""
            className={classes.formControl}
            name="password"
            control={control}
            error={Boolean(errors.password)}
            helperText={errors.password && errors.password.message}
            as={
              <TextField
                className={classes.inputClass}
                color="secondary"
                placeholder="Escriba su contraseña"
                type="password"
                variant="outlined"
                autoComplete="off"
              />
            }
            rules={{
              required: 'Este campo es requerido',
              pattern: {
                value: /^[0-9a-zA-Z]+$/,
                message: 'Este campo solo permite letras y números',
              },
            }}
          />
          <label className={classes.labelTitle}>Confirme contraseña</label>
          <Controller
            className={classes.formControl}
            defaultValue=""
            name="confirmPassword"
            error={Boolean(errors.confirmPassword)}
            helperText={
              errors.confirmPassword ? 'Las contraseñas deben coincidir' : ''
            }
            control={control}
            as={
              <TextField
                className={classes.inputClass}
                color="secondary"
                placeholder="Confirme su contraseña"
                type="password"
                variant="outlined"
                autoComplete="off"
              />
            }
            rules={{
              validate: value => value === getValues().password,
            }}
          />

          <label className={classes.labelTitle}>Teléfono</label>
          <Controller
            defaultValue=""
            className={classes.formControl}
            control={control}
            error={Boolean(errors.phone)}
            helperText={errors.phone ? errors.phone.message : ''}
            name="phone"
            as={
              <TextField
                name="phone"
                className={classes.inputClass}
                color="secondary"
                placeholder="Escriba su teléfono"
                variant="outlined"
                autoComplete="off"
              />
            }
            rules={{
              maxLength: {
                value: 10,
                message: 'El teléfono debe contener 10 dígitos',
              },
              minLength: {
                value: 10,
                message: 'El teléfono debe contener 10 dígitos',
              },
              pattern: {
                value: /^[0-9]{0,10}$/,
                message: 'Este campo solo permite números',
              },
            }}
          />
          <Button
            type="submit"
            className={classes.btnSignup}
            color="secondary"
            disabled={!canSignUp() || disabledBtn}
            variant="contained"
          >
            Registrarme
          </Button>
        </form>

        {!isLocactionActive ? (
          <Alert severity="warning">
            Si desea registrarse en el sistema tiene que permitir activar la
            Ubicación para este sitio web
          </Alert>
        ) : (
          ''
        )}
        <h1 className={classes.labelLogin}>
          ¿Ya tienes una cuenta?{' '}
          <Link to="/" variant="body2" className={classes.optionUser}>
            Iniciar sesión
          </Link>
        </h1>
      </Card>

      {openDialog && (
        <Dialog
          onClose={handleClose}
          open={openDialog}
          aria-labelledby="customized-dialog-title"
          severity="error"
          variant="filled"
        >
          <DialogTitle className={classes.header} id="customized-dialog-title">
            <img
              className={classes.img}
              src={!errorServer ? checked : imgErr}
              alt={!errorServer ? 'checked' : 'err'}
            />
          </DialogTitle>
          <DialogContent dividers className={classes.bodyContent}>
            <Typography>
              {!errorServer
                ? 'Su registro se ha completado correctamente.'
                : `El registro no se pudo completar. ${errorServer.message}`}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {isRedirect && <Redirect to="/login" />}
    </React.Fragment>
  );
}
export default catchError(SingUp);
