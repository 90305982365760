import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  root: {
    position: 'absolute',
    bottom: '-108px',
    minWidth: '92%',
    margin: 'auto',
    height: '500px',
    display: 'block',
    textAlign: 'center',
    justifyContent: 'center',
    '& > div:first-child': {
      '&::before': {
        position: 'absolute',
        top: '-60px',
        left: '0',
        color: '#00205C',
        'font-size': '20px',
        'font-family': 'Montserrat',
        'font-weight': 'bold',
      },
    },
    [theme.breakpoints.down(400)]: {
      bottom: '1em',
      height: '55vh',
    },
    [theme.breakpoints.down(700)]: {
      top: '0',
      left: '0',
      right: '0',
    },
    [theme.breakpoints.down(1400)]: {
      top: '100px',
      left: '0',
      right: '0',
    },
    [theme.breakpoints.up(1400)]: {
      top: '240px',
      left: '0',
      right: '0',
    },
  },
  paper: {
    maxWidth: '730px',
    width: '40%',
    margin: 'auto',
    [theme.breakpoints.down(1250)]: {
      width: '90%',
    },
  },
  spacer: {
    width: '100%',
    height: '100px',
  },
  tableTitle: {
    color: '#00205C',
    fontSize: '20px',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    [theme.breakpoints.down(700)]: {
      fontSize: '15px',
      margin: '5% 1% 5% 1%',
    },
  },
  emptyRow: {
    height: '200px',
  },
  tableLabel: {
    color: '#00205C',
    fontSize: '16px',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
  },
  tableSelect: {
    height: 'fit-content',
  },
  head: {
    fontWeight: 'bold',
  },
  card: {
    borderRadius: '10px',
    margin: '3%',
    width: '300px',
    padding: '15px 10px',
    position: 'relative',
    overflow: 'unset',
    marginTop: '10%',
    [theme.breakpoints.down(700)]: {
      'margin-top': '10%',
    },
  },
  button: {
    height: '45.6px',
    backgroundColor: '#00205C',
    border: '4px',
    borderRadius: 5,
    color: '#fff',
    display: 'flex',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 'bold',
    width: '100%',
    [theme.breakpoints.down(430)]: {
      fontSize: 13,
    },
  },
  icon: {
    width: 200,
    height: 200,
    [theme.breakpoints.down(720)]: {
      height: 150,
      width: 150,
    },
    [theme.breakpoints.down(430)]: {
      height: 125,
      width: 125,
    },
  },
  label: {
    color: '#9B9B9B',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    margin: '1rem 0 0 0',
  },
  title: {
    color: '#00205C',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  container: {
    position: 'absolute',
    backgroundColor: '#F0F3F0',
    bottom: '0',
    left: '0',
    right: '0',
  },
  input: {
    margin: '-5px 1.5em 0',
    width: 100,
  },
  btnContainer: {
    marginBottom: 30,
    width: '40%',
    [theme.breakpoints.down(1200)]: {
      marginTop: 10,
      width: '50%',
    },
    [theme.breakpoints.down(700)]: {
      marginTop: -30,
      padding: 10,
      width: '90%',
    },
  },
  alertWarning: {
    width: '40%',
    margin: 'auto',
    marginBottom: '5%',
    backgroundColor: '#FAE871',
    color: '#E29E41',
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
    [theme.breakpoints.down(800)]: {
      width: '60%',
      marginBottom: '10%',
    },
  },
  chipNumberUsers: {
    marginLeft: '2%',
    [theme.breakpoints.down(700)]: {
      marginTop: '2%',
    },
  },
  alertStatusRegistered: {
    padding: 0,
    fontFamily: 'Montserrat',
    fontSize: 16,
    background: 'none',
    [theme.breakpoints.down(400)]: {
      fontSize: 14,
    },
  },
  alertPreviewDose: {
    color: '#ABA090',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    backgroundColor: '#FFF7F2',
    [theme.breakpoints.down(400)]: {
      fontSize: 12,
    },
  },
  backgroundGreen: {
    backgroundColor: '#DFF2D7',
    marginBottom: 3,
  },
  backgroundYellow: {
    backgroundColor: '#F8F3D8',
    marginBottom: 3,
  },
  backgroundRed: {
    backgroundColor: '#EBE1C6',
    marginBottom: 3,
  },
  backgroundBlue: {
    backgroundColor: '#CEE8F5',
    marginBottom: 3,
  },
  sectionHeader: {
    fontSize: 20,
    color: theme.palette.primary.main,
    fontWeight: 700,
    textTransform: 'uppercase',
    textAlign: 'left',
  },
  fontLabel: {
    fontFamily: 'Montserrat',
    textTransform: 'capitalize',
  },
}));
