import { get } from '../utils/fetch';
/**
 * Returns an object with the names and lastname
 * of the person with the given id number.
 * @param {String} idNumber
 * @returns {Object} data with the name and lastname of the person or error message
 */

class Patient {
  async getNamesFromAPI(idNumber) {
    const { name: fullName, cedula, previewDose, ...others } = await get(
      `/vaccination/citizen/${idNumber}`
    ).then(res => res.json());

    return {
      ...others,
      cedula: ((cedula && cedula.toString()) || '').padStart(11, '0'),
      names: fullName,
      lastNames: '-',
      previewDose,
    };
  }

  async getBasicCitizenData(idNumber) {
    const { name, cedula, ...others } = await get(
      `/vaccination/basic-citizen-data/${idNumber}`
    ).then(res => res.json());

    return {
      ...others,
      cedula: ((cedula && cedula.toString()) || '').padStart(11, '0'),
      name,
    };
  }
}

export default new Patient();
