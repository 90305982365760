import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  Card,
  CardContent,
  Chip,
  Paper,
  Select,
  Typography,
  Grid,
  Container,
} from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import pacient from '../../icons/pacient.png';
import symptoms from '../../icons/symptoms.png';
import { post } from '../../utils/fetch';
import AppStorage from '../../helpers/AppStorage';
import AppContext from '../../context/AppContext';
import useStyles from './styled';
import { catchError } from '../../catchErrors/ErrorComponent';
import ListOfRegisteredVaccinate from '../../components/List';

const VACCINATED = 'vaccinated';
const SYMPTOMS = 'symptoms';

function Vaccination() {
  const classes = useStyles();
  const {
    state: {
      userData: { personId },
    },
  } = useContext(AppContext);

  const [validation, setValidation] = useState(false);
  const [users, setUsers] = useState([]);
  const [resumeType, setResumeType] = useState(VACCINATED);
  const { centerId } = AppStorage.get('center', true) || {};
  const nameUserLogin = AppStorage.get('userName') || '';
  const [totalUsers, setTotalUsers] = useState('');

  useEffect(() => {
    if ([VACCINATED, SYMPTOMS].includes(resumeType)) {
      const url =
        resumeType === VACCINATED
          ? '/vaccination/list'
          : '/vaccination/list-symptoms';
      post(url, { centerId, limit: 10, personId })
        .then(res => res.json())
        .then(({ data, total }) => {
          setUsers(data || []);
          setTotalUsers(total);
        });
    }
  }, [resumeType, centerId, personId]);

  const optionsName = [
    {
      title: 'Paciente',
      subtitle: 'Registrar Dosis de Paciente',
      img: pacient,
      textBtn: 'Agregar',
      url: '/vaccination/register',
    },
    {
      title: 'Sintomatología',
      subtitle: 'Reportar efectos secundarios',
      img: symptoms,
      textBtn: `Agregar`,
      url: '/vaccination/symptoms',
    },
  ];

  const OptionButton = ({ param }) => {
    return (
      <div className={classes.root}>
        <Grid
          container
          spacing={0}
          direction="row"
          justify="center"
          alignItems="center"
          style={{
            width: '100%',
            maxWidth: '730px',
            maxHeight: '50px',
          }}
        >
          <Container
            style={{
              padding: '0',
              width: '100%',
              maxWidth: '730px',
              maxHeight: '50px',
              marginLeft: '5%',
              marginRight: '5%',
            }}
          >
            <Typography className={classes.sectionTitle}>Registrar</Typography>
          </Container>
          <Grid
            container
            spacing={0}
            direction="row"
            justify="center"
            alignItems="center"
            style={{
              width: '100%',
              maxWidth: '730px',
              marginLeft: '5%',
              marginRight: '5%',
            }}
          >
            {param.map(({ title, subtitle, img, textBtn, url }, i) => {
              return (
                <Grid
                  item
                  xs={6}
                  style={{ textAlign: '-webkit-center', fontSize: '14px' }}
                  key={i}
                >
                  <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                      <img className={classes.icon} src={img} alt={title} />
                      <Typography className={classes.sectionHeader}>
                        {title}
                      </Typography>
                      <h2 className={classes.label}>{subtitle}</h2>

                      <Button
                        key={i}
                        className={classes.button}
                        color="primary"
                        onClick={() => setValidation(url)}
                        variant="contained"
                      >
                        {textBtn}
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>

          <Paper className={classes.paper}>
            <div className={classes.tableTitleContainer}>
              <h3 className={classes.tableTitle}>
                {`Últimos registros de ${nameUserLogin}`}
              </h3>
              {Boolean(totalUsers) && (
                <Chip className={classes.chipNumberUsers} label={totalUsers} />
              )}
              <Select
                native
                className={classes.tableSelect}
                value={resumeType}
                onChange={ev => setResumeType(ev.target.value)}
              >
                <option value={VACCINATED}>Vacunados</option>
                <option value={SYMPTOMS}>Síntomas</option>
              </Select>
            </div>
            <ListOfRegisteredVaccinate listOfPeople={users} showDate={true} />
          </Paper>
          <div className={classes.spacer} />
        </Grid>
      </div>
    );
  };

  return (
    <div>
      <OptionButton param={optionsName} />
      {validation && <Redirect to={validation} />}
    </div>
  );
}

export default catchError(Vaccination);
