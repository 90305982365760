import React, { useState, useContext } from 'react';
// Google Analytics
import ReactGA from 'react-ga';
import { Snackbar, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Alert } from '@material-ui/lab';

import AppContext from '../../context/AppContext';
import SimpleModal from '../../components/SimpleModal';
import { post } from '../../utils/fetch';
import Symptom from '../../helpers/Symptom';
import PacientInfo from './PacientInfo';
import useStyles from './styled';

import { RESET_STATES } from '../../reducer/actions';
import { catchError } from '../../catchErrors/ErrorComponent';

function VaccinationSymptoms() {
  const classes = useStyles();
  const history = useHistory();
  const { state, dispatch } = useContext(AppContext);
  const {
    symptomsData,
    userData: { centerId = '', personId },
  } = state;
  const [error, setError] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [userData, setUserData] = useState({
    isValid: null,
    firstNames: '',
    documentNumber: '',
  });
  const [disabledBtn, setDisabledBtn] = useState(false);

  const setAttributes = ({ openDialog = true, error = false }) => {
    setError(error);
    setOpenDialog(openDialog);
  };
  const handleSubmit = async () => {
    setDisabledBtn(true);
    const {
      documentNumber,
      firstNames,
      isValid,
      isNameform,
      youngerName,
      youngerId,
      isYounger,
      isOtherIdForm,
    } = userData;

    //Cambiar por SwitchCase
    if (isNameform) {
      if (!documentNumber || !isValid) {
        return setAttributes({
          openDialog: true,
          error:
            'Por favor verifique que la cédula esté correctamente digitada',
        });
      }
    }

    if (isNameform) {
      if (!firstNames) {
        return setAttributes({
          openDialog: true,
          error:
            'Por favor verifique que el nombre esté correctamente digitado',
        });
      }
    }

    if (isYounger) {
      if (!youngerName || !youngerId) {
        return setAttributes({
          openDialog: true,
          error:
            'Por favor verifique que el id y el nombre del menor esté digitado',
        });
      }
    }

    if (isOtherIdForm) {
      if (!documentNumber || !firstNames) {
        return setAttributes({
          openDialog: true,
          error:
            'Por favor verifique que los campos otra identificación y nombre esten correctos',
        });
      }
    }

    const body = {
      documentNumber,
      firstNames: firstNames.toUpperCase(),
      centerId,
      personId,
      youngerName: youngerName?.toUpperCase(),
      youngerId,
      ...Symptom.mapSymptoms(symptomsData),
    };

    await post('/vaccination/add-symptoms', body).then(({ status }) => {
      let errorMsg;
      switch (status) {
        case 201:
          ReactGA.event({
            category: 'vaccineded-symptoms',
            action: 'vaccineded symptoms saved',
          });
          dispatch({ type: RESET_STATES });
          setSuccessOpen(true);
          setTimeout(() => history.push('/vaccination'), 1500);
          break;
        case 400:
          errorMsg = 'El número de cédula introducido es inválido';
          break;
        case 401:
        case 403:
          errorMsg = 'El usuario actual no puede realizar esta acción';
          break;
        default:
          errorMsg = 'Hubo un error en el servidor';
      }

      setAttributes({ error: errorMsg, openDialog: Boolean(errorMsg) });
    });
  };
  const handleClose = () => {
    if (!error) {
      dispatch({ type: RESET_STATES });
      setUserData({});
    }
    setOpenDialog(false);
    setDisabledBtn(false);
  };

  return (
    <React.Fragment>
      <div className={classes.card}>
        <Grid container spacing={4}>
          <Grid item xs={12} style={{ marginTop: '30px' }}>
            <PacientInfo
              onSubmit={handleSubmit}
              setUserData={setUserData}
              userData={userData}
              handleClose={handleClose}
              disabledBtn={disabledBtn}
            />
          </Grid>
        </Grid>
      </div>

      {openDialog && (
        <SimpleModal
          onClose={handleClose}
          isOpen={openDialog}
          body={`La información no pudo ser guardada. ${error}.`}
        />
      )}
      <Snackbar
        autoHideDuration={3000}
        onClose={() => setSuccessOpen(false)}
        open={successOpen}
      >
        <Alert
          onClose={() => setSuccessOpen(false)}
          severity="success"
          variant="filled"
        >
          Registro Guardado
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}
export default catchError(VaccinationSymptoms);
