import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  appBar: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '1rem 2rem',
    position: 'static',
    height: 120,
    boxShadow: 'unset',
    [theme.breakpoints.down(700)]: {
      height: 60,
      padding: '1rem',
    },
  },
  appBarOut: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '1rem 2rem',
    position: 'static',
    background: 'transparent',
    height: 120,
    boxShadow: 'unset',
    [theme.breakpoints.down(450)]: {
      height: 100,
      padding: '1rem',
    },
  },
  appLogo: {
    objectFit: 'contain',
    paddingRight: '6px',
    [theme.breakpoints.up(500)]: {
      paddingRight: '0',
    },
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '20px',
    [theme.breakpoints.down(500)]: {
      marginRight: '0',
      marginLeft: '-17px',
    },
  },
  phoneNumber: {
    fontSize: '1rem',
  },
  link: {
    color: '#fff',
  },
  linkSpan: {
    '& span': {
      'font-weight': '800',
    },
  },
  linkDrawer: {
    color: '#000',
  },
  navigationContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down(500)]: {
      width: '90%',
    },
  },
  headerTitlesContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '9px',
    borderLeft: '1px solid gray',
  },
  headerPrimaryTitle: {
    color: 'white',
    fontSize: '18px',
    margin: 0,
    textTransform: 'uppercase',
    [theme.breakpoints.down(720)]: {
      fontSize: '8px',
    },
  },
  headerSecondaryTitle: {
    color: 'white',
    fontSize: '12px',
    margin: 0,
    textTransform: 'uppercase',
    fontWeight: 300,
    marginTop: '5px',
  },
  menuList: {
    margin: '3% 3%',
  },
  menuMobile: {
    display: 'flex',
  },
  hide: {
    display: 'none',
  },
  drawerPaper: {
    width: 160,
  },
  iconMenu: {
    marginLeft: '100%',
  },
}));
