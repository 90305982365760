import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  sectionHeader: {
    color: theme.palette.primary.main,
    fontFamily: 'Montserrat',
    fontSize: '20px',
    marginTop: theme.spacing(2),
    fontWeight: 'bold',
    textAlign: 'center',
    [theme.breakpoints.down(630)]: {
      fontSize: '25px',
      marginTop: '-160px',
    },
    [theme.breakpoints.down(430)]: {
      fontSize: '30px',
      marginTop: '-6px',
    },
  },
  messageDataEmpty: {
    textAlign: 'center',
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontWeight: 'bold',
    marginTop: '0px',
  },
  sectionBody: {
    color: theme.palette.primary.main,
    // marginTop: theme.spacing(2),
    fontWeight: 700,
    textTransform: 'uppercase',
    textAlign: 'center',
    [theme.breakpoints.down(430)]: {
      marginLeft: 0,
    },
    [theme.breakpoints.down(403)]: {
      fontSize: '16px',
      marginTop: 0,
    },
  },
  root: {
    flexGrow: 1,
    position: 'absolute',
    bottom: '-108px',
    minWidth: '92%',
    margin: 'auto',
    height: '500px',
    display: 'block',
    textAlign: 'center',
    justifyContent: 'center',
    '& > div:first-child': {
      '&::before': {
        position: 'absolute',
        top: '-50px',
        left: '0',
        color: '#00205C',
        'font-size': '20px',
        'font-family': 'Montserrat',
        'font-weight': 'bold',
      },
    },
    [theme.breakpoints.down(1400)]: {
      top: '100px',
      left: '0',
      right: '0',
    },
    [theme.breakpoints.up(1400)]: {
      top: '200px',
      left: '0',
      right: '0',
    },
  },
  card: {
    borderRadius: '10px',
    margin: '3%',
    padding: '30px',
    height: '215px',
    marginTop: '10%',
    [theme.breakpoints.down(700)]: {
      marginTop: '10%',
    },
    [theme.breakpoints.down(402)]: {
      width: '60%',
      margin: 'auto',
      height: '106px',
      marginBottom: '-3%',
      paddingTop: '-11%',
      marginTop: '6%',
    },
  },
  icon: {
    width: 30,
    height: 30,
    [theme.breakpoints.down(720)]: {
      height: 25,
      width: 25,
    },
    [theme.breakpoints.down(430)]: {
      height: 25,
      width: 25,
    },
  },
  btnUpdateData: {
    backgroundColor: '#00205C',
    color: '#FFFFFF',
    '-webkit-text-stroke-width': 'medium',
    padding: '15px',
    '&:hover': {
      color: '#00205C',
      border: 'solid',
    },
  },
}));
