import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => {
  return {
    emptyRow: {
      height: '200px',
    },
    tableLabel: {
      color: '#00205C',
      fontSize: '16px',
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
    },
    head: {
      fontWeight: 'bold',
    },
    btnEdit: {
      backgroundColor: '#00205C',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#194b85',
      },
    },
    filter: {
      marginRight: '2%',
      float: 'right',
      width: '40%',
      [theme.breakpoints.down(700)]: {
        width: '60%',
        float: 'none',
      },
    },
  };
});
