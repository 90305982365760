import {
  Button,
  Card,
  Grid,
  Table,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import AppStorage from '../../helpers/AppStorage';
import { formatDate } from '../../helpers/formatDate';
import { get } from '../../utils/fetch';
import SendReportModal from './SendReportModal';
import useStyles from './styled';
import html2pdf from 'html2pdf.js';

export default function VaccinedTotal() {
  const classes = useStyles();
  const { centerId, name } = AppStorage.get('center', true) || {};
  const [vaccineTotal, setVaccineTotal] = useState({});
  const [vaccineType, setVaccineType] = useState([]);
  const [total, setTotal] = useState(0);

  const getVaccineTypes = async () => {
    await get(`/vaccination/vaccine-types`)
      .then(e => e.json())
      .then(({ data }) => {
        setVaccineType(data);
      });
  };
  useEffect(() => {
    get(`/vaccination/total-vaccined?centerId=${centerId}`)
      .then(e => e.json())
      .then(({ data, total, reportIsCreated }) => {
        setVaccineTotal(data);
        setTotal(total);
        // setReportIsCreated(reportIsCreated);
      });
    (() => getVaccineTypes())();
  }, [centerId]);

  const downloadReport = e => {
    var content = document.querySelector('.tableOuterCont').innerHTML;
    var opt = {
      filename: 'pdf-report.pdf',
      jsPDF: { unit: 'cm', format: 'letter', orientation: 'portrait' },
    };

    html2pdf(content, opt)
      .output()
      .then(data => {
        return;
      });
  };
  const styles = {
    tableOuterCont: {
      width: '100%',
      overflow: 'scroll',
    },
    titlesContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      boxSizing: 'border-box',
      backgroundColor: '#f1f1f1e3',
      width: '98%',
      marginLeft: '1%',
      marginBottom: '3px',
      paddingTop: '10px',
      paddingBottom: '10px',
      paddingLeft: '1%',
    },
    headerLabel: {
      color: '#000000',
      fontSize: '15px',
      fontWeight: '300',
      textAlign: 'center',
      width: '20%',
    },
    headerLabelDose: {
      color: '#000000',
      fontSize: '15px',
      fontWeight: '300',
      textAlign: 'center',
      width: '22%',
    },
    container: {
      width: '98%',
      marginLeft: '1%',
    },
    columns: {
      display: 'inline-block',
      backgroundColor: 'transparent',
      boxSizing: 'border-box',
      width: '21%',
      height: '60px',
      paddingTop: '2px',
      paddingBottom: '65px',
      paddingLeft: '15px',
      color: '#FFFFFF',
    },
    columnLabel: {
      textAlign: 'center',
      fontSize: '14px',
      paddingTop: '5px',
      paddingLeft: '5px',
      width: '90%',
      height: '3.5rem',
      overflowWrap: 'break-word',
    },
    totalColumn: {
      display: 'inline-block',
      backgroundColor: 'transparent',
      width: '40%',
      height: '45px',
      paddingTop: '10px',
      paddingBottom: '15px',
      paddingLeft: '5px',
      color: '#FFFFFF',
      textAlign: 'left',
    },
    rows: {
      backgroundColor: '#00205CE3',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      marginBottom: '1px',
      color: '#FFFFFF',
    },
    rowLabel: {
      display: 'inline',
      width: '18%',
      height: '3.5rem',
      margin: '1px',
      textAlign: 'center',
      paddingTop: '5px',
    },
    totalRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      boxSizing: 'border-box',
      backgroundColor: '#00205CE3',
      width: '98%',
      marginLeft: '1%',
      paddingLeft: '10px',
      color: '#FFFFFF',
      marginTop: '1px',
    },
    totalRowLabel: {
      marginTop: '10px',
    },
    date: {
      fontFamily: 'Montserrat',
      fontSize: '15px',
      fontWeight: '600',
      textAlign: 'right',
      width: '100%',
      float: 'right',
      marginTop: '2%',
      marginRight: '21px',
    },
  };

  return (
    <div className={classes.root}>
      {total === 0 ? (
        <Typography className={classes.messageDataEmpty}>
          No existe registro de vacunados para este centro
        </Typography>
      ) : (
        <>
          <Typography className={classes.messageData}>
            Reporte de cierre de centro de vacunación
          </Typography>
          <Card className={classes.card}>
            <div className="tableOuterCont" style={styles.tableOuterCont}>
              <Table className={classes.table}>
                <TableHead>
                  <Typography className={classes.date} style={styles.date}>
                    {name && name} <br /> {formatDate(Date(), 'reports')}
                  </Typography>
                  <TableRow>
                    {vaccineTotal &&
                      Object.keys(vaccineTotal).map((dose, key) => (
                        <>
                          <Grid style={styles.titlesContainer}>
                            <Typography style={styles.headerLabelDose}>
                              {dose}
                            </Typography>

                            {vaccineType &&
                              vaccineType.map(({ name }, index) => (
                                <Typography key={index} style={styles.headerLabel}>
                                  {name && name === 'AstraZeneca CovidShield'
                                    ? 'AstraZeneca'
                                    : name}
                                </Typography>
                              ))}
                          </Grid>
                          {Object.keys(vaccineTotal[dose]).map((user, index) => {
                            return (
                              <Grid key={index} style={styles.container}>
                                <Grid style={styles.rows}>
                                  <Grid
                                    style={styles.columns}
                                    className={classes.columns}
                                  >
                                    <Typography style={styles.columnLabel}>
                                      {user}
                                    </Typography>
                                  </Grid>

                                  {Object.keys(vaccineTotal[dose][user]).map(
                                    (vacc, index) => {
                                      return (
                                        <Typography key={index} style={styles.rowLabel}>
                                          {vaccineTotal[dose][user][vacc]}
                                        </Typography>
                                      );
                                    }
                                  )}
                                </Grid>
                              </Grid>
                            );
                          })}
                        </>
                      ))}
                  </TableRow>
                </TableHead>
                {total !== 0 && (
                  <>
                    <Grid style={styles.totalRow}>
                      <Grid style={styles.totalColumn}>
                        <Typography>Total general</Typography>
                      </Grid>
                      <Typography style={styles.totalRowLabel}>
                        {total}
                      </Typography>
                    </Grid>
                  </>
                )}
              </Table>
            </div>
            <div className={classes.btnsContainer}>
              <Button
                className={classes.actionBtn}
                size="small"
                onClick={(e) => downloadReport(e)}
              >
                Descargar reporte
              </Button>
            </div>
          </Card>
        </>
      )}
    </div>
  );
}
