export default function isYoung(date, numDose) {
  const dateNow = new Date();
  const dateOfBirth = new Date(date);

  const yearsSince = (dateOfBirth, now) => {
    now.setHours(0, 0, 0);
    const years = now.getFullYear() - dateOfBirth.getFullYear();
    return now.getMonth() + 1 >= dateOfBirth.getMonth() + 1 &&
      now.getDate() >= dateOfBirth.getDate() &&
      now.getMonth() + 1 >= dateOfBirth.getMonth() + 1
      ? years
      : years - 1;
  };

  const age = yearsSince(dateOfBirth, dateNow);

  const willTurn5ThisYear =
    (age === 5 &&
      dateOfBirth.getMonth() + 1 === dateNow.getMonth() + 1 &&
      dateOfBirth.getDate() <= dateNow.getDate()) ||
    (age === 5 && dateOfBirth.getMonth() + 1 <= dateNow.getMonth() + 1);

  if ((willTurn5ThisYear || age >= 5) && age <= 11 && numDose > 2) {
    return 1;
  }

  if ((willTurn5ThisYear || age >= 5) && age < 18) {
    return true;
  } else {
    return 2;
  }
}
