import React from 'react';

export const catchError = Component => {
  class ErrorComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false, message: '' };
    }

    componentDidCatch(error) {
      return this.setState({
        hasError: true,
        message: `${error.message}`,
      });
    }

    render() {
      if (this.state.hasError) {
        return <h1>{this.state.message}</h1>;
      }
      return <Component {...this.props} />;
    }
  }
  return ErrorComponent;
};
