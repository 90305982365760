import React, { useContext, useEffect, useReducer, useState } from 'react';
// Google Analytics
import ReactGA from 'react-ga';
import {
  Card,
  TextField,
  Button,
  Typography,
  Select,
  MenuItem,
} from '@material-ui/core';
import { Alert, ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import { Room } from '@material-ui/icons';
import { FixedSizeList } from 'react-window';
import AppContext from '../../context/AppContext';
import icon from '../../../src/icons/Vacunaterd@2x.svg';
import { get, post } from '../../utils/fetch';
import { USER_LOGIN } from '../../reducer/actions';
import reducer from '../../utils/reducer';
import useStyles from './styled';
import AppStorage from '../../helpers/AppStorage';
import MuiDialog from '../../components/MuiDialog';
import User from '../../helpers/User';
import { useLocation } from '../../context/LocationContext';
import { ErrorOutline } from '@material-ui/icons';
import { catchError } from '../../catchErrors/ErrorComponent';

import { VACCINATION, DEFAULT_RNC } from '../../const';
import ModalResetPassword from '../../components/ModalResetPassword';
import { Controller, useForm } from 'react-hook-form';
import useLocationHooks from '../../hooks/useLocation';
import { ListCenters } from './ListCenters/index';
import InputMaskComponent from '../../components/InputMask';

const REDIRECT_BYT_ROLE = {
  [VACCINATION]: '/vaccination/',
};

function Login({ history }) {
  if (User.loggedIn()) {
    history.push('/vaccination');
  }
  const classes = useStyles();
  const { handleSubmit, control, errors } = useForm();
  const [state, setState] = useReducer(reducer, {
    signInError: false,
    target: VACCINATION,
    errorData: {},
    userData: {},
  });

  const { dispatch } = useContext(AppContext);
  const { userData, errorData, target, signInError } = state;
  const { idNumber } = userData;

  const [loadingProvinces, setLoadingProvinces] = useState(false);
  const [provinces, setProvinces] = useState([]);
  const [vaccineTowns, setVaccineTowns] = useState([]);
  const [vaccineCenters, setVaccineCenters] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState('');
  const [selectedTown, setSelectedTown] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  // eslint-disable-next-line
  const [selectedCenter, setSelectedCenter] = useState('');
  const [status, setStatus] = useState();
  const [showUpdateBtn, setShowUpdateBtn] = useState(false);
  const [openModalResetPassword, setOpenModalResetPassword] = useState(false);
  const [hasToUpdatePass, setHasToUpdatePass] = useState(false);
  const [alignment, setAlignment] = useState('stationary');
  const [selected, setSelected] = useState(false);
  const { latitude, longitude } = useLocationHooks();
  const { isLocactionActive } = useLocation();

  useEffect(() => {
    setState({
      userData: { ...userData, centerId: selectedCenter },
    });
    // eslint-disable-next-line
  }, [selectedCenter]);

  const handleChange = ({ target: { name, value } }, pattern) => {
    if (name === 'idNumber') {
      value = value.replace(/\D/g, '');
      if (value.length === 11) {
        User.isValidPerson(value)
          .then(res => res.json())
          .then(({ valid }) => {
            setStatus(valid);
            if (!valid) {
              setState({
                signInError:
                  'Su usuario no se encuentra activo, por favor contacte al administrador',
              });
            } else {
              setState({ signInError: false });
              const body = Object.assign(
                {},
                {
                  idNumber: value,
                  password: null,
                }
              );
              /* 
              After a bug found and futher investigation, we supposed this following post request is not exactly meant to do the action  
              it reffers by its name, with this I mean, the action of setting the password. It seems a validation to know when a super admin
              wants a specific user to change their password, and that's why this request is send after the user is verified as 
              actived
              */
              post('/auth/setPassword', body)
                .then(response => {
                  switch (response.status) {
                    case 200:
                      setShowUpdateBtn(true);
                      setState({
                        signInError:
                          'El administrador ha solicitado un cambio de contraseña para su usuario',
                      });
                      return;
                    default:
                      setState({
                        signInError: '',
                      });
                  }
                })
                .catch(err => {
                  setState({ signInError: err.message });
                });
            }
          });
      } else {
        setStatus(false);
      }
    }

    if (!value.match(pattern)) {
      setState({
        userData: {
          ...userData,
          [name]: value.slice(0, value.length - 1),
        },
      });
      setState({ errorData: { ...errorData, [name]: false } });
    } else {
      setState({ userData: { ...userData, [name]: value } });
      setState({ errorData: { ...errorData, [name]: false } });
    }
  };

  const login = async e => {
    let { rnc, idNumber, password, centerId } = userData;
    let bodyQuery = { password, target };

    idNumber = idNumber.replace(/\D/g, '');
    rnc = (rnc || DEFAULT_RNC).replace(/\D/g, '');

    if (
      (/^([a-zA-Z0-9]{8,15})$/.test(password) && /^([0-9]{9})*$/.test(rnc)) ||
      /^[0-9]{11}$/.test(idNumber)
    ) {
      bodyQuery = Object.assign({}, bodyQuery, userData, {
        rnc,
      });
      await post('/auth/login', bodyQuery)
        .then(response => {
          switch (response.status) {
            case 200:
              ReactGA.event({
                category: 'user-loggin',
                action: 'user loggin',
              });
              return response.json();
            case 400:
              throw Error('Falta de datos');
            case 401:
              ReactGA.event({
                category: 'Intento fallido',
                action: `El Usuario con cédula: ${userData.idNumber} ha fallado al intentar loguearse`,
              });
              throw Error('Credenciales inválidas');
            case 404:
              throw Error('Cédula no registrada');
            default:
              throw Error('Error en el servidor');
          }
        })
        .then(({ login, ...payload }) => {
          const { companyId, personId, Company, firstName } = payload.data;
          const currentCenter = vaccineCenters.find(
            center => center.id === centerId
          );
          AppStorage.set(
            'center',
            {
              centerId,
              name: currentCenter.name,
            },
            true
          );
          AppStorage.set('auth_token', payload.token);
          AppStorage.set('userName', firstName);
          if (!login) {
            setState({ signInError: true });
            return;
          } else {
            dispatch({
              type: USER_LOGIN,
              payload: {
                companyId,
                firstName,
                companyName: Company.name,
                personId,
                rnc: userData.rnc,
                role: target,
                centerId,
              },
            });

            setOpenDialog(true);
            setTimeout(() => history.push(REDIRECT_BYT_ROLE[target]), 1000);
          }
        })
        .catch(err => {
          setState({ signInError: err.message });
        });
    } else {
      setState({
        signInError: 'Cédula incorrecta  o contraseña incorrecta.',
      });
    }
  };

  const canLogin = () => {
    const { idNumber, password, centerId } = userData;
    return idNumber && password && centerId && status;
  };

  const onChangeProvince = e => {
    setSelectedProvince(e.target.value);
    setVaccineTowns([]);
    setVaccineCenters([]);
    setSelectedTown('');
    setSelectedCenter('');

    get(`/provinces/${e.target.value}/towns`)
      .then(res => res.json())
      .then(r => {
        setVaccineTowns(r);
      })
      .catch(() => {});
  };

  const onChangeTown = e => {
    setSelectedTown(e.target.value);
    setSelectedCenter('');
    setVaccineCenters([]);

    get(`/provinces/towns/centers/${e.target.value}`)
      .then(res => {
        if (res.status === 200) return res.json();
        else return [];
      })
      .then(r => {
        setVaccineCenters(r);
      })
      .catch(() => {});
  };

  useEffect(() => {
    const fetchProvinces = async () => {
      await get('/provinces')
        .then(res => res.json())
        .then(r => {
          setProvinces(r);
        })
        .finally(() => {
          setLoadingProvinces(false);
        });
    };
    if (provinces && !provinces.length) fetchProvinces();
    // eslint-disable-next-line
  }, []);

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
      getNearCenters(newAlignment === 'mobiles');
    }
  };

  const getNearCenters = (isCenterMobile = false) => {
    setSelectedCenter('');
    setState({ userData: { ...userData, centerId: '' } });
    get(
      `/provinces/towns/near-centers?latitude=${latitude}&longitude=${longitude}&center_is_mobile=${isCenterMobile}`
    )
      .then(res => res.json())
      .then(results => {
        setVaccineCenters(results);
      });
  };

  const openListCenters = () => {
    setSelected(!selected);
    getNearCenters(alignment === 'mobiles');
  };

  const showCheckMarkIcon = status === true;
  const showWrongIcon = status === false && userData.idNumber !== '';

  useEffect(() => {
    setHasToUpdatePass(idNumber && status && showUpdateBtn);
  }, [idNumber, status, showUpdateBtn]);

  const getListCenters = () => (
    <ListCenters
      nearCenters={vaccineCenters || []}
      handleListItemClick={index => setSelectedCenter(index)}
      selectedIndex={selectedCenter}
    />
  );

  return (
    <div>
      <Card className={classes.container}>
        {navigator.permissions ? (
          <>
            <div className={classes.title}>
              <img
                alt="vaccine logo"
                className={classes.logoImage}
                src={icon}
              />
              <Typography
                variant="h4"
                gutterBottom
                className={classes.titleLogin}
              >
                Acceder
              </Typography>
            </div>
            <form onSubmit={handleSubmit(login)} className={classes.textFields}>
              <label
                className={classes.labelTitleSelect}
                style={{ marginBottom: '0.5em' }}
              >
                Tipo de centro
              </label>
              <ToggleButtonGroup
                value={alignment}
                exclusive
                size="small"
                className={classes.toggleButtonGroup}
                onChange={handleAlignment}
                aria-label="text alignment"
              >
                <ToggleButton
                  value="stationary"
                  classes={{
                    root: classes.labelToggle,
                    selected: classes.toggleSelected,
                  }}
                >
                  estacionarios
                </ToggleButton>
                <ToggleButton
                  value="mobiles"
                  classes={{
                    root: classes.labelToggle,
                    selected: classes.toggleSelected,
                  }}
                >
                  móviles
                </ToggleButton>
              </ToggleButtonGroup>
              <ToggleButton
                disabled={!isLocactionActive}
                value="nearbyCenters"
                style={{ backgroundColor: '#D0E6F3' }}
                selected={selected}
                classes={{
                  root: classes.labelBtn,
                  selected: classes.toggleSelected,
                }}
                onClick={openListCenters}
              >
                <Room fontSize="small" />
                Centros cercanos
              </ToggleButton>
              {!selected ? (
                <>
                  <label className={classes.labelTitleSelect}>Provincia</label>
                  <Controller
                    className={classes.formControl}
                    control={control}
                    defaultValue=""
                    name="centerId"
                    render={() => {
                      return (
                        <Select
                          fullWidth
                          variant="outlined"
                          className={classes.selectInputClass}
                          color="secondary"
                          id="outlined-text-input-provincias"
                          type="text"
                          value={selectedProvince}
                          disabled={loadingProvinces}
                          onChange={onChangeProvince}
                          name="centerId"
                          displayEmpty
                        >
                          <MenuItem disabled value="">
                            Seleccione su provincia
                          </MenuItem>
                          {Array.isArray(provinces) &&
                            provinces.map(({ id, name }) => (
                              <MenuItem key={id} value={id}>
                                {name}
                              </MenuItem>
                            ))}
                        </Select>
                      );
                    }}
                  />
                  <label className={classes.labelTitleSelect}>Municipio</label>
                  <Controller
                    className={classes.formControl}
                    control={control}
                    defaultValue=""
                    name="centerId"
                    render={() => {
                      return (
                        <Select
                          className={classes.selectInputClass}
                          color="secondary"
                          id="outlined-text-input-Centros"
                          name="centerId"
                          type="text"
                          value={selectedTown || ''}
                          onChange={e => onChangeTown(e)}
                          variant="outlined"
                          displayEmpty
                          disabled={!vaccineTowns.length}
                        >
                          <MenuItem disabled value="">
                            Seleccione su municipio
                          </MenuItem>
                          {Array.isArray(vaccineTowns) &&
                            vaccineTowns.map(({ id, name }) => (
                              <MenuItem key={id} value={id}>
                                {name}
                              </MenuItem>
                            ))}
                        </Select>
                      );
                    }}
                  />
                  <label className={classes.labelTitleSelect}>
                    Centro de vacunación
                  </label>
                  <Controller
                    className={classes.formControl}
                    control={control}
                    defaultValue=""
                    name="centerId"
                    render={() => {
                      return (
                        <Select
                          className={classes.selectInputClass}
                          color="secondary"
                          id="outlined-text-input-Centros"
                          type="text"
                          value={selectedCenter || ''}
                          onChange={({ target: { value } }) =>
                            setSelectedCenter(value)
                          }
                          variant="outlined"
                          displayEmpty
                          name="centerId"
                          disabled={!vaccineCenters.length}
                        >
                          <MenuItem disabled value="">
                            Seleccione el centro de vacunación
                          </MenuItem>
                          {Array.isArray(vaccineCenters) &&
                            vaccineCenters.map(({ id, name }) => (
                              <MenuItem key={id} value={id}>
                                {name}
                              </MenuItem>
                            ))}
                        </Select>
                      );
                    }}
                  />
                </>
              ) : (
                <>
                  <label className={classes.labelTitle}>
                    Cantidad de centros encontrados: {vaccineCenters.length}
                  </label>
                  <FixedSizeList
                    height={250}
                    itemSize={2}
                    itemCount={1}
                    style={{
                      marginBottom: '1em',
                      backgroundColor: '#F5F5F58F',
                    }}
                  >
                    {getListCenters}
                  </FixedSizeList>
                </>
              )}
              <label className={classes.labelTitle}>Cédula</label>
              <Controller
                control={control}
                defaultValue=""
                name="idNumber"
                render={() => {
                  return (
                    <InputMaskComponent
                      onChange={e => handleChange(e)}
                      name="idNumber"
                      label="Escriba su cédula"
                      mask="999-9999999-9"
                      value={userData.idNumber}
                      showWrongIcon={showWrongIcon}
                      showCheckMarkIcon={showCheckMarkIcon}
                    />
                  );
                }}
              />
              {!hasToUpdatePass && (
                <>
                  <label className={classes.labelTitle}>Contraseña</label>
                  <Controller
                    className={classes.formControl}
                    control={control}
                    defaultValue=""
                    name="password"
                    render={() => {
                      return (
                        <TextField
                          error={errors.password}
                          helperText={
                            errorData.password ? 'Solo letras y números' : ''
                          }
                          onChange={e => handleChange(e, /^[0-9a-zA-Z]+$/)}
                          id="outlined-password-input"
                          className={classes.inputClass}
                          color="secondary"
                          placeholder="Escriba su contraseña"
                          type="password"
                          name="password"
                          variant="outlined"
                          autoComplete="off"
                        />
                      );
                    }}
                  />
                </>
              )}
              {signInError && (
                <h1 className={classes.labelMessage}>{signInError}</h1>
              )}

              <Typography
                className={classes.labelRegistration}
                variant="h6"
                gutterBottom
              >
                {!showUpdateBtn ? (
                  <Button
                    disabled={!canLogin()}
                    type="submit"
                    className={classes.btnLogin}
                    variant="contained"
                    color="primary"
                  >
                    Acceder
                  </Button>
                ) : (
                  <Button
                    disabled={!hasToUpdatePass}
                    className={classes.btnLogin}
                    variant="contained"
                    color="primary"
                    onClick={() => setOpenModalResetPassword(true)}
                  >
                    Actualizar
                  </Button>
                )}
                {!isLocactionActive ? (
                  <Alert severity="warning" className="mb-20">
                    Si desea acceder al sistema tiene que permitir activar la
                    Ubicación para este sitio web
                  </Alert>
                ) : (
                  ''
                )}
                ¿Aún no tienes una cuenta?{' '}
                <Link
                  to="/signup"
                  variant="body2"
                  className={classes.optionUser}
                >
                  Regístrate
                </Link>
              </Typography>
            </form>
            <ModalResetPassword
              open={openModalResetPassword}
              onClose={() => setOpenModalResetPassword(false)}
              idNumber={idNumber}
              setHasToUpdatePass={setHasToUpdatePass}
              setState={setState}
              setShowUpdateBtn={setShowUpdateBtn}
            />
          </>
        ) : (
          <>
            <ErrorOutline
              color="secondary"
              fontSize="large"
              className={classes.iconErr}
            />
            <Typography>
              Tu navegador no es compatible con esta aplicación, por favor
              cambia a Google Chrome
            </Typography>
          </>
        )}
      </Card>
      {canLogin() && (
        <MuiDialog
          onClose={login}
          onOpen={openDialog}
          body={{
            success: 'Has iniciado sesión',
          }}
        />
      )}
    </div>
  );
}
export default catchError(Login);
