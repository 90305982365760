import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const InternetNotification = ({ status }) => {
  if (status) return '';

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={true}
    >
      <Alert variant="filled" severity="error">
        No tiene conexión a internet!
      </Alert>
    </Snackbar>
  );
};

InternetNotification.propTypes = {
  status: PropTypes.bool,
};

export default InternetNotification;
