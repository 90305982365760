import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Dialog, DialogContent } from '@material-ui/core';
import MuiDialogTitle from '../MuiDialogTitle';
import imgWarning from '../../icons/warning-app.webp';
import useStyles from './styled';

export default function MuiDialog({ onOpen, onClose, body }) {
  const classes = useStyles();
  return (
    <Dialog aria-labelledby="customized-dialog-title" open={onOpen}>
      <MuiDialogTitle
        className={classes.header}
        id="customized-dialog-title"
        onClose={onClose}
      >
        <img
          className={classes.img}
          style={{ paddingBottom: '2%' }}
          src={imgWarning}
          alt="err"
        />
      </MuiDialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>{body.message}</Typography>
      </DialogContent>
    </Dialog>
  );
}

MuiDialog.propTypes = {
  onOpen: PropTypes.bool,
  onClose: PropTypes.bool,
  body: PropTypes.object,
};

MuiDialog.defaultProps = {
  body: {},
};
