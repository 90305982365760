import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import AppContext from '../context/AppContext';

export default function PrivateRoute({ component: Component, ...rest }) {
  const { state } = useContext(AppContext);
  return (
    <Route
      {...rest}
      render={props =>
        (state.isUserLoggedIn && <Component {...props} />) || (
          <Redirect to="/login" />
        )
      }
    />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  path: PropTypes.string.isRequired,
};
