class Symptom {
  constructor() {
    this.SYMPTOMS = {
      Fiebre: 'fever',
      Vomito: 'vomit',
      Diarrea: 'diarrhea',
      Escalofríos: 'shivers',
      Cansancio: 'fatigue',
      'Dolor de cabeza': 'headache',
      Nausea: 'nausea',
      Mareo: 'dizziness',
      'Inflamación en el area de la inyección': 'swellingInjection',
      Otros: 'other',
    };
  }
  getInitSymptomps(init) {
    return Object.keys(this.SYMPTOMS).reduce((acc, symptom) => {
      acc[symptom] = init || false;
      return acc;
    }, {});
  }

  mapSymptoms({ noSymptoms, symptoms }) {
    return Object.keys(this.SYMPTOMS).reduce((acc, symptom) => {
      acc[this.SYMPTOMS[symptom]] = noSymptoms ? false : symptoms[symptom];
      return acc;
    }, {});
  }
}

export default new Symptom();
