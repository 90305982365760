class AppStorage {
  storageToken(token) {
    localStorage.setItem('auth_token', token);
  }

  store(token) {
    this.storageToken(token);
  }

  clear() {
    localStorage.removeItem('auth_token');
  }

  getToken() {
    return localStorage.getItem('auth_token');
  }

  get(propName, isObject) {
    const savedItem = localStorage.getItem(propName);

    if (savedItem) {
      return isObject ? JSON.parse(savedItem) : savedItem;
    }

    return '';
  }

  set(propName, value, isObject) {
    if (isObject) {
      return localStorage.setItem(propName, JSON.stringify(value));
    }
    return localStorage.setItem(propName, value);
  }

  remove(propName) {
    localStorage.removeItem(propName);
  }
}

export default new AppStorage();
