import React, {
  useMemo,
  useState,
  useEffect,
  createContext,
  useContext,
} from 'react';

export const LocationContext = createContext();

export function LocationProvider(props) {
  const [isLocactionActive, setIsLocationActive] = useState(false);

  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };
  const success = pos => {
    setIsLocationActive(true);
  };
  const errors = err => {
    setIsLocationActive(false);
  };
  useEffect(() => {
    if (navigator.geolocation && navigator.permissions) {
      navigator.permissions
        .query({ name: 'geolocation' })
        .then(function (result) {
          if (result.state === 'granted') {
            navigator.geolocation.getCurrentPosition(success);
          } else if (result.state === 'prompt') {
            setIsLocationActive(false);
            navigator.geolocation.getCurrentPosition(success, errors, options);
          } else if (result.state === 'denied') {
            setIsLocationActive(false);
          }
          result.onchange = function () {
            if (result.state === 'granted') {
              setIsLocationActive(true);
            } else setIsLocationActive(false);
          };
        });
    } else {
      setIsLocationActive(false);
    }
  }, [isLocactionActive, options]);

  const value = useMemo(() => {
    return {
      isLocactionActive,
    };
  }, [isLocactionActive]);

  return <LocationContext.Provider value={value} {...props} />;
}

export function useLocation() {
  const context = useContext(LocationContext);
  if (!context) {
    throw new Error(
      'useLocation debe estar dentro del proveedor LocationContext'
    );
  }
  return context;
}
