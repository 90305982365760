import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  fab: {
    position: 'fixed',
    bottom: 20,
    right: 20,
    [theme.breakpoints.down(430)]: {
      left: 20,
    },
  },
}));
