import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  sectionHeader: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
    fontWeight: 700,
    textTransform: 'uppercase',
    textAlign: 'left',
    marginLeft: 20,
    [theme.breakpoints.down(500)]: {
      fontSize: 18,
      marginLeft: 13,
    },
    [theme.breakpoints.down(390)]: {
      fontSize: 16,
      marginLeft: 15,
    },
  },
  btnContainer: {
    width: '92%',
    marginLeft: 22,
    marginTop: 10,
    [theme.breakpoints.down(540)]: {
      padding: 10,
      width: '90%',
    },
    [theme.breakpoints.down(430)]: {
      padding: 3,
      width: '89%',
    },
    [theme.breakpoints.down(380)]: {
      padding: 3,
      width: '87.5%',
    },
    [theme.breakpoints.down(325)]: {
      padding: 5,
      width: '85%',
    },
  },
  cardForCamera: {
    borderRadius: 15,
    display: 'block',
    marginTop: '5%',
    padding: '1% 0% 10%',
    textAlign: 'center',
    [theme.breakpoints.down(430)]: {
      minWidth: 225,
      Width: '100%',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    marginTop: 20,
    minWidth: 120,
    width: '82%',
  },
  formControlLabel: {
    left: '15px',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  alertWarning: {
    backgroundColor: '#FAE871',
    color: '#E29E41',
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
  },
  labelTitleSelect: {
    textAlign: 'left',
    marginTop: '0.3em',
    fontFamily: 'Montserrat',
  },
  labelTitle: { textAlign: 'left', marginBottom: '0.5em', marginTop: 20 },
  placeHolderSelect: {
    textAlign: 'left',
  },
  selectInputClass: {
    color: '#9B9B9B',
    marginBottom: 20,
    marginTop: '2.5%',
    textAlign: 'left',
  },
  subLabel: {
    paddingLeft: '5%',
    textAlign: 'left',
    [theme.breakpoints.down(430)]: {
      fontSize: 14,
    },
  },
  toggleButton: {
    width: '80%',
    marginTop: 15,
    float: 'left',
    [theme.breakpoints.down(400)]: {
      width: '45%',
    },
  },
  toggleButtonTop: { marginTop: 15, width: '100%' },
  header: {
    paddingTop: '2em',
    textAlign: 'center',
    [theme.breakpoints.down(400)]: {
      paddingBottom: '0',
    },
    '& .MuiTypography-h6': {
      fontWeight: 'bold',
    },
  },
}));
