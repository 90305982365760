import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#00205C' },
    secondary: { main: '#3d6d8c' },
    toggleButtonBackground: { main: '#194b85' },
    toggleButtonColor: { main: '#fff' },
  },
  typography: { fontFamily: 'Montserrat' },
  h1: { fontFamily: 'Montserrat' },
  h2: { fontFamily: 'Montserrat' },
  h3: { fontFamily: 'Montserrat' },
  h4: { fontFamily: 'Montserrat' },
  h5: { fontFamily: 'Montserrat' },
  h6: { fontFamily: 'Montserrat' },
});

theme.overrides.MuiToggleButton = {
  // Override the styling for selected toggle buttons
  root: {
    '&$selected': {
      backgroundColor: theme.palette.toggleButtonBackground.main,
      color: theme.palette.toggleButtonColor.main,
    },
  },
};

export default theme;
