import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  btnLogin: {
    backgroundColor: '#00205C',
    borderRadius: '5px',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 'bold',
    height: '45.6px',
    margin: '4% auto 10%',
    maxHeight: 50,
    textDecoration: 'none',
    width: '100%',
    [theme.breakpoints.down(400)]: {
      height: 50,
    },
  },
  container: {
    boxShadow: '1px 1px 8px hsla(0, 0%, 40%, 0.3)',
    display: 'flex',
    flexWrap: 'Wrap',
    margin: '80px auto 45vh',
    maxWidth: 400,
    minWidth: 50,
    padding: 30,
    backgroundSize: 'cover',
    borderRadius: 10,
    textAlign: 'center',
    [theme.breakpoints.down(400)]: {
      maxWidth: 'auto',
    },
  },
  input: {
    color: '#9B9B9B',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    width: '100%',
  },
  labelMessage: {
    color: '#D52904',
    fontSize: 15,
    fontWeight: 500,
    fontFamily: 'Montserrat',
    textAlign: 'center',
  },
  logoImage: {
    height: '80px',
    margin: '10% 0 10%',
  },
  labelRegistration: {
    fontSize: 14,
    fontWeight: 600,
    margin: 'auto',
    marginBottom: 10,
    marginTop: 20,
    textAlign: 'center',
  },
  labelTitleSelect: {
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'left',
    marginTop: 20,
  },
  labelTitle: {
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'left',
    marginBottom: '0.5em',
    marginTop: 20,
  },
  labelToggle: {
    width: '100%',
    fontSize: 12,
    fontWeight: 600,
    margin: 'auto',
    marginBottom: '1em',
    borderColor: '#3E76A2',
    borderStyle: 'solid',
    [theme.breakpoints.down(340)]: {
      fontSize: 10,
    },
  },
  labelBtn: {
    height: '3.5em',
    fontSize: 12,
    fontWeight: 600,
    margin: 'auto',
    marginBottom: '1em',
    color: '#1D3E6B',
    borderStyle: 'solid',
    [theme.breakpoints.down(340)]: {
      fontSize: 10,
      padding: '1em',
    },
  },
  toggleSelected: {
    backgroundColor: '#386EB7 !important',
  },
  listCenters: {
    backgroundColor: '#94CDFF59 !important',
  },
  titleLogin: {
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontWeight: 'bold',
    letterSpacing: '0.11px',
    lineHeight: '24px',
    marginBottom: '25%',
    textAlign: 'center',
    width: '132px',
  },
  selectInputClass: {
    color: '#9B9B9B',
    marginTop: '2.5%',
    textAlign: 'left',
  },
  inputClass: {
    marginBottom: 20,
    marginTop: '2.5%',
  },
  textFields: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  title: {
    margin: 'auto',
  },
  optionUser: {
    color: '#048EF7',
  },
  iconErr: {
    margin: 'auto',
    paddingBottom: '10px',
  },
  toggleButtonGroup: {
    width: '100%',
    margin: 'auto',
    marginBottom: '0.2em',
    height: '3em',
  },
}));
