import {
  Dialog,
  DialogTitle,
  Typography,
  Button,
  DialogActions,
  IconButton,
} from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useStyles from './styled';
import { ReactMultiEmail } from 'react-multi-email';
import CloseIcon from '@material-ui/icons/Close';
import 'react-multi-email/style.css';
import { post } from '../../../utils/fetch';
import html2pdf from 'html2pdf.js';
import AppStorage from '../../../helpers/AppStorage';

export default function SendReportModal({ open, close }) {
  const classes = useStyles();
  const [wantToContinue, setWantToContinue] = useState(false);
  const [emails, setEmails] = useState([]);
  const [errors, setErrors] = useState('');
  const { name } = AppStorage.get('center', true) || {};

  const reloadPage = () => {
    window.location.reload();
  };

  const sendReport = e => {
    var content = document.querySelector('.tableOuterCont').innerHTML;
    var opt = {
      filename: 'pdf-report.pdf',
      jsPDF: { unit: 'cm', format: 'letter', orientation: 'portrait' },
    };

    html2pdf(content, opt)
      .output()
      .then(data => {
        close(false);
        setWantToContinue(false);
        setEmails([]);
      });
  };

  const validateGmail = e => {
    e.preventDefault();

    if (emails.length === 0) {
      setWantToContinue(false);
      setErrors('Debe tener al menos un correo');
    } else {
      setWantToContinue(true);
    }
  };

  if (wantToContinue) {
    return (
      <Dialog fullWidth aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle className={classes.header} id="customized-dialog-title">
          <Typography className={classes.modalTitle}>
            ¿Estás seguro que deseas enviar el reporte?
          </Typography>
        </DialogTitle>
        <DialogActions>
          <Button
            className={classes.sendBtn}
            autoFocus
            onClick={sendReport}
            color="primary"
          >
            Sí, enviar
          </Button>
          <Button autoFocus onClick={reloadPage} color="primary">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog fullWidth aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">
        Enviar reporte por correo
      </DialogTitle>
      <form style={{ padding: '0em 2em 2em 2em' }}>
        <label>Emails</label>
        <ReactMultiEmail
          style={{ marginTop: '10px' }}
          emails={emails}
          onChange={_emails => {
            setEmails(_emails);
          }}
          getLabel={(email, index, removeEmail) => {
            return (
              <>
                <label className={classes.email} key={index}>
                  {email}
                  <IconButton
                    size="small"
                    aria-label="close"
                    onClick={() => removeEmail(index)}
                  >
                    <CloseIcon style={{ color: 'gray' }} />
                  </IconButton>
                </label>
              </>
            );
          }}
        />

        {!wantToContinue && <p className={classes.emptyInput}>{errors}</p>}

        <Button
          variant="contained"
          className={classes.btnClose}
          onClick={reloadPage}
        >
          Cancelar
        </Button>
        <Button
          type="submit"
          variant="contained"
          onClick={validateGmail}
          color="primary"
          className={classes.btnSend}
        >
          Continuar
        </Button>
      </form>
    </Dialog>
  );
}

SendReportModal.prototype = {
  open: PropTypes.bool,
};

SendReportModal.defaultProps = {
  open: false,
};
