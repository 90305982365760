import {
  Dialog,
  DialogTitle,
  TextField,
  FormControl,
  Button,
} from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { post } from '../../utils/fetch';
import MuiDialog from '../MuiDialog';
import useStyles from './styled';

export default function ModalResetPassword({
  onClose,
  open,
  idNumber,
  setHasToUpdatePass,
  setState,
  setShowUpdateBtn,
}) {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);

  const { register, errors, getValues, setValue } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const restrictPattern = (name, pattern) => {
    const currentValue = getValues(name);
    if (!currentValue.match(pattern)) {
      setValue(name, currentValue.slice(0, currentValue.length - 1));
    }
  };
  const handleResetPassword = () => {
    const { password } = getValues();
    const body = Object.assign(
      {},
      {
        idNumber,
        password,
      }
    );
    return post('/auth/setPassword', body).then(response => {
      if (response.status === 200) {
        setOpenDialog(true);
        setTimeout(() => {
          setOpenDialog(false);
          onClose();
          setHasToUpdatePass(false);
          setState({ signInError: false });
          setShowUpdateBtn(false);
        }, 1500);
      }
    });
  };

  const canResetPassword = () => {
    const { confirmPassword, password } = getValues();
    if (confirmPassword && password) {
      return !Object.keys(errors).some(key => !!errors[key] === true);
    }
    return false;
  };

  return (
    <Dialog
      onClose={onClose}
      fullWidth
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">Cambio de contraseña</DialogTitle>
      <form onSubmit={handleResetPassword} className={classes.textFields}>
        <FormControl className={classes.formControl}>
          <label className={classes.labelTitle}>Nueva contraseña</label>
          <TextField
            className={classes.inputClass}
            color="secondary"
            error={Boolean(errors.password)}
            helperText={errors.password ? errors.password.message : ''}
            inputRef={register({
              required: true,
              minLength: {
                value: 8,
                message: 'Por favor colocar 8 caracteres o más',
              },
              pattern: {
                value: /^[0-9a-zA-Z]+$/,
                message: 'Solo letras y números',
              },
            })}
            placeholder="Escriba su nueva contraseña"
            name="password"
            type="password"
            variant="outlined"
            onChange={() => restrictPattern('password', /^[0-9a-zA-Z]+$/)}
            autoComplete="off"
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <label className={classes.labelTitle}>Confirme contraseña</label>
          <TextField
            className={classes.inputClass}
            color="secondary"
            error={Boolean(errors.confirmPassword)}
            helperText={
              errors.confirmPassword ? 'Las contraseñas deben coincidir' : ''
            }
            inputRef={register({
              required: true,
              validate: value => value === getValues().password,
            })}
            placeholder="Confirme su contraseña"
            name="confirmPassword"
            type="password"
            variant="outlined"
            onChange={() =>
              restrictPattern('confirmPassword', /^[0-9a-zA-Z]+$/)
            }
            autoComplete="off"
          />
        </FormControl>
        <Button
          className={classes.btn}
          color="primary"
          variant="contained"
          disabled={!canResetPassword()}
          onClick={handleResetPassword}
        >
          Confirmar nueva contraseña
        </Button>
      </form>
      {openDialog && (
        <MuiDialog
          onClose={!openDialog}
          onOpen={openDialog}
          body={{
            success: 'Contraseña actualizada',
          }}
        />
      )}
    </Dialog>
  );
}

ModalResetPassword.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  idNumber: PropTypes.number,
  setHasToUpdatePass: PropTypes.func,
  setState: PropTypes.func,
  setShowUpdateBtn: PropTypes.func,
};
