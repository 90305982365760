class Comorbidities {
  constructor() {
    this.COMORBIDITIES = {
      '1. diabetes': 'diabetes',
      '2. hipertensión': 'hypertension',
      '3. asma': 'asthma',
      '4. cáncer': 'cancer',
      '5. insuficiencia renal': 'renalInsufficiency',
      '6. enf. cardiovascular': 'enfCardiovascular',
    };
  }
  getInitComorbidities(init) {
    return Object.keys(this.COMORBIDITIES).reduce((acc, symptom) => {
      acc[symptom] = init || false;
      return acc;
    }, {});
  }
}

export default new Comorbidities();
