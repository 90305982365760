import React, { useReducer, useEffect, useState } from 'react';
// Google Analytics
import ReactGA from 'react-ga';
import { ThemeProvider, Snackbar, Button } from '@material-ui/core';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import LoadingSpinner from 'react-loader-spinner';
import isMobile from 'ismobilejs';

import AppContext from './context/AppContext';
import Header from './components/Header';
import InstallButton from './components/InstallButton';
import InternetNotification from './components/internetNotification';
import Login from './route/Login';
import PrivateRoute from './components/PrivateRoute';
import reducer from './reducer';
import SignUp from './route/SignUp';
import theme from './theme';
import { post } from './utils/fetch';
import {
  SET_IS_MOVIL_DEVICE,
  USER_LOGIN,
  USER_LOGOUT,
  RESET_STATES,
} from './reducer/actions';

import './styles/App.css';
import Vaccination from './route/Vaccination';
import VaccinationRegister from './route/VaccinationRegister';
import VaccinationSymptoms from './route/VaccinationSymptoms';
import NotFoundPage from './route/NotFountPage';
import CheckStatus from './route/CheckStatus';
import AppStorage from './helpers/AppStorage';
import { initialState } from './utils/initialStateApp';
import { LocationProvider } from './context/LocationContext';
import { useServiceWorker } from './hooks/useServiceWorker';
import Appointment from './route/Appointment';
import Total from './route/Total';
import RegisteredVaccinate from './route/RegisteredVaccinate';
import MuiDialog from './components/MuiDialogUserDisabled';
import VaccinedTotal from './route/VaccinedTotal';

function App() {
  let location = useLocation();
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [connection, setConnection] = useState(window.navigator.onLine);
  const [isActive, setIsActive] = useState(true);
  const { showReload, reloadPage } = useServiceWorker();

  useEffect(() => {
    // Google Analytics pageview
    ReactGA.pageview(window.location.pathname);
  }, [location.pathname]);

  const logout = () => {
    dispatch({
      type: USER_LOGOUT,
    });
    dispatch({ type: RESET_STATES });
  };

  useEffect(() => {
    const fetchAuthData = async () => {
      let data = {};
      let userDisabled = false;
      try {
        data = await post('/auth').then(res => res.json());
        if (
          data.message &&
          data.message === 'The token is invalid or has not been provided.'
        ) {
          logout();
          setLoggedIn(false);
          setLoading(false);
          return;
        }
        if (data.message && data.message === 'The user is disabled') {
          userDisabled = true;
          setIsActive(false);
          setTimeout(() => {
            logout();
            window.location.reload();
          }, 1500);
        }
      } catch (error) {
        logout();
        setLoading(false);
        setLoggedIn(false);
      }
      const center = AppStorage.get('center', true) || {};

      if (data.data && data.data.companyId) {
        dispatch({
          type: USER_LOGIN,
          payload: {
            ...data.data,
            ...center,
          },
        });
        setLoggedIn(true);
      }
      if (!userDisabled) {
        setLoading(false);
      }
    };

    dispatch({
      type: SET_IS_MOVIL_DEVICE,
      payload: isMobile(userAgent).phone,
    });

    fetchAuthData();
  }, [userAgent]);

  function updateIndicator() {
    setConnection(window.navigator.onLine);
  }
  window.addEventListener('online', updateIndicator);
  window.addEventListener('offline', updateIndicator);

  return (
    <>
      {!loading ? (
        <ThemeProvider theme={theme}>
          <InternetNotification status={connection} />
          <LocationProvider>
            <AppContext.Provider value={{ state, dispatch }}>
              <Header />
              <Switch>
                <Route exact path="/signup" component={SignUp} />
                <Route exact path="/login" component={Login} />

                <Route exact path="/" component={Login} />
                <Route
                  exact
                  path="/checkpoint-status"
                  component={CheckStatus}
                />
                <PrivateRoute
                  exact
                  path="/vaccination"
                  component={Vaccination}
                />
                <PrivateRoute
                  exact
                  path="/vaccination/register"
                  component={VaccinationRegister}
                />
                <PrivateRoute
                  exact
                  path="/vaccination/symptoms"
                  component={VaccinationSymptoms}
                />
                <PrivateRoute
                  exact
                  path="/appointment"
                  component={Appointment}
                />
                <PrivateRoute exact path="/totals" component={Total} />
                <PrivateRoute
                  exact
                  path="/vaccined-totals"
                  component={VaccinedTotal}
                />
                <PrivateRoute
                  exact
                  path="/registered-vaccinate"
                  component={RegisteredVaccinate}
                />
                {/* {userData && userData.role === VACCINATION && ( )} */}
                <Route path="*" component={NotFoundPage} />
              </Switch>

              <InstallButton />
            </AppContext.Provider>
          </LocationProvider>
        </ThemeProvider>
      ) : (
        isActive && (
          <LoadingSpinner type="TailSpin" className="spinner" color="#3A9AEC" />
        )
      )}
      {loggedIn && <Redirect to={window.location.pathname} />}
      <MuiDialog
        onClose={isActive}
        onOpen={!isActive}
        body={{
          message: 'Su cuenta fue deshabilitada. Contacte al administrador',
        }}
      />
      <Snackbar
        open={showReload}
        message="Una nueva versión de la aplicación se ha detectado. Se actualizará la aplicación para obtener los nuevos cambios."
        onClick={reloadPage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        action={
          <Button color="inherit" size="small" onClick={reloadPage}>
            Reload
          </Button>
        }
      />
    </>
  );
}

export default App;
