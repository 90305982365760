import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  btn: {
    backgroundColor: '#f7f7f7',
    border: '1px solid #00205ce8',
    color: '#4A4A4A',
    fontSize: 20,
    height: '3.5em',
    textTransform: 'lowercase',
    width: '100%',
    '&$selected': {
      backgroundColor: '#0153b32b',
      border: '2px solid #072548',
      color: '#194b85',
      paddingBottom: '1em',
      paddingTop: '1%',
    },
  },
  btnOther: {
    alignItems: 'center',
    backgroundColor: '#f7f7f7',
    border: '1px solid #00205ce8',
    color: '#4A4A4A',
    fontSize: 20,
    height: '35%',
    justifyContent: 'center',
    marginBottom: '8%',
    textTransform: 'lowercase',
    width: '100%',
    '&$selected': {
      backgroundColor: '#0153b32b',
      border: '2px solid #072548',
      color: '#194b85',
      paddingBottom: '1em',
      paddingTop: '2%',
    },
  },
  btnNone: {
    display: 'none',
  },
  text: {
    fontFamily: 'Montserrat',
    fontSize: 17,
    fontWeight: 600,
    '&:first-letter': {
      textTransform: 'uppercase',
    },
    [theme.breakpoints.down(1025)]: {
      fontSize: 15,
    },
    [theme.breakpoints.down(450)]: {
      fontSize: 10,
    },
  },
  iconCheck: {
    fontSize: '1.2rem',
    marginLeft: '93%',
    [theme.breakpoints.down(550)]: {
      marginLeft: '90%',
    },
    [theme.breakpoints.down(450)]: {
      width: '26%',
      marginLeft: '80%',
      paddingTop: '3px',
    },
  },
  blockSymptomsOther: {
    display: 'flex',
    width: '98%',
    margin: '1%',
    marginBottom: '10%',
  },
  containerSymptomsOther: {
    width: '98%',
    margin: '1%',
  },
  selected: {},
}));
