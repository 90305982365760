import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

export default function DatePickerComponent({
  handleDateChange,
  selectedDate,
}) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
        <KeyboardDatePicker
          fullWidth
          disableToolbar
          autoComplete="off"
          inputVariant="outlined"
          variant="inline"
          format="dd/MM/yyyy"
          placeholder="dd/MM/yyyy"
          invalidDateMessage=""
          margin="normal"
          id="date-picker-inline"
          value={selectedDate || null}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
}

DatePickerComponent.propTypes = {
  handleDateChange: PropTypes.func,
  selectedDate: PropTypes.string,
};

DatePickerComponent.defaultProps = {
  selectedDate: '',
};
