import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  creatableSelect: {
    height: '3em',
    marginTop: 10,
    padding: 2,
    width: '60%',
  },
  sectionHeader: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
    fontWeight: 700,
    textTransform: 'uppercase',
    textAlign: 'left',
    marginLeft: 20,
    [theme.breakpoints.down(430)]: {
      marginLeft: 15,
    },
  },
  btnContainer: {
    width: '92%',
    marginLeft: 22,
    marginTop: 10,
    [theme.breakpoints.down(540)]: {
      padding: 10,
      width: '90%',
    },
    [theme.breakpoints.down(430)]: {
      padding: 3,
      width: '89%',
    },
    [theme.breakpoints.down(380)]: {
      padding: 3,
      width: '87.5%',
    },
    [theme.breakpoints.down(325)]: {
      padding: 5,
      width: '85%',
    },
  },
  cardForCamera: {
    borderRadius: 15,
    display: 'block',
    marginTop: '5%',
    padding: '1% 0% 10%',
    textAlign: 'center',
    [theme.breakpoints.down(430)]: {
      minWidth: 225,
      Width: '100%',
    },
  },
  cardForCameraRegistered: {
    borderRadius: 15,
    display: 'block',
    marginTop: '5%',
    padding: '1% 1% 10%',
    textAlign: 'center',
    backgroundColor: '#8FCFFF52',
    [theme.breakpoints.down(430)]: {
      minWidth: 225,
      Width: '100%',
    },
  },
  cardForCameraNotRegistered: {
    borderRadius: 15,
    display: 'block',
    marginTop: '5%',
    padding: '1% 1% 10%',
    textAlign: 'center',
    backgroundColor: '#FBE12678',
    [theme.breakpoints.down(430)]: {
      minWidth: 225,
      Width: '100%',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    marginTop: 20,
    minWidth: 120,
    width: '82%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  alertWarning: {
    backgroundColor: '#FFFBE0',
    color: '#E29E41',
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
  },
  labelTitleSelect: {
    textAlign: 'left',
    marginTop: '0.3em',
    fontFamily: 'Montserrat',
  },
  labelTitle: {
    textAlign: 'left',
  },
  labelCardCode: {
    textAlign: 'left',
    color: 'gray',
    fontSize: '12px',
  },
  placeHolderSelect: {
    textAlign: 'left',
  },
  selectInputClass: {
    width: '92%',
    color: '#9B9B9B',
    marginBottom: 10,
    marginTop: '2.5%',
    textAlign: 'left',

    [theme.breakpoints.down(540)]: {
      width: '92%',
    },
    [theme.breakpoints.down(430)]: {
      width: '92%',
    },
    [theme.breakpoints.down(380)]: {
      width: '90%',
    },
  },
  selectLoteInputClass: {
    color: '#9B9B9B',
    textAlign: 'left',
    [theme.breakpoints.down(430)]: {
      width: '100%',
    },
  },
  classSelectInput: {
    width: '100%',
    color: '#9B9B9B',
    textAlign: 'left',
  },
  alertPreviewDose: {
    color: '#ABA090',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    backgroundColor: '#FFF7F2',
    [theme.breakpoints.down(400)]: {
      fontSize: 12,
    },
  },
  alertStatusRegistered: {
    padding: 0,
    fontFamily: 'Montserrat',
    fontSize: 16,
    background: 'none',
    [theme.breakpoints.down(400)]: {
      fontSize: 14,
    },
  },
  backgroundGreen: {
    backgroundColor: '#DFF2D7',
    marginTop: 3,
  },
  backgroundYellow: {
    backgroundColor: '#F8F3D8',
    marginTop: 3,
  },
  backgroundRed: {
    backgroundColor: '#EBE1C6',
    marginTop: 3,
  },
  backgroundBlue: {
    backgroundColor: '#CEE8F5',
    marginTop: 3,
  },
  rowButtonTextfield: {
    width: '86%',
    marginBottom: 10,
    marginTop: '2.5%',
    marginLeft: 34,
    alignItems: 'flex-end',

    [theme.breakpoints.down(540)]: {
      width: '90%',
    },
    [theme.breakpoints.down(430)]: {
      width: '87%',
      marginLeft: 26,
    },
    [theme.breakpoints.down(380)]: {
      width: '85%',
      marginLeft: 25,
    },
  },
  btnSend: {
    borderRadius: '10px',
    height: 50,
    width: '45%',
    [theme.breakpoints.down(430)]: {
      marginTop: '5%',
    },
  },
  btnClean: {
    borderRadius: '10px',
    height: 50,
    width: '45%',

    [theme.breakpoints.down(430)]: {
      marginTop: '5%',
    },
  },
  card: {
    left: '0',
    margin: 'auto',
    marginTop: '3%',
    maxWidth: 550,
    padding: 15,
    paddingBottom: 40,
    position: 'absolute',
    right: '0',
    textAlign: 'right',
  },
  errorMsg: {
    color: '#E20613',
    fontWeight: 600,
    margin: 8,
    [theme.breakpoints.down(430)]: {
      fontWeight: 300,
      fontSize: '30px',
      margin: 5,
    },
  },
  labelCompany: {
    fontWeight: 900,
    letterSpacing: -3,
    textTransform: 'capitalize',
    marginTop: '10%',
    [theme.breakpoints.down(430)]: {
      letterSpacing: -1,
      fontSize: '30px',
    },
  },
  labelSend: {
    color: '#fff',
  },
  labelCancel: {
    color: '#00205C',
  },
  sendIcon: {
    width: 50,
    [theme.breakpoints.down(430)]: {
      height: 20,
      width: 30,
    },
  },
  formControlLabel: {
    left: '15px',
  },
  root: {
    flexGrow: 1,
    margin: '2% 20%',
    [theme.breakpoints.down(1024)]: {
      margin: '2% 10%',
    },
  },
  fontLabel: {
    fontFamily: 'Montserrat',
    textTransform: 'capitalize',
  },
  toggleSelected: {
    backgroundColor: '#00205C !important',
  },
  passPortInput: {
    '& .MuiInputBase-input': {
      textTransform: 'uppercase',
    },
  },
  headerDialogErr: {
    height: '8em',
    paddingTop: '4em',
    paddingBottom: '1.5em',
    [theme.breakpoints.down(400)]: {
      paddingBottom: '0',
    },
  },
  bodyDialogErr: {
    color: '#00205C',
    fontSize: '15px',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    textAlign: 'center',
    justifyContent: 'center',
  },
  img: {
    display: 'flex',
    margin: 'auto',
    justifyContent: 'center',
    alignContent: 'center',
    width: '25%',
    [theme.breakpoints.down(400)]: {
      width: '40%',
    },
  },
  title: {
    color: '#00205C',
    fontSize: '15px',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    textAlign: 'left',
    justifyContent: 'center',
  },
  titleField: {
    fontSize: '15px',
    textAlign: 'left',
    margin: '15px 0 -14px 17px',
    fontFamily: 'Montserrat',
    justifyContent: 'center',
  },
  titleYoungerForm: {
    color: '#000000',
    fontSize: '18px',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    textAlign: 'left',
    margin: '10px 0 17px',
  },
  bodyContent: {
    margin: '0 20px',
    textAlign: 'left',
    justifyContent: 'left',
  },
  toggleButton: {
    width: '80%',
    margin: '15px 0',
    float: 'left',
    [theme.breakpoints.down(400)]: {
      width: '45%',
    },
  },
  toggleButtonTop: { marginTop: 15, width: '100%' },
  header: {
    paddingTop: '2em',
    textAlign: 'center',
    [theme.breakpoints.down(400)]: {
      paddingBottom: '0',
    },
    '& .MuiTypography-h6': {
      fontWeight: 'bold',
    },
  },
}));

export const useStylesBootstrap = makeStyles(theme => ({
  arrow: {
    color: theme.palette.common.black,
    left: '0 !important',
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    width: 300,
    [theme.breakpoints.down(385)]: {
      width: '82%',
    },
    [theme.breakpoints.down(370)]: {
      width: '78%',
    },
    [theme.breakpoints.down(355)]: {
      width: '74%',
    },
    [theme.breakpoints.down(340)]: {
      width: '70%',
    },
  },
}));
