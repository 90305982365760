import {
  Dialog,
  DialogTitle,
  MenuItem,
  TextField,
  Typography,
  Select,
  Button,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import useStyles from './styled';
import { put, get } from '../../utils/fetch';
import AppContext from '../../context/AppContext';
import checked from '../../icons/checked.png';
import ToggleButton from '../ToggleButton';
export default function EditModal({ open, dataSelected, onClose, getList }) {
  const {
    state: {
      userData: { personId },
    },
  } = useContext(AppContext);

  const { handleSubmit, control, errors, setValue } = useForm();
  const classes = useStyles();
  const [vaccineTypes, setVaccineTypes] = useState([]);
  const [numDose, setNumDose] = useState(
    dataSelected.numDose ? dataSelected.numDose.toString() : ''
  );
  const [updatedData, setUpdatedData] = useState({});
  const [isSaved, setIsSaved] = useState(false);
  const [loteChange, setLoteChange] = useState('');
  const [lotes, setLotes] = useState([]);
  const [vaccineChange, setVaccineChange] = useState(
    dataSelected.vaccineTypeId
  );

  const onSubmit = data => {
    updateData(data);
    setIsSaved(true);
  };

  useEffect(() => {
    get(`/vaccination/vaccine-types`)
      .then(res => res.json())
      .then(({ data }) => {
        setVaccineTypes(data || []);
      });
  }, []);

  useEffect(() => {
    let vaccineName = vaccineTypes.length
      ? vaccineTypes.filter(({ id, active }) => vaccineChange === id && active)
      : dataSelected.vaccineType;
    get(`/lotes?vaccineType=${vaccineName[0].name || vaccineName}`)
      .then(data => data.json())
      .then(obj => {
        obj.data.splice(0, 0, { value: 999, label: 'Seleccione' });
        obj.data.splice(1, 0, {
          value: 888,
          label: vaccineName[0].name || vaccineName,
        });
        setLotes(obj.data);
      });
    document.body.addEventListener('click', () => {
      document.querySelector('.MuiPopover-paper')
        ? (() => {
            document.querySelector('.MuiPopover-paper').style.top = '54%';
            document.querySelector('.MuiPopover-paper').style.height = '120px';
          })()
        : void 0;
    });
  }, [vaccineChange, vaccineTypes, dataSelected.vaccineType]);

  useEffect(() => {
    if (dataSelected.vaccineTypeId !== vaccineChange) {
      setLoteChange('Seleccione');
    } else {
      setLoteChange(dataSelected.vaccineId);
    }
  }, [dataSelected.vaccineTypeId, dataSelected.vaccineId, vaccineChange]);

  const updateData = ({ youngerName = '', youngerLastName = '' }) => {
    const data = {
      id: dataSelected.id,
      vaccineId: loteChange.toUpperCase(),
      vaccineTypeId: vaccineChange,
      numDose,
      personId,
      youngerName,
      youngerLastName,
    };
    put('/vaccination', data).then(res => res.json());
    setUpdatedData(data);
  };
  const handleChange = ({ target: { name, value } }) => {
    if (name === 'youngerName' || name === 'youngerLastName') {
      setValue(name, value.trimStart());
    }
  };

  const handleChangeNumDose = ({ value }) => {
    if (value !== null) {
      setNumDose(value);
    }
  };

  const reloadPage = () => {
    getList();
    onClose();
  };

  if (isSaved) {
    return (
      <Dialog
        fullWidth
        aria-labelledby="simple-dialog-title"
        open={open}
        onClose={onClose}
      >
        <DialogTitle className={classes.header} id="customized-dialog-title">
          <img className={classes.img} src={checked} alt="checked" />
          <Typography className={classes.modalTitle}>
            Los datos han sido editados correctamente.
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.bodyContent}>
            <Typography gutterBottom>
              <span className={classes.modalSubtitle}>Vacuna:</span>{' '}
              {
                vaccineTypes.find(
                  vaccine => vaccine.id === updatedData.vaccineTypeId
                ).name
              }
            </Typography>
            <Typography gutterBottom>
              <span className={classes.modalSubtitle}>Dosis:</span>{' '}
              {updatedData.numDose}
            </Typography>
            <Typography gutterBottom>
              <span className={classes.modalSubtitle}>Lote:</span> {loteChange}
            </Typography>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={reloadPage} color="primary">
            Seguir editando
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  const doseOptions = [
    { name: '1ra dosis', value: '1' },
    { name: '2da dosis', value: '2' },
    { name: '3ra dosis', value: '3' },
    { name: '4ta dosis', value: '4' },
    { name: '5ta dosis', value: '5' },
  ];

  return (
    <Dialog
      fullWidth
      aria-labelledby="simple-dialog-title"
      open={open}
      onClose={onClose}
    >
      <DialogTitle id="simple-dialog-title">Edición de campos</DialogTitle>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ padding: '0em 2em 2em 2em' }}
        onChange={handleChange}
      >
        {!dataSelected.isYounger && (
          <>
            <Typography style={{ textAlign: 'left', paddingTop: '10px' }}>
              Documento de identificación
            </Typography>
            <Controller
              fullWidth
              defaultValue={dataSelected.documentNumber}
              as={<TextField disabled />}
              control={control}
              name="documentNumber"
              variant="outlined"
            />
          </>
        )}

        <Typography style={{ textAlign: 'left', paddingTop: '10px' }}>
          Nombre(s) del vacunado
        </Typography>
        {dataSelected.isYounger ? (
          <>
            <Controller
              defaultValue={dataSelected.name}
              control={control}
              name="youngerName"
              error={Boolean(errors.youngerName)}
              helperText={errors.youngerName && errors.youngerName.message}
              as={
                <TextField
                  color="secondary"
                  fullWidth
                  placeholder={'Digite el nombre del menor'}
                  variant="outlined"
                  autoComplete="off"
                />
              }
              rules={{
                required: 'Este campo es requerido',
                pattern: {
                  value: /^[a-zA-Z ]+$/,
                  message: 'Este campo solo permite letras y números',
                },
              }}
            />
            <Typography style={{ textAlign: 'left', paddingTop: '10px' }}>
              Apellido(s) del vacunado
            </Typography>
            <Controller
              defaultValue={dataSelected.lastName}
              control={control}
              name="youngerLastName"
              error={Boolean(errors.lastName)}
              helperText={errors.lastName && errors.lastName.message}
              as={
                <TextField
                  color="secondary"
                  fullWidth
                  placeholder={'Digite el apellido del menor'}
                  variant="outlined"
                  autoComplete="off"
                />
              }
              rules={{
                required: 'Este campo es requerido',
                pattern: {
                  value: /^[a-zA-Z ]+$/,
                  message: 'Este campo solo permite letras y números',
                },
              }}
            />
          </>
        ) : (
          <Controller
            fullWidth
            defaultValue={dataSelected.name}
            as={<TextField disabled />}
            control={control}
            name="firstName"
            variant="outlined"
          />
        )}

        <Typography style={{ textAlign: 'left', paddingTop: '10px' }}>
          Tipo de vacuna
        </Typography>
        <Controller
          defaultValue={dataSelected.vaccineTypeId}
          render={({ field }) => {
            return (
              <Select
                fullWidth
                defaultValue={dataSelected.vaccineTypeId}
                variant="outlined"
                color="secondary"
                {...field}
                onChange={e => setVaccineChange(e.target.value)}
                displayEmpty
              >
                {vaccineTypes.map(vaccine => (
                  <MenuItem
                    key={vaccine.name.replace(/ /g, '_')}
                    value={vaccine.id}
                  >
                    {vaccine.name}
                  </MenuItem>
                ))}
              </Select>
            );
          }}
          control={control}
          name="vaccineTypeId"
        />
        <ToggleButton
          title={'Dosis Correspondiente'}
          name={'numDose'}
          value={numDose}
          options={doseOptions}
          onChange={handleChangeNumDose}
          styleTBG={classes.toggleButton}
          styleContainer={{ marginLeft: '-20px', marginTop: '10px' }}
        />
        <Typography style={{ textAlign: 'left', paddingTop: '10px' }}>
          Lote
        </Typography>
        <Controller
          render={({ field }) => {
            return (
              <Select
                fullWidth
                {...field}
                variant="outlined"
                value={loteChange}
                onChange={e => {
                  setLoteChange(e.target.value);
                }}
                displayEmpty
              >
                {lotes.map(({ value, label }) => (
                  <MenuItem
                    key={value}
                    value={label}
                    disabled={((label, value) =>
                      label === 'Seleccione' || value === 888)(label, value)}
                    style={((label, value) => {
                      let styles = {
                        width: 'min-content',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        padding: '0px 0px 5px 0px',
                      };
                      label === 'Seleccione'
                        ? (styles.backgroundColor = 'rgb(250, 250, 250)')
                        : (value =>
                            value === 888
                              ? ((styles.color = 'rgb(0, 50, 150)'),
                                (styles.borderRadius = '5px 5px 5px 5px'))
                              : void 0)(value);
                      return styles;
                    })(label, value)}
                  >
                    {label}
                  </MenuItem>
                ))}
              </Select>
            );
          }}
          control={control}
          name="vaccineId"
        />
        <Button
          variant="contained"
          className={classes.btnClose}
          onClick={onClose}
        >
          Cancelar
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.btnSend}
          disabled={
            !loteChange || loteChange === 'Seleccione' || errors.youngerName
          }
        >
          Enviar
        </Button>
      </form>
    </Dialog>
  );
}

EditModal.prototype = {
  open: PropTypes.bool,
  dataSelected: PropTypes.object,
  onClose: PropTypes.func,
  getList: PropTypes.func,
};
