import { InputAdornment, TextField } from '@material-ui/core';
import { Cancel, CheckCircle } from '@material-ui/icons';
import React from 'react';
import InputMask from 'react-input-mask';
import useStyles from './styled';

export default function InputMaskComponent({
  mask,
  name,
  value,
  onChange,
  label,
  showWrongIcon,
  showCheckMarkIcon,
}) {
  const classes = useStyles();
  return (
    <InputMask mask={mask} value={value} onChange={onChange}>
      {inputProps => (
        <TextField
          {...inputProps}
          className={classes.inputClass}
          color="secondary"
          id="outlined-text-input"
          placeholder={label}
          fullWidth
          name={name}
          variant="outlined"
          autoComplete="off"
          InputProps={
            showWrongIcon
              ? {
                  startAdornment: (
                    <InputAdornment position="start">
                      <Cancel style={{ color: '#CE1616' }} />
                    </InputAdornment>
                  ),
                }
              : showCheckMarkIcon
              ? {
                  startAdornment: (
                    <InputAdornment position="start">
                      <CheckCircle style={{ color: '#5ACB01' }} />
                    </InputAdornment>
                  ),
                }
              : {}
          }
        />
      )}
    </InputMask>
  );
}
