import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemText, Divider } from '@material-ui/core';

import { Check, ImportExport } from '@material-ui/icons';

import useStyles from './styled';

// eslint-disable-next-line react/display-name
export const ListCenters = memo(
  ({ nearCenters, handleListItemClick, selectedIndex }) => {
    const classes = useStyles();

    if (!Array.isArray(nearCenters)) {
      return null;
    }

    return (
      <>
        <List component="nav" aria-label="secondary mailbox folder">
          {nearCenters &&
            nearCenters.map(({ name, id }, e) => (
              <div key={e}>
                <ListItem
                  button
                  className={selectedIndex === id && classes.listCenters}
                  onClick={event => handleListItemClick(id)}
                >
                  <ListItemText primary={name} />
                  {selectedIndex === id && <Check />}
                </ListItem>
                <Divider />
              </div>
            ))}
        </List>
        {nearCenters && nearCenters.length > 4 && (
          <span className={classes.btn} disabled>
            <ImportExport />
          </span>
        )}
      </>
    );
  }
);

ListCenters.propTypes = {
  nearCenters: PropTypes.array,
  handleListItemClick: PropTypes.func,
  selectedIndex: PropTypes.string,
};
