import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import useStyles from './styled';
import { ArrowDownward, EditOutlined, FilterList } from '@material-ui/icons';
import {
  Button,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@material-ui/core';
import EditModal from '../EditModal';
import _ from 'lodash';

export default function ListOfRegisteredVaccinate({
  listOfPeople,
  showActions,
  showDate,
  getList,
  setFilterValue,
  filterValue,
  page,
  nextPage,
}) {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const [dataSelected, setDataSelected] = useState('');

  const debounce = useCallback(
    _.debounce(_searchVal => {
      setFilterValue(_searchVal);
      if (!_searchVal) {
        getList();
      }
    }, 100),
    []
  );

  const handleChange = ({ target: { name, value } }, newPage) => {
    if (name !== 'filterValue') {
      setFilterValue('');
      newPage = newPage === 0 ? 1 : newPage;
      getList(newPage || 1);
    } else {
      debounce(value);
      setFilterValue(value);
      getList();
    }
  };

  return (
    <TableContainer>
      {Boolean(showActions) && (
        <TextField
          color="secondary"
          id="outlined-text-input"
          placeholder="Filtra por nombre o documento"
          name="filterValue"
          variant="outlined"
          autoComplete="off"
          className={classes.filter}
          value={filterValue || ''}
          onChange={e => {
            handleChange(e);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FilterList />
              </InputAdornment>
            ),
          }}
        />
      )}
      <Table>
        {listOfPeople && listOfPeople.length > 0 ? (
          <TableHead>
            <TableRow>
              <TableCell className={classes.head} align="center">
                Nombre(s)
              </TableCell>
              {Boolean(showDate) && (
                <TableCell className={classes.head} align="center">
                  Fecha
                  <ArrowDownward
                    fontSize="small"
                    align="center"
                    color="disabled"
                  />
                </TableCell>
              )}
              {Boolean(showActions) && (
                <TableCell className={classes.head} align="center">
                  Acción
                </TableCell>
              )}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {listOfPeople &&
          Array.isArray(listOfPeople) &&
          listOfPeople.length ? (
            listOfPeople.map(({ name, date }, index) => {
              return (
                <TableRow hover key={name + index}>
                  <TableCell align="center">{name.toUpperCase()}</TableCell>
                  {Boolean(showDate) && (
                    <TableCell align="center">{date}</TableCell>
                  )}
                  {Boolean(showActions) && (
                    <TableCell align="center">
                      <Button
                        className={classes.btnEdit}
                        onClick={() => {
                          setDataSelected(listOfPeople[index]);
                          setModalOpen(true);
                          setFilterValue('');
                        }}
                      >
                        <EditOutlined
                          fontSize="small"
                          align="center"
                          color="white"
                        />
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
              );
            })
          ) : listOfPeople.length === 0 || filterValue ? (
            <TableRow className={classes.emptyRow}>
              <TableCell align="center" colSpan="2">
                <span className={classes.tableLabel}>
                  No hay pacientes registrados
                </span>
              </TableCell>
            </TableRow>
          ) : showActions ? (
            getList()
          ) : null}
        </TableBody>
        {Boolean(showActions) && (
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[]}
                count={listOfPeople.length && nextPage + 1}
                rowsPerPage={1}
                page={page}
                labelDisplayedRows={({ from, to }) =>
                  listOfPeople &&
                  listOfPeople.length > 0 &&
                  `${from - 1}/${to} de ${listOfPeople.length}`
                }
                onChangePage={handleChange}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
      {modalOpen && (
        <EditModal
          open={true}
          dataSelected={dataSelected}
          getList={getList}
          onClose={() => {
            setModalOpen(false);
            getList();
          }}
        />
      )}
    </TableContainer>
  );
}

ListOfRegisteredVaccinate.propTypes = {
  listOfPeople: PropTypes.array,
  showActions: PropTypes.bool,
  showDate: PropTypes.bool,
  getList: PropTypes.func,
  setFilterValue: PropTypes.func,
  filterValue: PropTypes.string,
  page: PropTypes.number,
};

ListOfRegisteredVaccinate.defaultProps = {
  listOfPeople: [],
  filterValue: '',
  showActions: false,
  showDate: false,
};
