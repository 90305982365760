import React, { useContext, useEffect, useState } from 'react';
import { Fab, Zoom } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';

import AppContext from '../../context/AppContext';
import { CLICK_INSTALL, SET_APP_INSTALLED } from '../../reducer/actions';
import useStyles from './styled';

export default function InstallButton() {
  const { state, dispatch } = useContext(AppContext);
  const { isInstallable } = state;
  const [isAppInstalled, setIsAppInstalled] = useState(false);
  const [promptEvent, setPromptEvent] = useState(null);
  const theme = useTheme();
  const classes = useStyles();

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  useEffect(
    function () {
      window.addEventListener('load', function () {
        (navigator.standalone ||
          matchMedia('(display-mode: standalone)').matches) &&
          setIsAppInstalled(true);
      });

      window.addEventListener('beforeinstallprompt', function (event) {
        event.preventDefault();
        setPromptEvent(event);
      });

      window.addEventListener('appInstalled', function () {
        dispatch({ type: CLICK_INSTALL });
      });

      dispatch({
        type: SET_APP_INSTALLED,
        payload: { isAppInstalled: isAppInstalled && !!promptEvent },
      });
    },
    [isAppInstalled, promptEvent, dispatch]
  );

  async function handleOnClick() {
    setIsAppInstalled(true);
    promptEvent.prompt();

    const userChoice = await promptEvent.userChoice;

    if (userChoice.outcome === 'accepted') {
      dispatch({ type: CLICK_INSTALL });
    } else {
      setIsAppInstalled(false);
    }
  }

  return (
    <React.Fragment>
      {!isAppInstalled && !!promptEvent && (
        <Zoom
          in={isInstallable}
          timeout={transitionDuration}
          style={{
            transitionDelay: `${transitionDuration.exit}ms`,
          }}
        >
          <Fab
            className={classes.fab}
            color="secondary"
            variant="extended"
            size="medium"
            onClick={handleOnClick}
          >
            <AddIcon />
            Instalar App
          </Fab>
        </Zoom>
      )}
    </React.Fragment>
  );
}
