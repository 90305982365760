import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  btnSend: {
    marginTop: '2%',
    borderRadius: '10px',
    height: 40,
    width: '35%',
    float: 'right',
    [theme.breakpoints.down(400)]: {
      width: '43%',
    },
  },
  btnClose: {
    marginTop: '2%',
    borderRadius: '10px',
    height: 40,
    width: '35%',
    float: 'left',
    [theme.breakpoints.down(400)]: {
      width: '43%',
    },
  },
  inputEmail: {
    marginBottom: '25px',
  },
  email: {
    margin: '0 5px 2px 0',
    paddingLeft: '5px',
    border: '1px solid #00205C',
    borderRadius: '5px',
    height: '25px',
  },
  emptyInput: {
    color: 'red',
    fontSize: '12px',
  },
  sendBtn: {
    backgroundColor: '#00205C',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#00205C',
    },
  },
}));
