import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  textFields: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
  },
  labelTitle: {
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'left',
    margin: '0.5em 0 0.5em 0',
  },
  container: {
    padding: '10em',
  },
  btn: {
    backgroundColor: '#00205C',
    borderRadius: '5px',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 'bold',
    height: '45.6px',
    margin: '8% auto 10%',
    maxHeight: 50,
    textDecoration: 'none',
    width: '100%',
    [theme.breakpoints.down(400)]: {
      height: 50,
    },
  },
}));
