import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '@material-ui/core';
import useStyles from './styled';

function getModalStyle() {
  return {
    top: `50%`,
    left: `0%`,
    transform: `translate(0, -50%)`,
  };
}

export default function SimpleModal({ isOpen, onClose, body }) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  return (
    <div>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={isOpen}
        onClose={onClose}
      >
        <div style={modalStyle} className={classes.paper}>
          {body}
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            onClick={onClose}
          >
            Ok
          </Button>
        </div>
      </Modal>
    </div>
  );
}

SimpleModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  body: PropTypes.string,
};

SimpleModal.defaultProps = {
  body: '',
};
