import React, { useContext, useEffect, useState } from 'react';
import useStyles from './styled';
import { catchError } from '../../catchErrors/ErrorComponent';
import ListOfRegisteredVaccinate from '../../components/List';
import { Paper } from '@material-ui/core';
import AppContext from '../../context/AppContext';
import { post } from '../../utils/fetch';
import AppStorage from '../../helpers/AppStorage';

const RegisteredVaccinate = () => {
  const classes = useStyles();
  const {
    state: {
      userData: { personId },
    },
  } = useContext(AppContext);
  const UserName = AppStorage.get('userName');
  const { centerId } = AppStorage.get('center', true) || {};
  const [registeredVaccinate, setRegisteredVaccinate] = useState([]);
  const [filterValue, setFilterValue] = useState('');
  const [selectedPage, setSelectedPage] = useState();
  const [nextPage, setNextPage] = useState(1);

  const getList = (page = 1) => {
    setSelectedPage(page);
    post('/vaccination/list', {
      centerId,
      personId,
      limit: 10,
      filterValue,
      page,
    })
      .then(res => res.json())
      .then(({ data, nextPage }) => {
        setNextPage(nextPage);
        setRegisteredVaccinate(data);
      });
  };

  useEffect(getList, []);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.tableTitleContainer}>
          <h3 className={classes.tableTitle}>{`Últimos registros de ${
            UserName && UserName
          }`}</h3>
        </div>
        <ListOfRegisteredVaccinate
          listOfPeople={registeredVaccinate}
          getList={getList}
          showActions={true}
          setFilterValue={setFilterValue}
          filterValue={filterValue}
          page={selectedPage}
          nextPage={nextPage}
        />
      </Paper>
    </div>
  );
};

export default catchError(RegisteredVaccinate);
