import React, { useContext, useState } from 'react';
import { Alert, ToggleButton } from '@material-ui/lab';
import { Box, Grid, Typography } from '@material-ui/core';
import { CheckCircleOutline } from '@material-ui/icons';
import AppContext from '../../../../context/AppContext';
import { SET_SYMPTOMS } from '../../../../reducer/actions';
import useStyles from './styled';
import { catchError } from '../../../../catchErrors/ErrorComponent';

function SymptomsForm() {
  const classes = useStyles();
  const { state, dispatch } = useContext(AppContext);
  const {
    symptomsData: { symptoms, error, noSymptoms },
  } = state;
  const [selectedOther, setSelectedOther] = useState(false);
  const [selectedSymptomLarge, setSelectedSymptomLarge] = useState(false);

  const handleSymptom = (symptom, selected) => {
    dispatch({
      type: SET_SYMPTOMS,
      payload: {
        error: false,
        symptoms: { ...symptoms, [symptom]: !selected },
        noSymptoms: false,
      },
    });
  };
  const handleSymptomsOut = symptom => {
    if (symptom === 'Otros') {
      setSelectedOther(!selectedOther);
    } else {
      setSelectedSymptomLarge(!selectedSymptomLarge);
    }
    dispatch({
      type: SET_SYMPTOMS,
      payload: {
        error: false,
        symptoms: {
          ...symptoms,
          Otros: !selectedOther,
          'Inflamación en el area de la inyección': !selectedSymptomLarge,
        },
        noSymptoms: false,
      },
    });
  };

  return (
    <React.Fragment>
      {error && (
        <Alert severity="error">Debe de seleccionar al menos una opción</Alert>
      )}
      <Box>
        <Grid container spacing={1}>
          {Object.entries(symptoms).map(([symptom, selected = false]) => {
            const checked = !noSymptoms && selected;
            return (
              <Grid key={symptom} item xs={6}>
                <ToggleButton
                  classes={
                    symptom === 'Otros' ||
                    symptom === 'Inflamación en el area de la inyección'
                      ? {
                          root: classes.btnNone,
                        }
                      : {
                          root: classes.btn,
                          selected: classes.selected,
                        }
                  }
                  onClick={() => handleSymptom(symptom, selected)}
                  value=""
                  selected={checked}
                >
                  <Grid
                    container
                    alignItems="center"
                    direction="column"
                    justify="center"
                  >
                    {selected && !noSymptoms && (
                      <CheckCircleOutline className={classes.iconCheck} />
                    )}
                    <Typography className={classes.text}>{symptom}</Typography>
                  </Grid>
                </ToggleButton>
              </Grid>
            );
          })}
          <div className={classes.containerSymptomsOther}>
            <ToggleButton
              classes={{
                root: classes.btnOther,
                selected: classes.selected,
              }}
              onClick={() =>
                handleSymptomsOut('Inflamación en el area de la inyección')
              }
              value=""
              selected={!noSymptoms && selectedSymptomLarge}
            >
              <Grid
                container
                alignItems="center"
                direction="column"
                justify="center"
              >
                {!noSymptoms && selectedSymptomLarge && (
                  <CheckCircleOutline className={classes.iconCheck} />
                )}
                <Typography className={classes.text}>
                  Inflamación en el area de la inyección
                </Typography>
              </Grid>
            </ToggleButton>

            <ToggleButton
              classes={{ root: classes.btn, selected: classes.selected }}
              className={`${selectedOther ? 'button-otros' : ''}`}
              onClick={() => handleSymptomsOut('Otros')}
              value=""
              selected={!noSymptoms && selectedOther}
            >
              <Grid
                container
                alignItems="center"
                direction="column"
                justify="center"
              >
                {!noSymptoms && selectedOther && (
                  <CheckCircleOutline className={classes.iconCheck} />
                )}
                <Typography className={classes.text}>Otros</Typography>
              </Grid>
            </ToggleButton>
          </div>
        </Grid>
      </Box>
    </React.Fragment>
  );
}
export default catchError(SymptomsForm);
