import React from 'react';
import { DialogTitle, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import useStyles from './styled';

const MuiDialogTitle = withStyles(useStyles)(props => {
  const { children, ...other } = props;
  const classes = useStyles();

  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
    </DialogTitle>
  );
});

export default MuiDialogTitle;
