export const CLICK_INSTALL = 'CLICK_INSTALL';
export const RESET_STATES = 'RESET_STATES';
export const SET_CAMERA_INFO = 'SET_CAMERA_INFO';
export const SET_LOCATION = 'SET_LOCATION';
export const SET_SYMPTOMS = 'SET_SYMPTOMS';
export const SET_TEMPERATURE = 'SET_TEMPERATURE';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const SET_BLUETOOTH_CONNECTION = 'SET_BLUETOOTH_CONNECTION';
export const SET_APP_INSTALLED = 'SET_APP_INSTALLED';
export const SET_IS_MOVIL_DEVICE = 'SET_IS_MOVIL_DEVICE';
export const SET_PACIENT_INFO = 'SET_PACIENT_INFO';
export const SET_COMORBIDITIES = 'SET_COMORBIDITIES';
export const RESET_COMORBIDITIES_STATES = 'RESET_COMORBIDITIES_STATES';
