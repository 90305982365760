import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    flexGrow: 1,
    position: 'absolute',
    marginTop: '50px',
    minWidth: '92%',
    margin: 'auto',
    height: '500px',
    display: 'block',
    justifyContent: 'center',
    '& > div:first-child': {
      '&::before': {
        position: 'absolute',
        top: '-50px',
        left: '0',
        color: '#00205C',
        'font-size': '20px',
        'font-family': 'Montserrat',
        'font-weight': 'bold',
      },
    },
    [theme.breakpoints.down(1400)]: {
      left: '0',
      right: '0',
    },
    [theme.breakpoints.up(1400)]: {
      left: '0',
      right: '0',
    },
  },
  card: {
    width: '96%',
    marginLeft: '2%',
    [theme.breakpoints.up(840)]: {
      width: '55%',
      marginLeft: '22.5%',
      marginTop: '6.2em',
    },
  },
  table: {
    width: '840px',
    overflow: 'hidden',
    [theme.breakpoints.up(840)]: {
      width: '100%',
    },
  },
  columns: {
    [theme.breakpoints.down(840)]: {
      overflow: 'scroll',
    },
  },
  labelTitleTop: {
    textAlign: 'left',
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontWeight: '600',
    margin: '1em 0 1em 0',
    [theme.breakpoints.down(500)]: {
      fontSize: '14px',
    },
  },
  date: {
    [theme.breakpoints.down(500)]: {
      fontSize: '14px',
      width: '95%',
    },
  },
  messageDataEmpty: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontWeight: 'bold',
  },
  messageData: {
    textAlign: 'center',
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontWeight: 'bold',
    marginTop: '70px',
    marginBottom: '80px',
    paddingBottom: '-10px',
    padding: '5px',
    textTransform: 'uppercase',
  },
  btnsContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  actionBtn: {
    background: '#00205CE3',
    color: '#fff',
    margin: '20px 15px 20px 10px',
    padding: '5px 10px',
    '&:hover': {
      background: '#00205CE3',
    },
  },
  alertStatus: {
    padding: 0,
    fontFamily: 'Montserrat',
    fontSize: 16,
    background: 'none',
    [theme.breakpoints.down(400)]: {
      fontSize: 14,
    },
  },
}));
