import React from 'react';
import PropTypes from 'prop-types';
import { Container, Typography } from '@material-ui/core';
import {
  ToggleButton as ToggleButtonMaterial,
  ToggleButtonGroup,
} from '@material-ui/lab';
import useStyles from './styled';

function ToggleButton({
  title,
  name: attrName,
  value: attrValue,
  onChange,
  options,
  styleTB,
  styleTBG,
  styleContainer,
}) {
  const classes = useStyles();
  return (
    <Container
      style={!styleContainer ? { margin: '10px 0 0 16px' } : styleContainer}
    >
      <Typography style={{ textAlign: 'left' }}>{title}</Typography>
      <ToggleButtonGroup
        value={attrValue}
        exclusive
        name={attrName}
        onChange={(event, value) => {
          if (value && onChange) {
            onChange({ name: attrName, value });
          }
        }}
        className={styleTBG}
      >
        {options.map(({ name, value }) => (
          <ToggleButtonMaterial
            key={name}
            value={value}
            style={styleTB}
            className={value === attrValue ? classes.toggleSelected : 'none'}
          >
            {name}
          </ToggleButtonMaterial>
        ))}
      </ToggleButtonGroup>
    </Container>
  );
}

ToggleButton.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  styleTB: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  styleTBG: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  styleContainer: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

ToggleButton.defaultProps = {
  title: '',
  styleTB: undefined,
  styleTBG: undefined,
  styleContainer: undefined,
};

export default ToggleButton;
