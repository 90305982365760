import React, { useEffect, useState } from 'react';
import {
  Paper,
  TextField,
  InputAdornment,
  Container,
  Typography,
  Divider,
} from '@material-ui/core';
import { Cancel, CheckCircle } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import QRCodeReader from '../../components/QRCodeReader';
import Patient from '../../helpers/Patient';
import { catchError } from '../../catchErrors/ErrorComponent';
import InputMask from 'react-input-mask';
import useStyles from './styled';

function CheckStatus() {
  const classes = useStyles();
  const [idNumberField, setIdNumberField] = useState('');
  const [errorScanIdNumber, setErrorScanIdNumber] = useState();
  const [checkStatusData, setCheckStatusData] = useState({});

  useEffect(() => {
    const finder = idNumberField;

    if (finder && finder.length === 11) {
      (async () => {
        const { valid, status_id, name } =
          (await Patient.getBasicCitizenData(finder)) || {};
        setCheckStatusData({
          valid,
          status_id,
          name: name.toLowerCase(),
        });
        if (!valid) return;
      })();
    }
  }, [idNumberField]);

  const handleChange = ({ target: { value } }, pattern) => {
    value = value.replace(/\D/g, '');
    if (!idNumberField.match(pattern)) {
      setIdNumberField(value.slice(0, value.length - 1));
    } else {
      setIdNumberField(value);
    }
  };

  const handleScanID = async data => {
    const {
      valid,
      cedula,
      status_id,
      name,
    } = await Patient.getBasicCitizenData(data);
    setErrorScanIdNumber(false);
    if (!valid) return;
    setCheckStatusData({
      valid,
      status_id,
      name: name.toLowerCase(),
    });
    handleChange({ target: { value: cedula } });
  };

  const handleErrorScanIdNumber = () => {
    setErrorScanIdNumber(true);
  };

  const showWrongIcon = !checkStatusData.valid && idNumberField;
  const showCheckMarkIcon =
    checkStatusData.valid === true &&
    idNumberField &&
    idNumberField.length === 11;

  const STATUS_DATA = [
    {
      className: classes.backgroundYellow,
      text: 'Paciente sin consentimiento Digital.',
    },
    {
      className: classes.backgroundRed,
      text: 'Fase de vacunación no activada.',
    },
    {
      className: classes.backgroundBlue,
      text: 'Paciente registrado y sin cita.',
    },
    {
      className: classes.backgroundGreen,
      text: 'La persona tiene el consentimiento digital firmado.',
    },
  ];
  const statusData = STATUS_DATA[checkStatusData.status_id] || STATUS_DATA[0];

  return (
    <div className={classes.root}>
      {errorScanIdNumber && (
        <Alert
          variant="filled"
          severity="warning"
          className={classes.alertWarning}
        >
          Problemas con el escanéo de la cédula
        </Alert>
      )}
      <Container className={classes.btnContainer}>
        <QRCodeReader
          onScan={handleScanID}
          onError={handleErrorScanIdNumber}
          label="Escanear Cédula"
        />
      </Container>
      <Paper className={classes.paper}>
        <Container>
          <Typography
            variant="h2"
            className={classes.sectionHeader}
            style={{ paddingTop: 22 }}
          >
            Información del ciudadano
          </Typography>
        </Container>

        <Container style={{ padding: '5%' }}>
          <>
            <InputMask
              mask="999-9999999-9"
              value={idNumberField}
              onChange={handleChange}
            >
              {inputProps => (
                <TextField
                  {...inputProps}
                  fullWidth
                  color="secondary"
                  id="outlined-text-input"
                  placeholder="Buscar el estado de la cédula"
                  name="idNumberField"
                  variant="outlined"
                  autoComplete="off"
                  InputProps={
                    showWrongIcon
                      ? {
                          startAdornment: (
                            <InputAdornment position="start">
                              <Cancel style={{ color: '#CE1616' }} />
                            </InputAdornment>
                          ),
                        }
                      : showCheckMarkIcon
                      ? {
                          startAdornment: (
                            <InputAdornment position="start">
                              <CheckCircle style={{ color: '#5ACB01' }} />
                            </InputAdornment>
                          ),
                        }
                      : {}
                  }
                />
              )}
            </InputMask>
          </>
          <Container
            style={{ padding: '5%', textAlign: 'left', lineHeight: 2 }}
          >
            {checkStatusData.name && idNumberField.length === 11 && (
              <div style={{ display: 'flex' }}>
                <Typography
                  style={{ fontWeight: 700, padding: 4 }}
                  className={classes.fontLabel}
                >
                  Nombre:
                </Typography>
                <span className={classes.fontLabel}>
                  {checkStatusData.name}
                </span>
              </div>
            )}

            {showCheckMarkIcon && (
              <>
                <Alert
                  severity="info"
                  color="#000000"
                  className={
                    classes.alertStatusRegistered && statusData.className
                  }
                >
                  {statusData.text}
                </Alert>
                <Divider />
              </>
            )}
          </Container>
        </Container>
      </Paper>
    </div>
  );
}

export default catchError(CheckStatus);
