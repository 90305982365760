import AppStorage from '../helpers/AppStorage';
import Symptom from '../helpers/Symptom';
import Comorbidities from '../helpers/Comorbidities';

const { vaccineType } = AppStorage.get('center', true) || {};

export const initialState = {
  isInstallable: true,
  isAppInstalled: false,
  isUserLoggedIn: false,
  isMobileDevice: false,
  userData: undefined,
  isConnectedToBluetooth: false,
  idInfo: {
    error: false,
    documentNumber: { name: 'Cédula', value: '' },
    passport: { name: 'Pasaporte', value: '' },
    firstNames: { name: 'Nombres', value: '' },
    lastNames: { name: 'Apellidos', value: '' },
  },
  pacientInfo: {
    error: false,
    provinceId: { name: 'Provincia', value: '' },
    townId: { name: 'Municipio', value: '' },
    sectorId: { name: 'Sector', value: '' },
    phoneNumber: { name: 'Télefono de contacto', value: '' },
    vaccineId: { name: 'Vacunación', value: '' },
    numDose: '',
    vaccineTypeId: { name: 'Tipos de vacunación', value: vaccineType || '' },
    longitude: '',
    latitude: '',
    acceptConditions: true,
  },
  temperature: 36.5,
  symptomsData: {
    error: false,
    noSymptoms: true,
    symptoms: {
      ...Symptom.getInitSymptomps(),
    },
  },
  comorbidities: {
    ...Comorbidities.getInitComorbidities(),
  },
  location: {
    longitude: null,
    latitude: null,
  },
};
