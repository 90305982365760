import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  btnSend: {
    borderRadius: '10px',
    height: 50,
    width: '45%',
    [theme.breakpoints.down(430)]: {
      marginTop: '5%',
    },
  },
  btnClean: {
    borderRadius: '10px',
    height: 50,
    width: '45%',

    [theme.breakpoints.down(430)]: {
      marginTop: '5%',
    },
  },
  card: {
    left: '0',
    margin: 'auto',
    marginTop: '3%',
    maxWidth: 550,
    padding: 15,
    paddingBottom: 40,
    position: 'absolute',
    right: '0',
    textAlign: 'right',
  },
  cardForCamera: {
    display: 'block',
    marginTop: '5%',
    minWidth: '100%',
    padding: '1% 1% 5%',
    textAlign: 'center',
    [theme.breakpoints.down(430)]: {
      minWidth: 225,
    },
  },
  errorMsg: {
    color: '#E20613',
    fontWeight: 600,
    margin: 8,
    [theme.breakpoints.down(430)]: {
      fontWeight: 300,
      fontSize: '30px',
      margin: 5,
    },
  },
  labelCompany: {
    fontWeight: 900,
    letterSpacing: -3,
    textTransform: 'capitalize',
    [theme.breakpoints.down(430)]: {
      fontWeight: 300,
      letterSpacing: -2,
      fontSize: '30px',
    },
  },
  labelSend: {
    color: '#fff',
  },
  labelCancel: {
    color: '#00205C',
  },
  sendIcon: {
    width: 50,
    [theme.breakpoints.down(430)]: {
      height: 20,
      width: 30,
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  formControlLabel: {
    left: '15px',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    flexGrow: 1,
    margin: '2% 20%',
    [theme.breakpoints.down(1024)]: {
      margin: '2% 10%',
    },
  },
}));
