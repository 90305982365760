import {
  CLICK_INSTALL,
  RESET_STATES,
  SET_BLUETOOTH_CONNECTION,
  SET_CAMERA_INFO,
  SET_LOCATION,
  SET_SYMPTOMS,
  SET_TEMPERATURE,
  USER_LOGIN,
  USER_LOGOUT,
  SET_APP_INSTALLED,
  SET_IS_MOVIL_DEVICE,
  SET_PACIENT_INFO,
  SET_COMORBIDITIES,
  RESET_COMORBIDITIES_STATES,
} from './actions';
import Symptom from '../helpers/Symptom';
import AppStorage from '../helpers/AppStorage';
import Comorbidities from '../helpers/Comorbidities';

export default function reducer(state, { type, payload }) {
  const setState = payload => {
    return Object.assign({}, state, {
      ...payload,
    });
  };

  switch (type) {
    case CLICK_INSTALL:
      return Object.assign({}, state, {
        isInstallable: false,
      });
    case SET_APP_INSTALLED:
      return setState({ isAppInstalled: payload.isAppInstalled });
    case SET_IS_MOVIL_DEVICE:
      return setState({ isMobileDevice: payload });
    case SET_TEMPERATURE:
      return setState({ temperature: payload });
    case SET_CAMERA_INFO:
      return setState({ idInfo: payload.idInfo });
    case SET_PACIENT_INFO:
      return setState({ pacientInfo: payload.pacientInfo });
    case SET_SYMPTOMS:
      return Object.assign({}, state, {
        symptomsData: Object.assign({}, state.symptomsData, payload),
      });
    case SET_BLUETOOTH_CONNECTION:
      return setState({ isConnectedToBluetooth: payload });
    case USER_LOGIN:
      return setState({
        isUserLoggedIn: true,
        userData: payload,
      });
    case USER_LOGOUT:
      AppStorage.remove('auth_token');
      AppStorage.remove('center');
      AppStorage.remove('userName');
      return setState({
        isUserLoggedIn: false,
        userData: undefined,
      });
    case SET_LOCATION:
      return setState({ location: payload });
    case SET_COMORBIDITIES:
      return Object.assign({}, state, {
        comorbidities: payload.comorbidities,
      });
    case RESET_COMORBIDITIES_STATES: {
      return setState({
        comorbidities: {
          ...Comorbidities.getInitComorbidities(),
        },
      });
    }
    case RESET_STATES: {
      return setState({
        temperature: 36.5,
        pacientInfo: {
          error: false,
          provinceId: { name: 'Provincia', value: '' },
          townId: { name: 'Municipio', value: '' },
          sectorId: { name: 'Sector', value: '' },
          phoneNumber: { name: 'Télefono de contacto', value: '' },
          vaccineId: { name: 'Vacunación', value: '' },
          vaccineTypeId: {
            name: 'Tipos de vacunación',
            value: '',
          },
          longitude: '',
          latitude: '',
        },
        idInfo: {
          error: false,
          documentNumber: { name: 'Cédula', value: '' },
          passport: { name: 'Pasaporte', value: '' },
          firstNames: { name: 'Nombres', value: '' },
          lastNames: { name: 'Apellidos', value: '' },
        },
        symptomsData: {
          error: false,
          noSymptoms: true,
          symptoms: {
            ...Symptom.getInitSymptomps(),
          },
        },
      });
    }
    default:
      return state;
  }
}
