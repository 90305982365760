import React from 'react';
import { Card } from '@material-ui/core';
import useStyles from './styled';
import { catchError } from '../../catchErrors/ErrorComponent';

function NotFoundPage() {
  const classes = useStyles();
  return (
    <div style={{ height: '100vh' }}>
      <Card className={classes.container}>Página no encontrada</Card>
    </div>
  );
}
export default catchError(NotFoundPage);
